import React from 'react';

import { Box, Fade, Typography, useTheme } from '@material-ui/core';

import { Logo } from '@vyce/core/src/components';
import wrongPlace from '@vyce/core/src/assets/svg/wrong-place.svg';

import useStyles from '../styles';

export const WrongPlace = () => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Fade in>
      <>
        <Box display="flex" justifyContent="center">
          <Logo height={48} width={171} />
        </Box>

        <Box display="flex" flexDirection="column" gridGap={16}>
          <Box className={classes.wrongPlaceImageWrapper}>
            <img width="100%" height="100%" alt="wrong_place" src={wrongPlace} />
          </Box>

          <Typography variant="h5" style={{ marginTop: theme.spacing(3), fontWeight: 700 }}>
            You’re in the wrong place!
          </Typography>

          <Typography color="textSecondary" variant="subtitle1">
            Please speak with your manager and ask them to send you an invite to register then follow the
            instructions carefully
          </Typography>

          <Typography color="textSecondary" variant="subtitle1">
            Please do not register as a company on this site, it will not work. 
          </Typography>
        </Box>
      </>
    </Fade>
  );
};
