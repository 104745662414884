import { ChangeEvent, useEffect, useMemo, useState, useCallback, useContext } from 'react';

import { GridSortModel } from '@mui/x-data-grid';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';

import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import { AxiosResponse } from 'axios';

import {
  AGE_MARKS_OPTIONS,
  CERTIFICATE_FILTERS,
  DOCUMENT_STATUSES_ARR,
  GRID_PAGE_SIZE,
  MAX_WORKER_AGE,
  MIN_WORKER_AGE,
  RTW_VALUES,
  TABLE_OFFSET_DELAY,
} from '@vyce/core/src/constants';
import { FilterSection, Nationality, Tag, TagGroups, Team, TeamMember } from '@vyce/core/src/types';
import { GetCompanyTeamsMembersDataRequest, TeamCertificateFilter } from '@vyce/core/src/api/types';
import { fetchNationalitiesRequest, fetchTagsRequest } from '@vyce/core/src/api/handbook';
import { getSurveyList } from '@vyce/core/src/api/survey';
import { generateQueryParamsFromObj } from '@vyce/core/src/utils/url';
import { isJson, generateExcelFileLink, isNil } from '@vyce/core/src/utils';
import { formatSortModel } from '@vyce/core/src/utils/sorting';
import { postExportQualifications, deleteTeamMembersFromModuleRequest } from '@vyce/core/src/api/teams';
import { useBooleanState, useDebounceValue } from '@vyce/core/src/hooks';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';

import { getTeamsFilter } from '../utils';

interface Props {
  companyId?: string;
  teams: Team[] | null;
  getAllTeamMembersRequest: (
    companyId: string,
    requestData: GetCompanyTeamsMembersDataRequest
  ) => Promise<AxiosResponse>;
}

export const useTeamMembersData = ({ companyId, getAllTeamMembersRequest, teams }: Props) => {
  const { handleServerError, showNotification } = useContext(NotificationContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const [offset, setOffset] = useState<number>(0);
  const [substring, setSubstring] = useState<string>('');
  const [teamMembers, setTeamMembers] = useState<TeamMember[]>([]);
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'registration_date', sort: 'desc' }]);
  const [roles, setRoles] = useState<Tag[]>([]);
  const [nationalities, setNationalities] = useState<Nationality[]>([]);
  const [questions, setQuestions] = useState<string[]>([]);
  const [exportButtonLoading, setExportButtonLoading] = useState<boolean>(false);
  const [filters, setFilters] = useState<any>();
  const [closeGridAction, setCloseGridAction] = useState<boolean>(false);
  const [isConfirmDialogOpen, openConfirmDialog, closeConfirmDialog] = useBooleanState(false);
  const [userToRemove, setUserToRemove] = useState<TeamMember>();

  const dOffset = useDebounceValue(offset, TABLE_OFFSET_DELAY);

  const history = useHistory();
  const teamsFilterOptions = getTeamsFilter(teams ?? []);

  const fetchJobsAndNationalities = async () => {
    try {
      const resRoles = await fetchTagsRequest({ group: TagGroups.ROLE, short: false });
      const resNationalities = await fetchNationalitiesRequest();
      setRoles(resRoles.data.items?.map((i: Tag) => i.name));
      setNationalities(resNationalities.data.items?.map((i: Tag) => i.name));
    } catch (e) {
      console.error(e);
    }
  };

  const fetchSurveysQuestions = async () => {
    try {
      const surveysQuestions = await getSurveyList();
      setQuestions(surveysQuestions.data.items?.map(i => i.name));
    } catch (e) {
      console.error(e);
    }
  };

  const filtersSections: FilterSection[] = useMemo(
    () => [
      {
        title: ' Health & Safety',
        expanded: true,
        filters: [
          {
            type: 'select',
            multiple: false,
            label: 'Select medical condition',
            field: 'survey_tag',
            values: ['', ...questions],
            defaultValue: '',
          },
        ],
      },
      teamsFilterOptions,

      {
        title: 'Checks',
        expanded: true,
        filters: [
          {
            type: 'select',
            multiple: false,
            label: 'ID Status',
            field: 'id_status',
            values: ['', ...DOCUMENT_STATUSES_ARR],
            defaultValue: '',
          },
          {
            type: 'select',
            multiple: false,
            label: 'RTW Status',
            field: 'rtw_status',
            values: ['', RTW_VALUES.INDEFINITE, RTW_VALUES.LIMITED, RTW_VALUES.NO_RTW],
            defaultValue: '',
          },
        ],
      },
      {
        title: 'Worker Profiles',
        expanded: true,
        filters: [
          {
            type: 'select',
            multiple: false,
            label: 'Job Role',
            field: 'role',
            values: ['', ...roles],
            defaultValue: '',
          },
          {
            type: 'select',
            multiple: false,
            label: 'Nationality',
            field: 'nationality',
            values: ['', ...nationalities],
            defaultValue: '',
          },
          {
            type: 'select',
            multiple: false,
            label: 'Gender',
            field: 'gender',
            values: ['', 'male', 'female'],
            defaultValue: '',
          },
          {
            type: 'slider',
            label: 'Age',
            field: 'age',
            dataType: 'object',
            min: MIN_WORKER_AGE,
            max: MAX_WORKER_AGE,
            defaultValue: { gte: MIN_WORKER_AGE, lte: MAX_WORKER_AGE },
            marks: AGE_MARKS_OPTIONS,
          },
        ],
      },
      {
        title: 'Certificates',
        expanded: true,
        filters: [
          {
            type: 'autocomplete',
            multiple: true,
            label: 'Certificate',
            field: 'certificate',
            values: CERTIFICATE_FILTERS,
            defaultValue: [],
          },
        ],
      },
    ],
    [roles, nationalities]
  );

  const resetCloseStatus = () => {
    setCloseGridAction(true);
    setTimeout(() => setCloseGridAction(false), 100);
  };

  const removeFromModule = async () => {
    if (!userToRemove || !companyId) {
      return;
    }
    try {
      const res = await deleteTeamMembersFromModuleRequest(companyId, {
        members: [userToRemove.user_id],
      });
      setTeamMembers(value => value.filter(user => user.user_id !== userToRemove.user_id));
      closeConfirmDialog();
      showNotification({ message: res.data.message, options: { variant: 'success' } });
    } catch (e) {
      handleServerError(e);
    }
  };

  const handleRemoveTeamMemberClick = (user: TeamMember) => {
    setUserToRemove(user);
    openConfirmDialog();
  };

  const handleRemoveClick = (user: TeamMember) => {
    resetCloseStatus();
    handleRemoveTeamMemberClick(user);
  };

  const getTeamMembers = useCallback(async () => {
    if (!companyId || isNil(dOffset)) {
      return;
    }
    try {
      setLoading(true);

      const requestData: GetCompanyTeamsMembersDataRequest = {
        offset: dOffset,
        limit: GRID_PAGE_SIZE,
        substring,
        order_by: formatSortModel<TeamMember>(sortModel),
        ...prepareBackendFilters(filters ?? {}),
      };

      const {
        data: { items, count },
      } = await getAllTeamMembersRequest(companyId, requestData);
      setLoading(false);
      setTeamMembers(items);
      setTotal(count);
    } catch (e) {
      setLoading(true);
      handleServerError(e);
    }
  }, [companyId, filters, dOffset, sortModel, substring]);

  const exportQualifications = useCallback(async () => {
    if (!companyId) {
      return;
    }
    try {
      setExportButtonLoading(true);
      const team_id = teams?.find(t => t.name === filters?.team_name)?.uuid;
      const res = await postExportQualifications({
        company_id: companyId,
        data: {
          substring: substring,
          survey_tag: filters?.survey_tag,
          age: filters?.age,
          gender: filters?.gender,
          nationality: filters?.nationality,
          role: filters?.role,
          rtw_status: filters?.rtw_status,
          id_status: filters?.id_status,
          team_id,
          certificate: filters?.certificate?.map((c: TeamCertificateFilter) => ({
            type: c.type,
            status: c.status,
          })),
          order_by: formatSortModel(sortModel),
          limit: 3000,
        },
      });
      generateExcelFileLink(res);
      setExportButtonLoading(false);
    } catch (e) {
      console.error(e);
      setExportButtonLoading(false);
      handleServerError(e);
    }
  }, [companyId, filters, substring, sortModel, teams]);

  const handleSortModelChange = (newModel: GridSortModel) => {
    if (isEqual(newModel, sortModel)) {
      return;
    }
    setSortModel(newModel);
  };

  const handlePageChange = (newPage: number) => {
    setOffset(newPage * GRID_PAGE_SIZE);
  };

  const handleSetSubstring = debounce((e: ChangeEvent<HTMLInputElement>) => {
    setSubstring(e?.target?.value);
  }, 500);

  const handleFilterChange = debounce((filters: any) => {
    history.push({
      pathname: history.location.pathname,
      search: generateQueryParamsFromObj(filters),
    });
  }, 300);

  const formatFilters = (filters: any) => {
    let formattedFilters = { ...filters };
    Object.keys(formattedFilters).forEach(key => {
      const filterItem = formattedFilters[key];
      if (!filterItem) {
        delete formattedFilters[key];
      }
      if (isJson(filterItem)) {
        formattedFilters[key] = JSON.parse(filterItem);
      }
    });

    return formattedFilters;
  };

  const prepareBackendFilters = (filters: any) => {
    return {
      ...filters,
      team_id: teams?.find(team => team.name === filters.team_name)?.uuid,
    };
  };

  useEffect(() => {
    fetchJobsAndNationalities();
    fetchSurveysQuestions();
  }, []);

  useEffect(() => {
    if (history.location.search) {
      const { page, ...filters } = queryString.parse(history.location.search, { parseNumbers: true });
      const formattedFilters = formatFilters(filters);
      setFilters(formattedFilters);
    }
  }, [history.location.search]);

  useEffect(() => {
    getTeamMembers();
  }, [getTeamMembers]);

  return {
    loading,
    substring,
    teamMembers,
    total,
    sortModel,
    exportButtonLoading,
    handleSetSubstring,
    filtersSections,
    filters,
    handleFilterChange,
    isConfirmDialogOpen,
    userToRemove,
    closeGridAction,
    setOffset,
    handleSortModelChange,
    handlePageChange,
    closeConfirmDialog,
    removeFromModule,
    handleRemoveClick,
    exportQualifications,
  };
};
