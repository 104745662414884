import React, { useEffect, useRef, useState, useContext, useMemo } from 'react';

import {
  Box,
  Button,
  Grid,
  MenuItem,
  MenuList,
  Paper,
  Typography,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { GridColDef, GridSortModel } from '@mui/x-data-grid';
import { useParams } from 'react-router-dom';
import { BsCheck } from 'react-icons/bs';
import { FiSend } from 'react-icons/fi';
import { BiError, BiExport } from 'react-icons/bi';
import { AiOutlineRedo } from 'react-icons/ai';
import { AxiosResponse } from 'axios';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';

import clsx from 'clsx';

import { PayScheduleInfo, DownloadBACS } from '@vyce/core/src/views/payroll/components';
import { AppSearchInput, AppMenu } from '@vyce/core/src/components/inputs';

import {
  Company,
  FilterSection,
  PayrunLine,
  PayrunPayload,
  PayrunTotals,
  PaySchedule,
  PeriodData,
} from '@vyce/core/src/types';
import {
  GetPayrun,
  MarkAsPaidRequest,
  SendBulkPayStatements,
  SendDocumentRequest,
  SendPayslipsRequest,
} from '@vyce/core/src/api/types';
import { currencyFormatter, generateExcelFileLink, getBooleanFilterValue } from '@vyce/core/src/utils';
import { getUrlItems } from '@vyce/core/src/utils/url';
import {
  DOCUMENT_STATUSES_ARR,
  GRID_PAGE_SIZE,
  RTW_VALUES,
  SUBCONTRACTOR_TYPES,
} from '@vyce/core/src/constants';
import {
  AppIconButton,
  AppLink,
  FilterSystem,
  GridActions,
  ConfirmDialog,
  Hint,
  AppDataGrid,
  IsVerified,
} from '@vyce/core/src/components';
import { formatSortModel } from '@vyce/core/src/utils/sorting';
import { DeviceContext } from '@vyce/core/src/contexts';
import { useHorizontalScrollStyles } from '@vyce/core/src/styles';

import { SendPayStatementsDialog } from './components';
import { useStyles } from './styles';
import { NotificationContext } from '../../contexts/notificationContext';

interface Props {
  refreshPayrunRequest: Function;
  getPayrunRequest: (params: GetPayrun) => Promise<AxiosResponse>;
  markPayrunAsPaidRequest: (params: MarkAsPaidRequest) => Promise<AxiosResponse>;
  markPayrunAsPaidForEmployeeRequest: (params: MarkAsPaidRequest) => Promise<AxiosResponse>;
  sendPayslipsByEmailRequest: (params: SendPayslipsRequest) => Promise<AxiosResponse>;
  sendPayslipsBySMSRequest: (params: SendPayslipsRequest) => Promise<AxiosResponse>;
  sendPassportRequest: (params: SendDocumentRequest) => Promise<AxiosResponse>;
  sendShareCodeRequest: (params: SendDocumentRequest) => Promise<AxiosResponse>;
  sendPayslipsToEmployeesBySMSRequest: (params: SendBulkPayStatements) => Promise<AxiosResponse>;
  sendPayslipsToEmployeesByEmailRequest: (params: SendBulkPayStatements) => Promise<AxiosResponse>;
  downloadPayrunBACSRequest: Function;
  downloadPayrunsBACSRequest: Function;
  getPayrunAsFileRequest: Function;
  token: string;
}

export const Payrun: React.FC<Props> = ({
  refreshPayrunRequest,
  getPayrunRequest,
  markPayrunAsPaidRequest,
  markPayrunAsPaidForEmployeeRequest,
  downloadPayrunBACSRequest,
  downloadPayrunsBACSRequest,
  getPayrunAsFileRequest,
  sendPayslipsByEmailRequest,
  sendPayslipsBySMSRequest,
  sendPassportRequest,
  sendShareCodeRequest,
  token,
  sendPayslipsToEmployeesBySMSRequest,
  sendPayslipsToEmployeesByEmailRequest,
}) => {
  const classes = useStyles();
  const { handleServerError, showNotification } = useContext(NotificationContext);
  const horizontalScrollStyles = useHorizontalScrollStyles();
  const [totals, setTotals] = useState<PayrunTotals>();
  const [rows, setRows] = useState<PayrunLine[]>([]);
  const [paySchedule, setPaySchedule] = useState<PaySchedule>();
  const [status, setStatus] = useState<string>();
  const [companyId, setCompanyId] = useState<string>('');
  const [company, setCompany] = useState<Company>();
  const [loading, setLoading] = useState<boolean>(true);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [openSendPayStatementsDialog, setOpenSendPayStatementsDialog] = useState<boolean>(false);
  const [payrunId, setPayrunId] = useState<string>('');
  const { id } = useParams<{ id: string }>();
  const theme = useTheme();
  const [substring, setSubstring] = useState<string>();
  const mountedRef = useRef(true);
  const [periodData, setPeriodData] = useState<PeriodData>();
  const [offset, setOffset] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [filters, setFilters] = useState<Partial<PayrunPayload>>();
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'first_name', sort: 'asc' }]);
  const [closeGridAction, setCloseGridAction] = useState<boolean>(false);
  const { isMobile } = useContext(DeviceContext);
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  const filtersSections: FilterSection[] = [
    {
      title: 'Checks',
      expanded: true,
      filters: [
        {
          type: 'select',
          multiple: false,
          label: 'ID Verified',
          field: 'id_verified',
          values: ['', 'Verified', 'Not Verified'],
          defaultValue: '',
        },
        {
          type: 'select',
          multiple: false,
          label: 'ID Status',
          field: 'id_status',
          values: ['', ...DOCUMENT_STATUSES_ARR],
          defaultValue: '',
        },
        {
          type: 'select',
          multiple: false,
          label: 'RTW Status',
          field: 'rtw_status',
          values: [
            '',
            { name: 'Indefinite', value: RTW_VALUES.INDEFINITE },
            { name: 'Limited', value: RTW_VALUES.LIMITED },
            { name: 'No RTW', value: RTW_VALUES.NO_RTW },
          ],
          defaultValue: '',
        },
        {
          type: 'select',
          multiple: false,
          label: 'CIS Verified',
          field: 'cis_verified',
          values: ['', 'Verified', 'Not Verified'],
          defaultValue: '',
        },
      ],
    },
    {
      title: 'Payroll Types',
      expanded: true,
      filters: [
        {
          type: 'select',
          multiple: false,
          label: 'Pay Scheme',
          field: 'pay_scheme',
          values: [
            '',
            SUBCONTRACTOR_TYPES.CIS_SELF_EMPLOYED.toUpperCase(),
            SUBCONTRACTOR_TYPES.SELF_EMPLOYED.toUpperCase(),
            SUBCONTRACTOR_TYPES.PAYE.toUpperCase(),
          ],
          defaultValue: '',
        },
        {
          type: 'select',
          multiple: false,
          label: 'Taxation Status',
          field: 'tax_status',
          values: [
            '',
            'CIS Gross',
            'CIS 20%',
            'CIS 30%',
            'Self-employed Gross',
            'PAYE Gross',
            'Umbrella Gross',
          ],
          defaultValue: '',
        },
      ],
    },
  ];

  const columns: GridColDef[] = [
    {
      field: 'first_name',
      headerName: 'Name',
      flex: 1.5,
      disableColumnMenu: true,
      renderCell: params => (
        <Box display="flex" width="100%" alignItems="center" justifyContent="space-between">
          <AppLink
            target="_blank"
            to={`/payroll/users/${params.row?.first_name}_${params.row?.user_id}_${companyId}`}>
            {params.row.first_name} {params.row.last_name}
          </AppLink>
          <Hint
            icon={
              params.row?.verification?.status === false ? (
                <BiError color={theme.palette.error.main} />
              ) : (
                <BsCheck
                  color={params.row?.on_hold ? theme.palette.warning.main : theme.palette.success.main}
                />
              )
            }>
            {params.row.verification?.errors?.length || params.row?.on_hold ? (
              <Box display="flex" flexDirection="column">
                <Typography variant="button" style={{ color: theme.palette.warning.main }}>
                  On Hold
                </Typography>
                {params.row.verification?.errors?.map((error: any) => (
                  <Typography
                    style={{ color: theme.palette.error.main, lineHeight: '32px' }}
                    variant="caption"
                    key={error.message}>
                    {error.message}
                  </Typography>
                ))}
              </Box>
            ) : undefined}
          </Hint>
        </Box>
      ),
      minWidth: 160,
    },
    {
      field: 'ni_number',
      headerName: 'Ref #',
      flex: 0.6,
      disableColumnMenu: true,
      minWidth: 90,
    },
    {
      field: 'tax_status',
      headerName: 'Tax Status',
      flex: 1,
      disableColumnMenu: true,
      minWidth: 110,
    },
    {
      field: 'total_pay',
      headerName: 'Total Pay',
      valueFormatter: params => currencyFormatter.format(params.row.total_pay),
      flex: 0.8,
      disableColumnMenu: true,
      minWidth: 100,
    },
    {
      field: 'total_tax',
      headerName: 'TAX',
      valueFormatter: params => currencyFormatter.format(params.row.total_tax),
      flex: 0.5,
      editable: false,
      disableColumnMenu: true,
      minWidth: 80,
    },
    {
      field: 'payroll_fee',
      headerName: 'Deductions',
      valueFormatter: params => currencyFormatter.format(params.row.payroll_fee),
      flex: 0.7,
      editable: false,
      disableColumnMenu: true,
      minWidth: 120,
    },
    {
      field: 'additions',
      headerName: 'Additions',
      valueFormatter: params => currencyFormatter.format(params.row.additions),
      flex: 0.7,
      editable: false,
      disableColumnMenu: true,
      minWidth: 110,
    },
    {
      field: 'vat',
      headerName: 'VAT (if applicable)',
      valueFormatter: params => currencyFormatter.format(params.row.total_vat),
      flex: 1,
      editable: false,
      disableColumnMenu: true,
      minWidth: 160,
    },
    {
      field: 'total_net',
      headerName: 'Take Home',
      valueFormatter: params => currencyFormatter.format(params.row.total_net),
      flex: 1,
      editable: false,
      disableColumnMenu: true,
      minWidth: 120,
    },
    {
      field: 'net',
      headerName: 'Paid',
      valueFormatter: params => currencyFormatter.format(params.row.net),
      flex: 0.7,
      editable: false,
      disableColumnMenu: true,
      minWidth: 100,
    },
    {
      field: 'net_unpaid',
      headerName: 'Outstanding',
      valueFormatter: params => currencyFormatter.format(params.row.net_unpaid),
      flex: 0.7,
      disableColumnMenu: true,
      editable: false,
      minWidth: 120,
    },
    {
      field: 'sms_notified',
      headerName: 'SMS Sent',
      flex: 0.2,
      editable: false,
      renderCell: params => <IsVerified verified={params.row.sms_notified} />,
      minWidth: 90,
      hideSortIcons: true,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'email_notified',
      headerName: 'Email Sent',
      flex: 0.2,
      editable: false,
      renderCell: params => <IsVerified verified={params.row.email_notified} />,
      minWidth: 90,
      hideSortIcons: true,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: '',
      headerName: 'Actions',
      width: 80,
      hideSortIcons: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: params => (
        <Box display="flex" width="100%">
          <GridActions close={closeGridAction}>
            <MenuList>
              <MenuItem
                onClick={() => {
                  resetCloseStatus();
                  markAsPaidForEmployee(params.row.employee_id);
                }}>
                Mark as paid
              </MenuItem>
              <MenuItem
                onClick={() => {
                  resetCloseStatus();
                  sendPayslipsByEmail(params.row.uuid);
                }}>
                Send pay statement to the worker
              </MenuItem>
              <MenuItem
                onClick={() => {
                  resetCloseStatus();
                  sendNetPayText(params.row.uuid);
                }}>
                Send net pay text message to worker
              </MenuItem>
              <MenuItem
                onClick={() => {
                  resetCloseStatus();
                  sendPassport(params.row.user_id);
                }}>
                Send email request for a clearer ID
              </MenuItem>
              <MenuItem
                onClick={() => {
                  resetCloseStatus();
                  sendShareCode(params.row.user_id);
                }}>
                Send email request for valid Share Code
              </MenuItem>
            </MenuList>
          </GridActions>
        </Box>
      ),
    },
  ];

  useEffect(() => {
    if (!mountedRef.current) return;
    const payrunUrlItems = getUrlItems(id);
    const payrunId = payrunUrlItems?.id;
    const companyId = payrunUrlItems?.additionalId;
    setCompanyId(companyId);
    setPayrunId(payrunId);
    getPayrun(companyId, payrunId, { substring, offset, order_by: formatSortModel<PayrunLine>(sortModel) });

    return () => {
      mountedRef.current = false;
    };
  }, []);

  const resetCloseStatus = () => {
    setCloseGridAction(true);
    setTimeout(() => setCloseGridAction(false), 100);
  };

  const sendSMS = async () => {
    try {
      await sendPayslipsToEmployeesBySMSRequest({ token, companyId, payrunId });
      showNotification({ message: 'Bulk Pay Statements have been sent', options: { variant: 'success' } });
      setOpenSendPayStatementsDialog(false);
    } catch (e) {
      handleServerError(e);
    }
  };

  const sendEmail = async () => {
    try {
      await sendPayslipsToEmployeesByEmailRequest({ token, companyId, payrunId });
      showNotification({ message: 'Bulk Pay Statements have been sent', options: { variant: 'success' } });
      setOpenSendPayStatementsDialog(false);
    } catch (e) {
      handleServerError(e);
    }
  };

  const getPayrun = async (companyId: string, payrunId: string, data: PayrunPayload) => {
    try {
      const res: AxiosResponse = await getPayrunRequest({
        token,
        companyId,
        payrunId,
        data: { ...data, limit: GRID_PAGE_SIZE },
      });
      const resData = res.data;
      if (!mountedRef.current) return;
      setTotals(resData.totals);
      setTotal(resData.pay_run_lines?.count);
      setStatus(resData.status);
      setPeriodData({
        end_date: resData.end_date,
        start_date: resData.start_date,
        tax_week_end: resData.tax_week_end,
        tax_year_end: resData.tax_year_end,
      });
      setPaySchedule(resData.pay_schedule);
      setCompany(resData.company);
      setRows(resData.pay_run_lines?.items);
      setLoading(false);
    } catch (e) {
      if (!mountedRef.current) return;
      setLoading(false);
      handleServerError(e);
    }
  };

  const refresh = async () => {
    if (!companyId || !payrunId || !paySchedule?.uuid) {
      return;
    }
    try {
      const res = await refreshPayrunRequest(token, companyId, paySchedule.uuid, payrunId);
      showNotification({ message: res.data.message, options: { variant: 'success' } });
      getPayrun(companyId, payrunId, {
        substring,
        offset,
        order_by: formatSortModel<PayrunLine>(sortModel),
        ...prepareFilters(filters),
      });
    } catch (e) {
      handleServerError(e);
    }
  };

  const markAsPaid = async () => {
    if (!companyId || !payrunId || !paySchedule?.uuid) {
      return;
    }
    try {
      const res = await markPayrunAsPaidRequest({
        token,
        companyId,
        payScheduleId: paySchedule.uuid,
        payrunId,
      });
      showNotification({ message: res.data.message, options: { variant: 'success' } });
      setOpenConfirmDialog(false);
      getPayrun(companyId, payrunId, {
        substring,
        offset,
        order_by: formatSortModel<PayrunLine>(sortModel),
        ...prepareFilters(filters),
      });
    } catch (e) {
      handleServerError(e);
    }
  };

  const markAsPaidForEmployee = async (employeeId: string) => {
    if (!companyId || !payrunId || !paySchedule?.uuid) {
      return;
    }
    try {
      const res = await markPayrunAsPaidForEmployeeRequest({
        token,
        companyId,
        payScheduleId: paySchedule.uuid,
        payrunId,
        employeeId,
      });
      showNotification({ message: res.data.message, options: { variant: 'success' } });
      getPayrun(companyId, payrunId, {
        substring,
        offset,
        order_by: formatSortModel<PayrunLine>(sortModel),
        ...prepareFilters(filters),
      });
    } catch (e) {
      handleServerError(e);
    }
  };

  const sendPayslipsByEmail = async (payrunLineId: string) => {
    if (!companyId || !payrunId) {
      return;
    }
    try {
      const res = await sendPayslipsByEmailRequest({
        token,
        companyId,
        payrunLineId,
      });
      showNotification({ message: res.data.message, options: { variant: 'success' } });
    } catch (e) {
      handleServerError(e);
    }
  };

  const sendNetPayText = async (payrunLineId: string) => {
    if (!companyId || !payrunId) {
      return;
    }
    try {
      const res = await sendPayslipsBySMSRequest({
        token,
        companyId,
        payrunLineId,
      });
      showNotification({ message: res.data.message, options: { variant: 'success' } });
    } catch (e) {
      handleServerError(e);
    }
  };

  const sendPassport = async (userId: string) => {
    try {
      const res = await sendPassportRequest({
        token,
        companyId,
        userId,
      });
      showNotification({ message: res.data.message, options: { variant: 'success' } });
    } catch (e) {
      handleServerError(e);
    }
  };

  const sendShareCode = async (userId: string) => {
    try {
      const res = await sendShareCodeRequest({
        token,
        companyId,
        userId,
      });
      showNotification({ message: res.data.message, options: { variant: 'success' } });
    } catch (e) {
      handleServerError(e);
    }
  };

  const isPaid = status === 'paid';

  const handleSearchChange = debounce((e: any) => {
    const substr = e.target.value;
    setSubstring(substr);
    getPayrun(companyId, payrunId, {
      substring: substr,
      offset,
      order_by: formatSortModel<PayrunLine>(sortModel),
      ...prepareFilters(filters),
    });
  }, 500);

  const handleFilterChange = (newFilters: any) => {
    setFilters(newFilters);
    getPayrun(companyId, payrunId, {
      substring,
      offset,
      order_by: formatSortModel<PayrunLine>(sortModel),
      ...prepareFilters(newFilters),
    });
  };

  const handlePageChange = (newPage: number) => {
    const newOffset = newPage * GRID_PAGE_SIZE;
    setOffset(newOffset);
    getPayrun(companyId, payrunId, {
      substring,
      offset: newOffset,
      order_by: formatSortModel<PayrunLine>(sortModel),
      ...prepareFilters(filters),
    });
  };

  const handleSortModelChange = (newModel: GridSortModel) => {
    if (isEqual(newModel, sortModel)) {
      return;
    }
    setSortModel(newModel);
    getPayrun(companyId, payrunId, {
      substring,
      offset,
      order_by: formatSortModel<PayrunLine>(newModel),
      ...prepareFilters(filters),
    });
  };

  const prepareFilters = (filters: any): PayrunPayload => {
    return {
      id_verified: getBooleanFilterValue(filters?.id_verified),
      cis_verified: getBooleanFilterValue(filters?.cis_verified),
      rtw_status: filters?.rtw_status?.value || undefined,
      id_status: filters?.id_status || undefined,
      pay_scheme: filters?.pay_scheme.toLowerCase() || undefined,
      tax_status: filters?.tax_status || undefined,
    };
  };

  const exportPayrun = async () => {
    if (!companyId || !payrunId) {
      return;
    }
    try {
      const res = await getPayrunAsFileRequest(token, companyId, payrunId);
      generateExcelFileLink(res);
    } catch (e) {
      handleServerError(e);
    }
  };

  const options = useMemo(
    () => [
      {
        title: 'Refresh',
        icon: <AiOutlineRedo size="15px" color={theme.palette.primary.main} />,
        disabled: loading || isPaid,
        onClick: refresh,
      },
      {
        title: 'Export',
        icon: <BiExport size="15px" color={theme.palette.primary.main} />,
        disabled: loading || !rows?.length,
        onClick: exportPayrun,
      },
      {
        title: 'Send Pay Statements',
        icon: <FiSend size="15px" color={theme.palette.primary.main} />,
        disabled: loading || !rows?.length,
        onClick: () => setOpenSendPayStatementsDialog(true),
      },

      {
        title: 'Mark As Paid',
        icon: <BsCheck size="20px" color={theme.palette.primary.main} />,
        disabled: loading || isPaid || !rows?.length,
        cy: 'open-mark-as-paid-dialog',
        onClick: () => setOpenConfirmDialog(true),
      },
    ],
    [exportPayrun, isPaid, loading, refresh, rows?.length]
  );

  const downloadBACSButton = useMemo(
    () => (
      <DownloadBACS
        downloadPayrunBACSRequest={downloadPayrunBACSRequest}
        downloadPayrunsBACSRequest={downloadPayrunsBACSRequest}
        mode="single"
        disabled={!rows?.length}
        payrunId={payrunId}
        // Legend Interface (for Vyce outsourced payruns)
        companyId={paySchedule?.outsourced ? 'vyce' : companyId}
      />
    ),
    [
      companyId,
      downloadPayrunBACSRequest,
      downloadPayrunsBACSRequest,
      paySchedule?.outsourced,
      payrunId,
      rows?.length,
    ]
  );

  const mobileControlView = useMemo(
    () => (
      <Box
        display="flex"
        alignItems="center"
        gridGap={16}
        margin={isMobile ? '40px 0 12px 0' : '24px 0'}
        justifyContent="space-between"
        className={clsx(horizontalScrollStyles.blockWithHideScroll, horizontalScrollStyles.blockWrapper)}>
        <Box display="flex" alignItems="center" flex={1}>
          <Box width="100%">
            <AppSearchInput onChange={handleSearchChange} isSmall expanded={true} fullWidth={true} />
          </Box>
        </Box>
        <Box display="flex" gridGap={8}>
          {payrunId && companyId && downloadBACSButton}

          <FilterSystem
            buttonBorder
            filtersSections={filtersSections}
            onFiltersChange={handleFilterChange}
          />
          <AppMenu options={options} cy="open-actions-dropdown-menu" />
        </Box>
      </Box>
    ),
    [
      companyId,
      downloadBACSButton,
      filtersSections,
      handleFilterChange,
      handleSearchChange,
      options,
      payrunId,
      isMobile,
    ]
  );

  const desktopControlView = useMemo(
    () => (
      <Box
        margin={'24px 0'}
        display="flex"
        alignItems="center"
        flexWrap="wrap"
        gridGap={8}
        justifyContent="space-between">
        <Box display="flex" gridGap={8}>
          <AppSearchInput onChange={handleSearchChange} isSmall expanded={true} />

          <AppIconButton disabled={loading || isPaid} variant="primary" onClick={refresh}>
            <AiOutlineRedo color={theme.palette.text.primary} />
          </AppIconButton>

          <Button
            onClick={exportPayrun}
            disabled={loading || !rows?.length}
            startIcon={<BiExport size="15px" />}
            variant="contained"
            size="small"
            color="secondary">
            Export
          </Button>
        </Box>

        <Box display="flex" flexWrap="wrap" gridGap={8}>
          <Button
            onClick={() => setOpenSendPayStatementsDialog(true)}
            disabled={loading || !rows?.length}
            startIcon={<FiSend size="16px" />}
            variant="contained"
            size="small"
            color="primary">
            Send Pay Statements
          </Button>

          <Button
            onClick={() => setOpenConfirmDialog(true)}
            disabled={loading || isPaid || !rows?.length}
            startIcon={<BsCheck />}
            variant="contained"
            size="small"
            cy-test-id="open-mark-as-paid-dialog"
            color="primary">
            Mark As Paid
          </Button>

          {payrunId && companyId && downloadBACSButton}

          <FilterSystem
            buttonBorder
            filtersSections={filtersSections}
            onFiltersChange={handleFilterChange}
          />
        </Box>
      </Box>
    ),
    [
      companyId,
      downloadPayrunBACSRequest,
      downloadPayrunsBACSRequest,
      exportPayrun,
      filtersSections,
      handleFilterChange,
      handleSearchChange,
      isPaid,
      loading,
      paySchedule?.outsourced,
      payrunId,
      refresh,
      rows?.length,
      token,
    ]
  );

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={9}>
          <PayScheduleInfo
            periodData={periodData}
            paySchedule={paySchedule}
            companyName={company?.name}
            status={status}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <Paper className={classes.wrapper} variant="outlined">
            {totals && (
              <Box alignItems={isMobile ? 'flex-start' : 'flex-end'} display="flex" flexDirection="column">
                <Box marginBottom={1}>
                  <Typography variant="subtitle2" className={clsx(classes.bold, classes.smallText)}>
                    Gross: {currencyFormatter.format(totals?.total_gross)}
                  </Typography>
                </Box>
                <Typography variant="subtitle2" className={classes.smallText}>
                  Net: {currencyFormatter.format(totals?.total_net)}
                </Typography>
              </Box>
            )}
          </Paper>
        </Grid>
      </Grid>

      {isDesktop ? desktopControlView : mobileControlView}

      <Box marginTop={2}>
        <AppDataGrid
          rows={rows}
          rowCount={total}
          height="calc(100vh - 420px)"
          getRowId={row => row.uuid}
          columns={columns}
          loading={loading}
          pageSize={GRID_PAGE_SIZE}
          rowsPerPageOptions={[GRID_PAGE_SIZE]}
          onSortModelChange={handleSortModelChange}
          onPageChange={handlePageChange}
          paginationMode="server"
          sortingMode="server"
          sortModel={sortModel}
          disableSelectionOnClick
          unit="payruns"
        />

        <ConfirmDialog
          handleClose={() => setOpenConfirmDialog(false)}
          open={openConfirmDialog}
          confirmText="Mark As Paid"
          cancelText="Cancel"
          subtitle="All individuals that are on hold because they do not have verified ID or RTW will not be marked as paid. You can mark them as paid once their ID and RTW have been verified."
          title="Mark As Paid?"
          handleConfirm={markAsPaid}
        />

        <SendPayStatementsDialog
          sendSMS={sendSMS}
          sendEmail={sendEmail}
          open={openSendPayStatementsDialog}
          setOpen={setOpenSendPayStatementsDialog}
        />
      </Box>
    </>
  );
};
