import { useContext, useEffect, useMemo, useState, useCallback } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';

import {
  Company,
  CompanyPosition,
  INTERFACE_PERMISSIONS,
  Passport,
  TEAMS_PERMISSIONS,
  UserPreviewData,
} from '../../../types';
import { getUrlItems } from '../../../utils/url';
import { GetUserData } from '../../../api/types';
import { NotificationContext } from '../../../contexts/notificationContext';
import { useBooleanState } from '../../../hooks';

interface Props {
  getEmployeePreviewRequest: (params: GetUserData) => Promise<AxiosResponse>;
  getPassportRequest: (params: GetUserData) => Promise<AxiosResponse>;
  getRtwRequest: (params: GetUserData) => Promise<AxiosResponse>;
  selectedCompany?: Company;
  selectedPosition?: CompanyPosition;
}

export const useEmployeeProfileData = ({
  getEmployeePreviewRequest,
  getRtwRequest,
  getPassportRequest,
  selectedCompany,
  selectedPosition,
}: Props) => {
  const { handleServerError } = useContext(NotificationContext);
  const [userPreviewData, setUserPreviewData] = useState<UserPreviewData>();
  const [loading, setLoadingTrue, setLoadingFalse] = useBooleanState(false);
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [passport, setPassport] = useState<Passport>();
  const [rtw, setRtw] = useState<any>();

  const selectedCompanyId = selectedCompany?.uuid ?? '';
  const urlItems = getUrlItems(id);
  const userId = urlItems?.id;

  const hasPayrollPermissions = useMemo(
    () =>
      selectedPosition?.permissions?.includes(INTERFACE_PERMISSIONS.PAYROLL) ||
      selectedPosition?.permissions?.includes(INTERFACE_PERMISSIONS.PAYROLL_OUTSOURCED),
    [selectedPosition]
  );

  const hasPermissionForCreateQualification = useMemo(
    () => selectedPosition?.permissions?.includes(TEAMS_PERMISSIONS.CAN_CREATE_QUALIFICATION),
    [selectedPosition]
  );

  const getUserPreviewData = useCallback(async () => {
    const urlItems = getUrlItems(id);
    const userId = urlItems?.id;
    if (!userId) {
      return;
    }
    try {
      setLoadingTrue();
      const res = await getEmployeePreviewRequest({ companyId: selectedCompanyId, userId });
      getPassport(userId);
      getRTW(userId);
      setUserPreviewData(res.data);
    } catch (e) {
      handleServerError(e);
    } finally {
      setLoadingFalse();
    }
  }, [id, selectedCompanyId]);

  const getPassport = async (userId: string) => {
    try {
      const res = await getPassportRequest({ companyId: selectedCompanyId, userId });
      setPassport(res.data);
    } catch (e) {
      console.error(e);
    }
  };

  const getRTW = async (userId: string) => {
    try {
      const { data } = await getRtwRequest({ companyId: selectedCompanyId, userId });
      setRtw(data.items?.length ? data.items[0] : data);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getUserPreviewData();
  }, []);

  const goToEditView = () => {
    history.push(`${history.location.pathname}/personal`);
  };

  return {
    userPreviewData,
    userPreviewDataLoading: loading,
    passport,
    rtw,
    hasPayrollPermissions,
    hasPermissionForCreateQualification,
    selectedCompanyId,
    userId,
    goToEditView,
    getUserPreviewData,
  };
};
