import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import {
  Box,
  Typography,
  Paper,
  useTheme,
  CircularProgress,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core';
import { BiMicrophone } from 'react-icons/bi';
import { FaPlay } from 'react-icons/fa6';
import { ReactMic, ReactMicStopEvent } from 'react-mic';
import { HiOutlineTrash } from 'react-icons/hi';

import { AppTextField } from '@vyce/core/src/components/inputs';
import { DeviceContext } from '@vyce/core/src/contexts';
import { AppIconButton } from '@vyce/core/src/components';
import { blobToBase64 } from '@vyce/core/src/utils';

import { AIChatContext } from '../context';

const controlHeight = 56;

export const AskAIInput: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { isMobile } = useContext(DeviceContext);
  const [question, setQuestion] = useState<string>('');
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [isRemoved, setIsRemoved] = useState<boolean>(false);
  const [showAudioStream, setShowAudioStream] = useState<boolean>(false);
  const { loading, addUserMessage, speechToText } = useContext(AIChatContext);
  const [timer, setTimer] = useState<number>(0);
  const [timerActive, setTimerActive] = useState<boolean>(false);
  const [audio, setAudio] = useState<string>('');

  const iconButtonStyles = {
    height: controlHeight,
    width: controlHeight,
    borderRadius: 16,
    backgroundColor: theme.palette.primary.main,
  };

  const gap = isMobile ? 8 : 16;

  const formattedTime = useMemo(() => {
    const seconds = timer % 60;
    const minutes = Math.floor(timer / 60);
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }, [timer]);

  const toggleTimer = () => {
    setTimerActive(!timerActive);
  };

  const resetTimer = () => {
    setTimer(0);
    setTimerActive(false);
  };

  const showSendButton = useMemo(() => !!question || isRecording, [question, isRecording]);

  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter' && !!question) {
      e.preventDefault();
      addUserMessage(question);
      setQuestion('');
    }
  };

  const onButtonClick = () => {
    if (question) {
      addUserMessage(question);
      setQuestion('');
    } else if (isRecording) {
      stopRecording();
    } else {
      setAudio('');
      setIsRemoved(false);
      toggleTimer();
      setShowAudioStream(true);
    }
  };

  const onStop = useCallback(async (recordedBlob: ReactMicStopEvent) => {
    const base64Audio = await blobToBase64(recordedBlob.blob);
    setAudio(base64Audio);
  }, []);

  const stopRecording = () => {
    setShowAudioStream(false);
    toggleTimer();
    resetTimer();
  };

  useEffect(() => {
    setTimeout(() => setIsRecording(showAudioStream));
  }, [showAudioStream]);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (timerActive) {
      interval = setInterval(() => {
        setTimer(prevTimer => prevTimer + 1);
      }, 1000);
    } else if (!timerActive && timer !== 0) {
      clearInterval(interval!);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [timerActive]);

  useEffect(() => {
    if (audio && !isRemoved) {
      speechToText(audio);
    }
  }, [audio, isRemoved]);

  return (
    <Paper
      variant="outlined"
      className={classes.container}
      style={{ position: 'fixed', bottom: 8, width: 'calc(100% - 32px)', maxWidth: 868 }}>
      <Box display="flex" flexDirection="column" gridGap={gap}>
        <Box display="flex" gridGap={gap}>
          <Box alignItems="flex-end" display="flex" flex={1}>
            <Box
              display="flex"
              flex={1}
              style={{
                visibility: showAudioStream ? 'visible' : 'hidden',
                width: showAudioStream ? 'auto' : 0,
              }}
              gridGap={gap}>
              <AppIconButton
                style={{
                  ...iconButtonStyles,
                  backgroundColor: isMobile
                    ? theme.palette.background.default
                    : theme.palette.background.paper,
                  border: isMobile ? 'none' : `2px solid ${theme.palette.primary.main}`,
                }}
                disableFocusRipple
                disableRipple
                disableTouchRipple
                onClick={() => {
                  setAudio('');
                  setIsRemoved(true);
                  stopRecording();
                }}>
                <HiOutlineTrash color={theme.palette.primary.main} size="24px" />
              </AppIconButton>

              <Box
                display="flex"
                flex={1}
                style={{ backgroundColor: theme.palette.primary.main }}
                gridGap={12}
                alignItems="center"
                borderRadius={26}
                padding="0 16px">
                <Box minWidth={42}>
                  <Typography style={{ color: '#FFFFFF', fontSize: 15 }}>{formattedTime}</Typography>
                </Box>
                <ReactMic
                  record={isRecording}
                  visualSetting="sinewave"
                  className={isMobile ? 'sound-wave-mobile' : 'sound-wave'}
                  onStop={onStop}
                  strokeColor={theme.palette.background.paper}
                  backgroundColor={theme.palette.primary.main}
                />
              </Box>
            </Box>

            {!showAudioStream && (
              <AppTextField
                inputProps={
                  isMobile
                    ? {
                        style: {
                          backgroundColor: theme.palette.background.paper,
                        },
                      }
                    : undefined
                }
                multiline
                fullWidth
                value={question}
                onChange={e => setQuestion(e.target.value)}
                onKeyDown={handleKeyPress}
                label="Ask me anything"
              />
            )}
          </Box>

          <Box display="flex" alignItems="flex-end" gridGap={16}>
            <AppIconButton
              isBorder
              style={iconButtonStyles}
              disabled={loading}
              borderColor={theme.palette.primary.main}
              disableFocusRipple
              disableRipple
              disableTouchRipple
              onClick={onButtonClick}>
              {loading ? (
                <CircularProgress size={24} style={{ color: '#FFFFFF' }} />
              ) : showSendButton ? (
                <FaPlay color="#FFFFFF" size="20px" />
              ) : (
                <BiMicrophone color="#FFFFFF" size="24px" />
              )}
            </AppIconButton>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: 24,
      margin: '0 16px 24px',
      border: `1px solid ${theme.palette.divider}`,
      [theme.breakpoints.down('xs')]: {
        margin: 0,
        padding: 0,
        border: 0,
        backgroundColor: 'transparent',
      },
    },
  })
);
