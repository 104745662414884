import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { CustomCertificate } from '@vyce/core/src/types';
import { DeviceContext } from '@vyce/core/src/contexts';
import { useBooleanState } from '@vyce/core/src/hooks';

import { Props, QualificationType } from '../types';
import { LIST_STEP } from '../config';
import { NotificationContext } from '../../../contexts/notificationContext';

export const useModuleData = ({
  userId = '',
  getQualificationsRequest,
  deleteCustomQualificationRequest,
}: Props) => {
  const [page, setPage] = useState(0);
  const [qualifications, setQualifications] = useState<QualificationType[]>([]);
  const [selectedQualificationId, setSelectedQualificationId] = useState<string | null>(null);
  const [isOpen, setDialogOpen, setDialogClose] = useBooleanState(false);
  const [isConfirmDialogOpen, setConfirmDialogOpen, setConfirmDialogClose] = useBooleanState(false);
  const [isPreviewDialogOpen, setPreviewDialogOpen, setPreviewDialogClose] = useBooleanState(false);
  const [isLoading, setLoadingTrue, setLoadingFalse] = useBooleanState(false);

  const { handleServerError } = useContext(NotificationContext);
  const { isMobile } = useContext(DeviceContext);

  const cuttedQualificationListByPage = useMemo(
    () => qualifications.slice(page * LIST_STEP, (page + 1) * LIST_STEP),
    [page, qualifications]
  );
  const pageCount = Math.ceil(qualifications.length / LIST_STEP);

  const paginationOptions = {
    page,
    pageSize: LIST_STEP,
    pageCount,
    rowCount: qualifications.length,
    setPage,
  };

  const selectedQualification = useMemo(
    () => qualifications.find(item => item.uuid === selectedQualificationId),
    [selectedQualificationId, qualifications]
  );

  const getQualifications = useCallback(async () => {
    try {
      setLoadingTrue();
      const res = await getQualificationsRequest({ userId, parameters: { limit: 500 } });

      const arr = [
        ...(res.data.certificates?.items.map(item => ({ ...item, type: 'certificate' })) || []),
        ...(res.data.courses?.items.map(item => ({ ...item, type: 'course' })) || []),
        ...(res.data.tests?.items.map(item => ({ ...item, type: 'test' })) || []),
      ];
      setQualifications(arr);
    } catch (e) {
      handleServerError(e);
    } finally {
      setLoadingFalse();
    }
  }, [userId]);

  const deleteCustomQualification = useCallback(async () => {
    if (!selectedQualificationId) return;

    try {
      await deleteCustomQualificationRequest(selectedQualificationId, userId);
      const arr = [...qualifications];
      const index = arr.findIndex(item => item.uuid === selectedQualificationId);
      arr.splice(index, 1);
      setQualifications(arr);
      setConfirmDialogClose();
    } catch (e) {
      handleServerError(e);
    }
  }, [selectedQualificationId]);

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    setQualifications(prevItems => {
      const dubl = [...prevItems];
      dubl.splice(dragIndex, 1);
      dubl.splice(hoverIndex, 0, prevItems[dragIndex]);
      return dubl;
    });
  }, []);

  const handleOpenPreviewDialog = (id: string) => {
    setPreviewDialogOpen();
    setSelectedQualificationId(id);
  };

  const handleDeleteByPreviewButton = (id: string) => {
    setConfirmDialogOpen();
    setSelectedQualificationId(id);
  };

  const handleOpenByAddButton = () => {
    setDialogOpen();
  };

  const handleOpenByEditButton = (id: string) => {
    const qualification = qualifications.find(item => item.uuid === id) as CustomCertificate;

    if (!qualification) return;
    setSelectedQualificationId(id);
    setDialogOpen();
  };

  useEffect(() => {
    getQualifications();
  }, []);

  return {
    isLoading,
    isMobile,
    qualifications,
    isOpen,
    isConfirmDialogOpen,
    isPreviewDialogOpen,
    selectedQualification,
    paginationOptions,
    cuttedQualificationListByPage,
    getQualifications,
    handleOpenByAddButton,
    handleOpenByEditButton,
    moveCard,
    setSelectedQualificationId,
    setConfirmDialogClose,
    handleDeleteByPreviewButton,
    deleteCustomQualification,
    setPreviewDialogClose,
    handleOpenPreviewDialog,
    setDialogClose,
  };
};
