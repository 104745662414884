import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { GridSortModel } from '@mui/x-data-grid';
import isEqual from 'lodash/isEqual';
import { useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';

import { GRID_PAGE_SIZE } from '@vyce/core/src/constants';
import { Company, EnrichedPayslips } from '@vyce/core/src/types';
import { generateExcelFileLink, generateFileLinkAndSave } from '@vyce/core/src/utils';
import { GetPayslipsData, GetPayslipsRequest } from '@vyce/core/src/api/types';
import { formatSortModel } from '@vyce/core/src/utils/sorting';
import { getUrlItems } from '@vyce/core/src/utils/url';

import { PaymentsTable } from './PaymentsTable';
import { NotificationContext } from '../../../contexts/notificationContext';

interface Props {
  searchPayslipsRequest: (
    params: GetPayslipsRequest
  ) => Promise<{ data: { items: EnrichedPayslips[]; count: number } }>;
  downloadUserPayslipsRequest: (params: GetPayslipsRequest) => Promise<AxiosResponse>;
  getPayslipsPDFRequest: (id: string, companyId?: string) => Promise<AxiosResponse>;
  selectedCompany?: Company;
  isLegend: boolean;
  unit?: string;
  withProfileButton?: boolean;
}

type EnrichedPayslipsProps = EnrichedPayslips & { id: string };

export const EmployeePayments: React.FC<Props> = ({
  searchPayslipsRequest,
  downloadUserPayslipsRequest,
  getPayslipsPDFRequest,
  isLegend,
  selectedCompany,
  unit,
  withProfileButton,
}) => {
  const { handleServerError } = useContext(NotificationContext);
  const [payslips, setPayslips] = useState<EnrichedPayslipsProps[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState<number>(0);
  const [offset, setOffset] = useState<number>(0);
  const [filters, setFilters] = useState<any>({});
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'pay_date', sort: 'desc' }]);
  const { id } = useParams<{ id: string }>();

  const pageCount = Math.ceil(total / GRID_PAGE_SIZE);

  const downloadPdf = async (id: string) => {
    try {
      const res = await getPayslipsPDFRequest(id, selectedCompany?.uuid);
      generateFileLinkAndSave(res);
    } catch (e) {
      handleServerError(e);
    }
  };

  const getPayslips = useCallback(
    async (data: GetPayslipsData) => {
      try {
        const urlItems = getUrlItems(id);
        setLoading(true);
        const res = await searchPayslipsRequest({
          userId: urlItems.id,
          companyId: isLegend ? undefined : selectedCompany?.uuid,
          data: {
            ...data,
            limit: GRID_PAGE_SIZE,
          },
        });
        setLoading(false);
        setPayslips(
          res.data.items.filter(item => item.net !== 0).map((item, i) => ({ ...item, id: String(i) }))
        );
        setTotal(res.data.count);
      } catch (e) {
        setLoading(false);
        handleServerError(e);
      }
    },
    [id, isLegend, searchPayslipsRequest, selectedCompany?.uuid]
  );

  const handlePageChange = useCallback(
    (newPage: number) => {
      const newOffset = newPage * GRID_PAGE_SIZE;
      setOffset(newOffset);
      setPage(newPage);
      getPayslips({
        offset: newOffset,
        order_by: formatSortModel<EnrichedPayslipsProps>(sortModel),
        tax_year_end: filters?.tax_year_end?.value,
      });
    },
    [filters?.tax_year_end?.value, getPayslips, sortModel]
  );

  const paginationOptions = useMemo(
    () => ({
      page,
      pageSize: GRID_PAGE_SIZE,
      pageCount,
      rowCount: total,
      setPage: handlePageChange,
    }),
    [page, pageCount, total, handlePageChange]
  );

  const handleFilterChange = (newFilters: any) => {
    setFilters(newFilters);
    getPayslips({
      offset,
      order_by: formatSortModel<EnrichedPayslipsProps>(sortModel),
      tax_year_end: newFilters?.tax_year_end?.value,
    });
  };

  const handleSortModelChange = (newModel: GridSortModel) => {
    if (isEqual(newModel, sortModel)) {
      return;
    }
    setSortModel(newModel);
    const formattedModel = [...newModel];
    const yearModel = newModel.find(model => model.field === 'tax_year_end');
    if (yearModel) {
      formattedModel.push({ ...yearModel, field: 'tax_week_end' });
    }
    getPayslips({
      offset,
      order_by: formatSortModel<EnrichedPayslipsProps>(formattedModel),
      tax_year_end: filters?.tax_year_end?.value,
    });
  };

  const handleDownloadPaySummaryClick = async () => {
    try {
      const urlItems = getUrlItems(id);
      const res = await downloadUserPayslipsRequest({
        userId: urlItems.id,
        companyId: isLegend ? undefined : selectedCompany?.uuid,
        data: {
          tax_year_end: filters?.tax_year_end?.value,
          order_by: formatSortModel<EnrichedPayslipsProps>(sortModel),
          limit: 10000,
        },
      });
      generateExcelFileLink(res);
    } catch (e) {
      setLoading(false);
      handleServerError(e);
    }
  };

  useEffect(() => {
    getPayslips({
      offset,
      order_by: formatSortModel<EnrichedPayslipsProps>(sortModel),
      tax_year_end: filters?.tax_year_end?.value,
    });
  }, []);

  return (
    <PaymentsTable
      handleDownloadPaySummaryClick={handleDownloadPaySummaryClick}
      downloadPdf={downloadPdf}
      height={'calc(100vh - 240px)'}
      handlePageChange={handlePageChange}
      handleFilterChange={handleFilterChange}
      handleSortModelChange={handleSortModelChange}
      sortModel={sortModel}
      loading={loading}
      payslips={payslips}
      total={total}
      unit={unit}
      withProfileButton={withProfileButton}
      paginationOptions={paginationOptions}
    />
  );
};
