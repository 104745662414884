import { useCallback, useContext, useState } from 'react';

import uuid from 'uuid';

import { TimePeriodSettings } from '@vyce/core/src/types';
import {
  getPeriodSettingsRequest,
  getTimeModulePreferencesRequest,
  setPeriodSettingsRequest,
  updateTimeModulePreferencesRequest,
} from '@vyce/core/src/api/time';

import { timelogDays, TimeLogsPeriods } from '../config';
import { NotificationContext } from '../../../../contexts/notificationContext';

interface Props {
  companyId?: string;
}

export const useSettingsData = ({ companyId }: Props) => {
  const { handleServerError } = useContext(NotificationContext);
  const [endDay, setEndDay] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(true);
  const [signalForRefreshingId, setSignalForRefreshingId] = useState<string>(uuid.v4());
  const [countAutoClockOut, setCountAutoClockOut] = useState<boolean>(false);
  const [settings, setSettings] = useState<TimePeriodSettings>({
    period_type: TimeLogsPeriods.WEEKLY,
    weekday_start: 'monday',
    period_start_date: undefined,
  });

  const getPeriods = async () => {
    if (!companyId) {
      return;
    }
    try {
      setLoading(true);
      const res = await getPeriodSettingsRequest(companyId);
      setSettings(res.data);
      if (res.data.weekday_start) {
        setEndDay(getWeeklyEndDay(res.data.weekday_start));
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      handleServerError(e);
    }
  };

  const getPreferences = async () => {
    if (!companyId) {
      return;
    }
    try {
      const res = await getTimeModulePreferencesRequest(companyId);
      setCountAutoClockOut(res.data.count_auto_check_out);
    } catch (e) {
      handleServerError(e);
    }
  };

  const updatePreferences = async (countAutoClockOut: boolean) => {
    if (!companyId) {
      return;
    }
    setCountAutoClockOut(countAutoClockOut);
    try {
      await updateTimeModulePreferencesRequest(companyId, countAutoClockOut);
    } catch (e) {
      setCountAutoClockOut(!countAutoClockOut);
      handleServerError(e);
    }
  };

  const setNewSignalForRefreshingId = useCallback(() => {
    setSignalForRefreshingId(uuid.v4());
  }, []);

  const getWeeklyEndDay = (startDay: string): string => {
    const dayIndex = timelogDays.findIndex(item => item === startDay);
    let endDayIndex;
    if (dayIndex === 0) {
      endDayIndex = 6;
    } else {
      endDayIndex = dayIndex - 1;
    }
    return timelogDays[endDayIndex];
  };

  const onPeriodChange = async (settings: TimePeriodSettings) => {
    if (!companyId) {
      return;
    }
    setSettings(settings);
    if (settings.weekday_start) {
      setEndDay(getWeeklyEndDay(settings.weekday_start));
    }
    try {
      await setPeriodSettingsRequest(companyId, settings);
    } catch (e) {
      handleServerError(e);
    }
  };

  return {
    endDay,
    expanded,
    signalForRefreshingId,
    onPeriodChange,
    getPeriods,
    settings,
    loading,
    setExpanded,
    countAutoClockOut,
    getPreferences,
    updatePreferences,
    setNewSignalForRefreshingId,
  };
};
