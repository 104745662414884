import React, { useContext, useMemo } from 'react';

import { Box, Button, MenuItem, MenuList, Tooltip, Typography, useTheme } from '@material-ui/core';
import { GridColDef } from '@mui/x-data-grid';
import { AiOutlinePlus } from 'react-icons/ai';
import { FiAlertCircle, FiUpload } from 'react-icons/fi';
import { BiCheck } from 'react-icons/bi';
import { TbClockHour4 } from 'react-icons/tb';
import { RiCloseFill } from 'react-icons/ri';
import clsx from 'clsx';

import { AppA, AppDataGrid, GridActions } from '@vyce/core/src/components';
import { GRID_PAGE_SIZE } from '@vyce/core/src/constants';
import { getUKFormattedDate } from '@vyce/core/src/utils/dates';
import { useHorizontalScrollStyles } from '@vyce/core/src/styles';
import { AppSearchInput } from '@vyce/core/src/components/inputs';
import { DeviceContext } from '@vyce/core/src/contexts';
import { useInvitesData } from './hooks';
import { useStyles } from './styles';
import { InviteProps } from './types';
import { listOfModules } from './config';

export const Invites = ({ inviteButton, ...restProps }: InviteProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const horizontalScrollClasses = useHorizontalScrollStyles();
  const { isMobile } = useContext(DeviceContext);

  const {
    loading,
    invitedUsers,
    sortModel,
    total,
    handleInviteButtonClick,
    handleSortModelChange,
    handlePageChange,
    resendInvite,
    deleteInvite,
    handleSetSubstring,
  } = useInvitesData(restProps);

  const statusOptions = useMemo(
    () => ({
      accepted: { text: 'Accepted', icon: <BiCheck size={32} color={theme.palette.success.main} /> },
      pending: { text: 'Pending', icon: <TbClockHour4 size={32} color={theme.palette.text.primary} /> },
      not_sent: { text: 'Not sent', icon: <FiAlertCircle size={32} color={theme.palette.warning.main} /> },
      not_accepted: {
        text: 'Not accepted',
        icon: <RiCloseFill size={32} color={theme.palette.error.main} />,
      },
      sent: { text: 'Sent', icon: <FiUpload size={32} color={theme.palette.text.primary} /> },
    }),
    [
      theme.palette.error.main,
      theme.palette.success.main,
      theme.palette.text.primary,
      theme.palette.warning.main,
    ]
  );

  const columns: GridColDef[] = [
    {
      field: 'email_and_phone',
      headerName: 'Invite email/ phone number',
      flex: 0.1,
      minWidth: 250,
      sortable: false,
      disableColumnMenu: true,
      renderCell: params => (
        <AppA
          content={params.row.email || params.row.phone}
          href={params.row.email ? `mailto: ${params.row.email}` : `tel: ${params.row.phone}`}
        />
      ),
    },
    {
      field: 'company_name',
      headerName: 'Company',
      flex: 0.1,
      disableColumnMenu: true,
      minWidth: 110,
    },
    {
      field: 'payload',
      headerName: 'Modules',
      flex: 0.15,
      disableColumnMenu: true,
      minWidth: 120,
      renderCell: params => {
        const modules: string[] = [];
        const payload = params.row?.payload || {};
        Object.keys(payload).forEach((key: string) => {
          if (payload[key]?.length) {
            const moduleName = listOfModules[key as keyof typeof listOfModules];
            modules.push(moduleName);
          }
        });
        const value = modules.join(', ');
        return (
          <Tooltip title={value}>
            <Typography className={classes.customTextCell}>{value}</Typography>
          </Tooltip>
        );
      },
    },
    {
      field: 'created_by_full_name',
      headerName: 'Invited by',
      flex: 0.07,
      disableColumnMenu: true,
      minWidth: 120,
    },
    {
      field: 'created_at',
      headerName: 'Invite Date',
      flex: 0.07,
      disableColumnMenu: true,
      valueGetter: params => getUKFormattedDate(params.row?.created_at),
      minWidth: 120,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.07,
      minWidth: 130,
      renderCell: params => {
        const option = statusOptions[params.row.status as keyof typeof statusOptions];
        return (
          <Box display="flex" alignItems="center" gridGap={12}>
            {option.icon}
            <Typography className={classes.customTextCell}>{option.text}</Typography>
          </Box>
        );
      },
    },
    {
      field: '',
      headerName: 'Actions',
      width: 80,
      hideSortIcons: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: params => (
        <Box display="flex" width="100%">
          <GridActions>
            <MenuList>
              <MenuItem onClick={() => resendInvite(params.row.uuid, params.row.company_id)}>
                Resend email
              </MenuItem>
              <MenuItem onClick={() => deleteInvite(params.row.uuid, params.row.company_id)}>
                Cancel invitation
              </MenuItem>
            </MenuList>
          </GridActions>
        </Box>
      ),
    },
  ];

  return (
    <>
      <Box
        mb={2}
        gridGap={16}
        className={clsx(horizontalScrollClasses.blockWrapper, horizontalScrollClasses.blockWithHideScroll)}>
        <Box>
          <AppSearchInput onChange={handleSetSubstring} isSmall expanded={!isMobile} />
        </Box>

        <Box display="flex" justifyContent="flex-end">
          {inviteButton ? (
            React.createElement(inviteButton)
          ) : (
            <Button
              onClick={handleInviteButtonClick}
              size="small"
              data-tour="invite"
              startIcon={<AiOutlinePlus />}
              variant="contained"
              color="primary">
              Invite New Members
            </Button>
          )}
        </Box>
      </Box>

      <AppDataGrid
        rows={invitedUsers}
        noPaper
        height="calc(100vh - 250px)"
        getRowId={row => row.uuid}
        columns={columns}
        loading={loading}
        pageSize={GRID_PAGE_SIZE}
        paginationMode="server"
        sortingMode="server"
        sortModel={sortModel}
        rowCount={total}
        onSortModelChange={handleSortModelChange}
        onPageChange={handlePageChange}
        rowsPerPageOptions={[GRID_PAGE_SIZE]}
        disableSelectionOnClick
        unit="invites"
        withProfileButton
      />
    </>
  );
};
