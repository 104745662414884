import { TabItem } from '@vyce/core/src/types';
import * as expiredData from './animations/expired-tag.json';
import * as unverifiedData from './animations/unverified-tag.json';

export const DEFAULT_ANIMATION_OPTIONS = {
  loop: true,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export const expiredOptions = {
  ...DEFAULT_ANIMATION_OPTIONS,
  animationData: expiredData,
};

export const unverifiedOptions = {
  ...DEFAULT_ANIMATION_OPTIONS,
  animationData: unverifiedData,
};

export const WORKER_PROFILE_TABS: TabItem[] = [
  {
    label: 'Personal',
    link: '/profile/personal',
  },
  {
    label: 'Professional',
    link: '/profile/professional',
  },
  {
    label: 'ID & RTW',
    link: '/profile/documents',
  },
  {
    label: 'Qualifications',
    link: '/profile/qualifications',
  },
  {
    label: 'Medical',
    link: '/profile/medical',
  },
];

export const EMPLOYER_HIRING_TABS: TabItem[] = [
  {
    label: 'Live Jobs',
    link: '/hiring/live',
  },
  {
    label: 'Draft Jobs',
    link: '/hiring/draft',
  },
  {
    label: 'Old Jobs',
    link: '/hiring/old',
  },
];

export const EMPLOYER_CANDIDATES_TABS: TabItem[] = [
  {
    label: 'Matched',
    link: 'matched',
  },
  {
    label: 'Interested',
    link: 'interested',
  },
  {
    label: 'Connected',
    link: 'connected',
  },
  {
    label: 'Declined',
    link: 'declined',
  },
];

export const WORKER_JOBS_TABS: TabItem[] = [
  {
    label: 'Matched',
    link: '/jobs/matched',
  },
  {
    label: 'Interested',
    link: '/jobs/interested',
  },
  {
    label: 'Connected',
    link: '/jobs/connected',
  },
  {
    label: 'Declined',
    link: '/jobs/declined',
  },
];

export const COMPANY_PROFILE_TABS: TabItem[] = [
  {
    label: 'General',
    link: 'general',
  },
  {
    label: 'Bank Details',
    link: 'bank',
  },
  {
    label: 'Socials',
    link: 'socials',
  },
  {
    label: 'Testimonials',
    link: 'testimonials',
  },
  // TODO: Implement when backend will be ready
  // {
  //   label: 'Case Studies',
  //   link: 'studies',
  // },
  {
    label: 'Photos',
    link: 'photos',
  },
];
