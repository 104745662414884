import { TbMapPin, TbTarget, TbBriefcase } from 'react-icons/tb';
import { PiListBulletsBold, PiGiftBold, PiMoneyBold, PiGearSixBold } from 'react-icons/pi';
import { LuKeyRound, LuLayers, LuScrollText, LuMessageSquare } from 'react-icons/lu';
import { FaArrowTrendUp } from 'react-icons/fa6';
import { CgFileDocument } from 'react-icons/cg';
import { GrTechnology, GrUserWorker, GrGlobe } from 'react-icons/gr';
import { MdOutlineManageAccounts } from 'react-icons/md';

import * as animationData from './animations/ai.json';
import { AIChatIdea } from '@vyce/core/src/types';

export const AI_TOOL_ACTIVATED = 'aiToolActivated';

export const ANIMATION_OPTIONS = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export const IDEAS: AIChatIdea[] = [
  {
    icon: FaArrowTrendUp,
    title: 'Latest trends',
    requests: [
      'Create a list of the latest technology trends in my industry in the UK',
      'What are the benefits of using workforce management software like Vyce for my company?',
      'As a construction subcontractor, what should I be worried about in the future?',
    ],
  },
  {
    icon: PiGiftBold,
    title: 'Gift ideas',
    requests: [
      'It’s my 15th wedding anniversary. Can you give me a list of possible gifts for my wife',
      'It’s my daughter’s 11th birthday coming up. She loves cricket. Give me a list of birthday ideas',
      'It’s my company’s 10 year anniversary, give me a list of appropriate gift ideas for my boss the founder',
    ],
  },
  {
    icon: LuLayers,
    title: 'Project Management and Planning',
    requests: [
      'Can you help create a project timeline for a residential building project?',
      'What are the key milestones in a commercial construction project?',
      'How can we optimize our construction schedule to accommodate unexpected delays?',
    ],
  },
  {
    icon: LuScrollText,
    title: 'Compliance and Regulations',
    requests: [
      'What are the current building regulations in the UK for a new commercial development?',
      'Can you explain the latest updates to the Health and Safety at Work Act relevant to construction?',
      'What documentation do we need to comply with the Construction Design and Management Regulations 2015?',
    ],
  },
  {
    icon: PiMoneyBold,
    title: 'Cost Estimation and Budget Management',
    requests: [
      'How can we create a detailed cost estimate for a new construction project?',
      'What are the best practices for managing construction costs without sacrificing quality?',
      'Can you help us prepare a budget for a refurbishment project?',
    ],
  },
  {
    icon: MdOutlineManageAccounts,
    title: 'Resource Allocation and Management',
    requests: [
      'What is an effective labor allocation strategy for multiple concurrent projects?',
      'How can we efficiently manage equipment rentals across different job sites?',
      'What software tools are recommended for inventory management in construction?',
    ],
  },
  {
    icon: CgFileDocument,
    title: 'Contract and Negotiation',
    requests: [
      'What should be included in a subcontractor agreement?',
      'How can we negotiate better terms with suppliers?',
      'What are the essential clauses to include in a construction contract to protect our interests?',
    ],
  },
  {
    icon: GrTechnology,
    title: 'Technology Integration',
    requests: [
      'What are the latest technological advancements in construction that we should consider adopting?',
      'How can we implement BIM (Building Information Modeling) in our next project?',
      'What are the benefits of using drones in construction site monitoring',
    ],
  },
  {
    icon: PiGearSixBold,
    title: 'Risk Management',
    requests: [
      'What are common risks in construction projects and how can we mitigate them?',
      'How do we conduct a risk assessment for a high-rise construction project?',
      'What insurance policies are essential for a construction subcontractor in the UK?',
    ],
  },
  {
    icon: TbBriefcase,
    title: 'Client Communication and Relationship Management',
    requests: [
      'What are effective communication strategies to maintain client satisfaction?',
      'How can we use CRM (Customer Relationship Management) software to improve client relations?',
      'What are the best practices for handling client complaints in construction?',
    ],
  },
  {
    icon: GrUserWorker,
    title: 'Workforce Training and Development',
    requests: [
      'What training programs are essential for new construction workers?',
      'How can we promote safety culture among our workforce?',
      'What are the certifications required for workers in specialized construction roles?',
    ],
  },
  {
    icon: GrGlobe,
    title: 'Sustainability and Green Building Practices',
    requests: [
      'What are the best practices for sustainable construction in the UK?',
      'How can we incorporate green building materials into our projects?',
      'What government incentives are available for implementing eco-friendly construction techniques?',
    ],
  },
];

export const TIPS = [
  {
    icon: LuMessageSquare,
    title: 'One question at a time',
    text: 'To get a focused response, ask one question per prompt.',
  },
  {
    icon: TbMapPin,
    title: 'Be direct',
    text: 'Use clear and straightforward language to avoid misunderstandings.',
  },
  {
    icon: TbTarget,
    title: 'Be specific',
    text: 'Include all the relevant details in your question for an accurate and helpful answer.',
  },
  {
    icon: PiListBulletsBold,
    title: 'Request the format',
    text: 'If you’d like to get a list, a detailed explanation or a step-by-step instruction, mention it!',
  },
  {
    icon: LuKeyRound,
    title: 'Include keywords',
    text: 'Using the right keywords will improve the answer, especially in technical or niche topics.',
  },
];
