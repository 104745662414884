import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  selector: {
    borderRadius: 6,
    '& .MuiInputBase-input': {
      paddingTop: 10,
    },
  },
}));
