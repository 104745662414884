import { useCallback, useContext, useEffect, useState } from 'react';

import isEqual from 'lodash/isEqual';
import { GridSortModel } from '@mui/x-data-grid';

import { AddTimeWorkerData, Company, Employee, AddMemberForm } from '@vyce/core/src/types';
import { useBooleanState, useDebounceValue } from '@vyce/core/src/hooks';
import { addSiteWorkerRequest, getLookupSiteWorkersRequest } from '@vyce/core/src/api/time';
import { GetSiteWorkersRequestPayload, WorkerLookupDTO } from '@vyce/core/src/api/types';
import { GRID_PAGE_SIZE, TABLE_OFFSET_DELAY } from '@vyce/core/src/constants';
import { formatTimeSortModel } from '@vyce/core/src/utils/sorting';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';
import { isNil } from '@vyce/core/src/utils';

interface Props {
  siteId?: string;
  isSitePage?: boolean;
  selectedCompany?: Company;
}

export const useLocationUsersData = ({ siteId, isSitePage, selectedCompany }: Props) => {
  const [loadingTableData, setLoadingTableData] = useState<boolean>(false);
  const { handleServerError } = useContext(NotificationContext);
  const [substring, setSubstring] = useState<string>('');
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'first_name', sort: 'asc' }]);
  const [offset, setOffset] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [workers, setWorkers] = useState<WorkerLookupDTO[]>([]);
  const [isAddMemberDialogOpen, openAddMemberDialog, closeAddMemberDialog] = useBooleanState(false);

  const dOffset = useDebounceValue(offset, TABLE_OFFSET_DELAY);

  const selectedCompanyId = selectedCompany?.uuid ?? '';

  const getMembers = useCallback(async () => {
    if (!selectedCompanyId || isNil(dOffset)) {
      return setWorkers([]);
    }

    setLoadingTableData(true);
    try {
      const payload: GetSiteWorkersRequestPayload = {
        offset: dOffset as number,
        limit: GRID_PAGE_SIZE,
        substring,
        order_by: formatTimeSortModel<WorkerLookupDTO>(sortModel),
        site_id: siteId,
      };
      const res = await getLookupSiteWorkersRequest({ companyId: selectedCompanyId, payload });
      setTotal(res.data.count);
      setWorkers(res.data.items);
      setLoadingTableData(false);
    } catch (e) {
      setLoadingTableData(false);
      handleServerError(e);
    }
  }, [dOffset, sortModel, substring, selectedCompanyId]);

  const addMember = async (data: AddMemberForm, employee?: Employee) => {
    if (!employee || !selectedCompanyId || !siteId) {
      return;
    }

    try {
      const worker: AddTimeWorkerData = {
        basic_amount: data.basic_amount,
        basic_unit: data.basic_unit,
        overtime_amount: data.overtime_amount,
        overtime_unit: data.overtime_unit,
        user_id: employee.user_id,
      };
      await addSiteWorkerRequest({
        companyId: selectedCompanyId,
        siteId,
        shiftId: data.shift_id,
        worker,
      });
      closeAddMemberDialog();
      getMembers();
    } catch (e) {
      handleServerError(e);
    }
  };

  const handleSearchChange = (event: any) => {
    setSubstring(event.target.value);
  };

  const handleSortModelChange = (newModel: GridSortModel) => {
    if (isEqual(newModel, sortModel)) {
      return;
    }
    setSortModel(newModel);
  };

  const handlePageChange = (newPage: number) => {
    setOffset(newPage * GRID_PAGE_SIZE);
  };

  useEffect(() => {
    if (isSitePage && siteId) {
      getMembers();
    } else if (!isSitePage) {
      getMembers();
    }
  }, [siteId, isSitePage, getMembers]);

  return {
    total,
    workers,
    substring,
    sortModel,
    isAddMemberDialogOpen,
    loadingTableData,
    closeGridAction: false,
    setOffset,
    handlePageChange,
    handleSearchChange,
    addMember,
    getMembers,
    handleSortModelChange,
    openAddMemberDialog,
    closeAddMemberDialog,
  };
};
