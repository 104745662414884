// @ts-nocheck
import React, { useEffect, useMemo } from 'react';

import InputMask from 'react-input-mask';
import { Box, Typography, useTheme } from '@material-ui/core';
import { HiMinus, HiPlus } from 'react-icons/hi';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { AppTextField } from '../../../../components/inputs';
import { monochrome } from '../../../../theme/styles';
import { numbersToSeconds, numbersToTime, secondsToTime, timeToSeconds } from '../../../../utils/dates';

interface Props {
  title: string;
  isMinus: boolean;
  trackedHours: number;
  trackedMinutes: number;
  value: string;
  handleIconClick: Function;
  setValue: Function;
  setIsNegativeTotal: Function;
  setTotal: Function;
}

export const AMENDMENT_INPUT_LENGTH = 5;

export const AmendmentInput: React.FC<Props> = ({
  title,
  isMinus,
  trackedHours,
  trackedMinutes,
  value,
  handleIconClick,
  setValue,
  setIsNegativeTotal,
  setTotal,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const trackedTime = useMemo(
    () => numbersToTime(trackedHours, trackedMinutes),
    [trackedHours, trackedMinutes]
  );

  const totalSeconds = useMemo(() => {
    const amendmentSeconds = timeToSeconds(value, isMinus);

    const trackedSeconds = numbersToSeconds(trackedHours, trackedMinutes);
    if (isNaN(amendmentSeconds)) {
      return trackedSeconds;
    }
    return trackedSeconds + amendmentSeconds;
  }, [trackedTime, value, isMinus]);

  useEffect(() => {
    setIsNegativeTotal(totalSeconds < 0);
    setTotal(totalSeconds);
  }, [totalSeconds]);

  return (
    <Box>
      <Typography className={classes.title}>{title}:</Typography>

      <Box display="flex" alignItems="center" gridGap={16} marginTop={2}>
        <Typography style={{ fontWeight: 500 }} variant="caption">
          {trackedTime}
        </Typography>

        <Box onClick={() => handleIconClick()} className={classes.iconButton}>
          {isMinus ? (
            <HiMinus color={theme.palette.text.secondary} size={16} />
          ) : (
            <HiPlus color={theme.palette.text.secondary} size={16} />
          )}
        </Box>

        <InputMask value={value} onChange={e => setValue(e.target.value)} mask="99:99" maskChar={null}>
          {() => (
            <AppTextField
              style={{ width: 100 }}
              error={value.length < AMENDMENT_INPUT_LENGTH || totalSeconds < 0}
              label="HH:MM"
              helperText={totalSeconds < 0 && 'less than 0'}
              type="tel"
              fullWidth
            />
          )}
        </InputMask>

        <Typography style={{ fontWeight: 500 }} variant="caption">
          =
        </Typography>

        <Typography style={{ fontWeight: 500, width: 36 }} variant="caption" color="secondary">
          {secondsToTime(totalSeconds)}
        </Typography>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles(theme =>
  createStyles({
    title: {
      fontSize: '18px',
      color: theme.palette.type === 'dark' ? monochrome.light : monochrome.dark,
    },
    iconButton: {
      cursor: 'pointer',
      borderRadius: '100%',
      background: theme.palette.type === 'dark' ? monochrome.mediumdark : monochrome.light,
      minWidth: 32,
      height: 32,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  })
);
