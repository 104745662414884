import React, { useCallback, useContext } from 'react';

import { Box, Button, Typography } from '@material-ui/core';
import { AiOutlinePlus } from 'react-icons/ai';
import clsx from 'clsx';
import { BiExport } from 'react-icons/bi';

import {
  ButtonTitleWithLoading,
  CustomizeDashboard,
  EmptyList,
  FilterSystem,
} from '@vyce/core/src/components';
import { WidgetItem } from '@vyce/core/src/types';
import { AppDashboard } from '@vyce/core/src/components/Dashboard/AppDashboard';
import { TeamsDashboardModuleContext } from '@vyce/core/src/contexts';
import { useHorizontalScrollStyles } from '@vyce/core/src/styles';
import { HealthSafetyWidget } from '@vyce/core/src/views/widgets/HealthSafetyWidget';
import { QualificationWidget } from '@vyce/core/src/views/widgets/QualificationWidget';
import config from '@vyce/core/src/assets/config';

import { useTeamsDashboardData } from './hooks';

export const TeamsDashboard = () => {
  const horizontalScrollClasses = useHorizontalScrollStyles();

  const { widgetComponent, onOpenUniversalInviteForm, inviteButtonComponent } =
    useContext(TeamsDashboardModuleContext);

  const {
    isMobile,
    first_name,
    companyDataLoading,
    teamsDashboard,
    filters,
    teams,
    filtersSections,
    selectedCompanyId,
    selectedTeamId,
    buttonLoading,
    exportQualifications,
    handleFilterChange,
    onWidgetStatusChange,
    onLayoutChange,
    goToTeamsList,
  } = useTeamsDashboardData();

  const getWidget = useCallback(
    (widget: WidgetItem) => {
      switch (widget.system_name) {
        case 'team_health_and_safety':
          return <HealthSafetyWidget companyId={selectedCompanyId} teamId={selectedTeamId} />;
        case 'teams_qualifications':
          return <QualificationWidget selectedCompanyId={selectedCompanyId} />;
        default:
          return React.createElement(widgetComponent, { widget, redirectUrl: '/teams/members' });
      }
    },
    [selectedCompanyId, selectedTeamId]
  );

  return (
    <>
      <Box
        className={clsx(
          horizontalScrollClasses.blockWrapper,
          horizontalScrollClasses.blockWithHideScroll,
          horizontalScrollClasses.navBarBlock
        )}>
        {!isMobile && (
          <Box display="flex">
            <Typography
              variant="subtitle1"
              style={{ marginRight: 4, fontWeight: 600, whiteSpace: 'nowrap' }}
              color="secondary">
              Hey {first_name},
            </Typography>
            <Typography variant="subtitle1" style={{ whiteSpace: 'nowrap' }} color="textSecondary">
              here’s your Teams dashboard...
            </Typography>
          </Box>
        )}
        <Box display="flex" gridGap={16}>
          <FilterSystem
            selectedValues={filters}
            filtersSections={filtersSections}
            onFiltersChange={handleFilterChange}
          />
          {inviteButtonComponent ? (
            React.createElement(inviteButtonComponent)
          ) : (
            <Button
              onClick={onOpenUniversalInviteForm}
              size="small"
              data-tour="invite"
              startIcon={<AiOutlinePlus />}
              variant="contained"
              color="primary">
              Invite New Members
            </Button>
          )}

          <Button
            onClick={exportQualifications}
            startIcon={!buttonLoading && <BiExport size="15px" />}
            style={{ width: 234 }}
            variant="contained"
            size="small"
            color="secondary">
            <ButtonTitleWithLoading
              title="Export Qualifications Matrix"
              loaderVariant="paper"
              loading={buttonLoading}
            />
          </Button>

          <CustomizeDashboard
            items={teamsDashboard?.widgets || []}
            onWidgetStatusChange={onWidgetStatusChange}
          />
        </Box>
      </Box>

      {!teams.length && !companyDataLoading ? (
        <EmptyList
          image={config.icons3D.lightning}
          title="We haven't found any team members."
          subtitle="Please add or invite team members to see data about you teams."
          buttonText="Manage My Teams"
          callback={goToTeamsList}
        />
      ) : (
        <Box style={{ marginRight: '-24px', marginLeft: '-24px' }}>
          <AppDashboard
            layouts={teamsDashboard?.layouts}
            onLayoutChange={onLayoutChange}
            widgets={teamsDashboard?.widgets || []}
            getWidget={getWidget}
            onWidgetStatusChange={onWidgetStatusChange}
          />
        </Box>
      )}
    </>
  );
};
