import { MouseEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import { LatLngExpression } from 'leaflet';

import defaultMarkerPicture from '@vyce/core/src/assets/avatar-male-placeholder.png';
import { getUrlItems } from '@vyce/core/src/utils/url';
import { EMPLOYER_CANDIDATES_TABS } from '@vyce/core/src/views/profile/config';
import { DeviceContext } from '@vyce/core/src/contexts/deviceContext';
import { Candidate, Job, MapMarker, TabItem } from '@vyce/core/src/types';
import {
  acceptWorkerApplicationRequest,
  applyWorkerRequest,
  cancelConnectionRequest,
  cancelEmployerDeclinationRequest,
  declineApplicationRequest,
  getCandidateRequest,
  getCandidatesRequest,
  getJobRequest,
  updateJobRequest,
} from '@vyce/core/src/api/connect';
import {
  DEFAULT_CALCULATED_DISTANCE,
  LIST_STEP,
  MILES_PER_HOUR,
  MINUTES_IN_HOUR,
  NEAREST_SORTING,
  POSSIBLE_DISTANCE,
  RELEVANT_SORTING,
} from '@vyce/core/src/modules/hiringModule/config';
import { MAP_MODE_LIMIT } from '../../../views/map/constants';
import { NotificationContext } from '../../../contexts/notificationContext';

interface Props {
  token: string;
  companyId: string;
}

export const useJobCandidatesData = ({ token, companyId }: Props) => {
  const { handleServerError, showNotification } = useContext(NotificationContext);
  const [currentJob, setCurrentJob] = useState<Job>();
  const [editMode, setEditMode] = useState<boolean>(true);
  const [jobDialogOpen, setJobDialogOpen] = useState<boolean>(false);
  const [connectDialogOpen, setConnectDialogOpen] = useState<boolean>(false);
  const [cancelConnectionDialogOpen, setCancelConnectionDialogOpen] = useState<boolean>(false);
  const [declineApplicationDialogOpen, setDeclineApplicationDialogOpen] = useState<boolean>(false);
  const [acceptDialogOpen, setAcceptDialogOpen] = useState<boolean>(false);
  const [cancelDeclinationDialogOpen, setCancelDeclinationDialogOpen] = useState<boolean>(false);
  const [userIdForConnectAction, setUserIdForConnectAction] = useState<string | undefined>();
  const [jobPreviewOpen, setJobPreviewOpen] = useState<boolean>(false);
  const [candidateDetailsOpen, setCandidateDetailsOpen] = useState<boolean>(false);
  const [sorting, setSorting] = useState<any>(RELEVANT_SORTING);
  const [jobId, setJobId] = useState<string>();
  const [markers, setMarkers] = useState<MapMarker[]>([]);
  const [filters, setFilters] = useState({});
  const [mapMode, setMapMode] = useState<boolean>(false);
  const [selectedMarker, setSelectedMarker] = useState<MapMarker>();
  const [tabs, setTabs] = useState<TabItem[]>(EMPLOYER_CANDIDATES_TABS);
  const [successConnectionDialogOpen, setSuccessConnectionDialogOpen] = useState<boolean>(false);
  const [candidates, setCandidates] = useState<Candidate[]>([]);
  const [selectedCandidate, setSelectedCandidate] = useState<Candidate>();
  const [connectedCandidate, setConnectedCandidate] = useState<Candidate>();
  const [count, setCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [dialogLoading, setDialogLoading] = useState<boolean>(false);
  const [page, setPage] = useState(0);

  const {
    candidate,
    category = 'matched',
    job,
  } = useParams<{ candidate: string; category: string; job: string }>();

  const history = useHistory();
  const { isMobile } = useContext(DeviceContext);

  const pageCount = Math.ceil(count / LIST_STEP);

  const contextProps = useMemo(
    () => ({
      token,
      isJobDialogOpen: jobDialogOpen,
      isEditMode: editMode,
    }),
    [token, jobDialogOpen, editMode]
  );

  const getCandidates = useCallback(
    async (jobId: string) => {
      setLoading(true);
      try {
        const data = {
          distance: DEFAULT_CALCULATED_DISTANCE,
          distance_type: 'mile',
          category,
          offset: mapMode ? 0 : page * LIST_STEP,
          limit: mapMode ? MAP_MODE_LIMIT : LIST_STEP,
          order_by: sorting,
          ...filters,
        };
        const {
          data: { items, count },
        } = await getCandidatesRequest(token, companyId, jobId, data);
        setCount(count);
        setCandidates(items);
        setLoading(false);
      } catch (e) {
        handleServerError(e);
        setLoading(false);
      }
    },
    [token, jobId, filters, page, mapMode, sorting]
  );

  const updateTabs = (pathname: string) => {
    const items = pathname.split('/');
    items.splice(items.length - 2, 2);
    const url = items.join('/');
    const newTabs = EMPLOYER_CANDIDATES_TABS.map(item => ({
      ...item,
      link: `${url}/${item.link}`,
    }));
    setTabs(newTabs);
  };

  const sort = (option: string) => {
    const sortData = option === 'Relevant' ? RELEVANT_SORTING : NEAREST_SORTING;
    setSorting(sortData);
  };

  const handleFilterChange = (newFilter: any) => {
    const distance =
      newFilter?.distance && newFilter.distance <= POSSIBLE_DISTANCE
        ? (newFilter.distance / MINUTES_IN_HOUR) * MILES_PER_HOUR
        : null;

    const newFilters = {
      ...filters,
      ...newFilter,
      id_verified: newFilter.id_verified || undefined,
      cscs_verified: newFilter.cscs_verified || undefined,
      cpcs_verified: newFilter.cpcs_verified || undefined,
      rtw_verified: newFilter.rtw_verified || undefined,
      distance,
    };
    setFilters(newFilters);
  };

  const getJobPosition = (): { position: LatLngExpression | undefined; popupText: string } => {
    return {
      position:
        currentJob?.address?.lon && currentJob?.address?.lat
          ? [currentJob.address?.lat, currentJob.address?.lon]
          : undefined,
      popupText: 'Your Job',
    };
  };

  const getJob = useCallback(
    async (uuid: string) => {
      try {
        const { data } = await getJobRequest(token, uuid);
        setCurrentJob(data);
      } catch (e) {
        handleServerError(e);
      }
    },
    [token]
  );

  const updateInfo = useCallback(async () => {
    if (!jobId) {
      return;
    }
    await getCandidates(jobId);
    if (candidate) {
      const candidateUrlItems = getUrlItems(candidate);
      const userId = candidateUrlItems?.id;
      await getCandidate(userId, jobId);
    }
  }, [jobId, candidate, page, filters, sorting]);

  const cancelDeclination = useCallback(async () => {
    if (!userIdForConnectAction || !jobId) {
      return;
    }
    setDialogLoading(true);
    try {
      await cancelEmployerDeclinationRequest(token, companyId, jobId, userIdForConnectAction);
      await updateInfo();
      setCancelDeclinationDialogOpen(false);
      setDialogLoading(false);
      showNotification({ message: 'Declination canceled', options: { variant: 'success' } });
    } catch (e) {
      setDialogLoading(false);
      handleServerError(e);
    }
  }, [token, jobId, userIdForConnectAction]);

  const acceptApplication = useCallback(async () => {
    if (!userIdForConnectAction || !jobId) {
      return;
    }
    setDialogLoading(true);
    try {
      await acceptWorkerApplicationRequest(token, companyId, jobId, userIdForConnectAction);
      setConnectedCandidate(candidates.find(candidate => candidate.user_id === userIdForConnectAction));
      await updateInfo();
      setSuccessConnectionDialogOpen(true);
      setAcceptDialogOpen(false);
      setDialogLoading(false);
      showNotification({ message: 'Application accepted', options: { variant: 'success' } });
    } catch (e) {
      setAcceptDialogOpen(false);
      setDialogLoading(false);
      handleServerError(e);
    }
  }, [token, jobId, userIdForConnectAction]);

  const declineApplication = useCallback(async () => {
    if (!userIdForConnectAction || !jobId) {
      return;
    }
    setDialogLoading(true);
    try {
      await declineApplicationRequest(token, companyId, jobId, userIdForConnectAction);
      await updateInfo();
      setDeclineApplicationDialogOpen(false);
      setDialogLoading(false);
      showNotification({ message: 'Application declined', options: { variant: 'info' } });
    } catch (e) {
      setDialogLoading(false);
      handleServerError(e);
    }
  }, [token, jobId, userIdForConnectAction]);

  const cancelConnection = useCallback(async () => {
    if (!userIdForConnectAction || !jobId) {
      return;
    }
    setDialogLoading(true);
    try {
      await cancelConnectionRequest(token, companyId, userIdForConnectAction, jobId);
      await updateInfo();
      setCancelConnectionDialogOpen(false);
      setDialogLoading(false);
      showNotification({ message: 'Connection canceled', options: { variant: 'info' } });
    } catch (e) {
      setDialogLoading(false);
      handleServerError(e);
    }
  }, [token, jobId, userIdForConnectAction]);

  const connectCandidate = useCallback(
    async (message: string) => {
      if (!userIdForConnectAction || !jobId) {
        return;
      }
      setDialogLoading(true);
      try {
        await applyWorkerRequest(token, companyId, userIdForConnectAction, jobId, message);
        await updateInfo();
        setConnectDialogOpen(false);
        setDialogLoading(false);
        showNotification({
          message: 'Your request to connect has been sent!',
          options: { variant: 'success' },
        });
      } catch (e) {
        setDialogLoading(false);
        setConnectDialogOpen(false);
        handleServerError(e);
      }
    },
    [token, jobId, userIdForConnectAction]
  );

  const getCandidate = useCallback(
    async (userId: string, jobId: string) => {
      try {
        const res = await getCandidateRequest(token, companyId, userId, jobId);
        setSelectedCandidate(res.data);
      } catch (e) {
        handleServerError(e);
      }
    },
    [token, jobId]
  );

  const closeJob = useCallback(
    async (uuid: string) => {
      const companyId = currentJob?.company?.uuid;
      if (!companyId) {
        return;
      }
      try {
        await updateJobRequest(token, uuid, companyId, { status: 'old' });
        showNotification({
          message: 'Your job has been closed. You’ll find it in ‘Old Jobs’',
          options: { variant: 'info' },
        });
        history.push('/hiring/live');
      } catch (e) {
        handleServerError(e);
      }
    },
    [token]
  );

  const goToCandidate = (id: number | string, name: string) => {
    history.push(
      `/hiring/live/${currentJob?.name}_${currentJob?.uuid}/candidates/${category}/${name}_${id}`
    );
  };

  const goBack = () => {
    history.push(`/hiring/live/${currentJob?.name}_${currentJob?.uuid}/candidates/${category}`);
  };

  const goToMatched = () => {
    history.push(`/hiring/live/${currentJob?.name}_${currentJob?.uuid}/candidates/matched`);
  };

  const openConnectDialog = (event: MouseEvent<any>, userId: string) => {
    event.stopPropagation();
    setUserIdForConnectAction(userId);
    setConnectDialogOpen(true);
  };

  const openCancelConnectionDialog = (event: MouseEvent<any>, userId: string) => {
    event.stopPropagation();
    setUserIdForConnectAction(userId);
    setCancelConnectionDialogOpen(true);
  };

  const openDeclineConnectionDialog = (event: MouseEvent<any>, userId: string) => {
    event.stopPropagation();
    setUserIdForConnectAction(userId);
    setDeclineApplicationDialogOpen(true);
  };

  const openAcceptDialog = (event: MouseEvent<any>, userId: string) => {
    event.stopPropagation();
    setUserIdForConnectAction(userId);
    setAcceptDialogOpen(true);
  };

  const openCancelDeclinationDialog = (event: MouseEvent<any>, userId: string) => {
    event.stopPropagation();
    setUserIdForConnectAction(userId);
    setCancelDeclinationDialogOpen(true);
  };

  const handleDuplicateJob = () => {
    setEditMode(false);
    setJobDialogOpen(true);
  };

  const handleEditJob = () => {
    setEditMode(true);
    setJobDialogOpen(true);
  };

  const handleJobDialogClose = () => {
    setJobDialogOpen(false);
    if (currentJob) {
      getJob(currentJob?.uuid);
    }
  };

  useEffect(() => {
    const selectedMarker: MapMarker | undefined = selectedCandidate
      ? {
          lat: selectedCandidate.address?.lat,
          lon: selectedCandidate.address?.lon,
          id: selectedCandidate.user_id,
          name: `${selectedCandidate.first_name} ${selectedCandidate.last_name}`,
          picture: selectedCandidate.photo?.url || defaultMarkerPicture,
        }
      : undefined;

    let markers = candidates.map(candidate => ({
      lat: candidate.address?.lat,
      lon: candidate.address?.lon,
      name: `${candidate.first_name} ${candidate.last_name}`,
      id: candidate.user_id,
      picture: candidate.photo?.url || defaultMarkerPicture,
    }));

    if (selectedMarker) {
      markers = markers.filter(marker => marker.id !== selectedMarker.id);
    }

    setMarkers(markers);
    setSelectedMarker(selectedMarker);
  }, [selectedCandidate, candidates]);

  useEffect(() => {
    const jobUrlItems = getUrlItems(job);
    const jobId = jobUrlItems?.id;
    if (jobId) {
      setJobId(jobId);
      getJob(jobId);
      getCandidates(jobId);
    }
  }, [job]);

  useEffect(() => {
    if (!mapMode && candidates.length > LIST_STEP) {
      setCandidates(candidates.slice(0, LIST_STEP));
    }
  }, [candidates, mapMode]);

  useEffect(() => {
    if (jobId) {
      getCandidates(jobId);
    }
  }, [page, mapMode]);

  useEffect(() => {
    if (page === 0 && jobId) {
      getCandidates(jobId);
    } else {
      setPage(0);
    }
  }, [sorting, category, filters]);

  useEffect(() => {
    const candidateUrlItems = getUrlItems(candidate);
    const userId = candidateUrlItems?.id;
    setCandidateDetailsOpen(!!userId);
    if (userId && jobId) {
      getCandidate(userId, jobId);
      updateTabs(history.location.pathname);
    } else {
      setSelectedCandidate(undefined);
    }
  }, [candidate, jobId]);

  return {
    isMobile,
    mapMode,
    count,
    currentJob,
    tabs,
    jobPreviewOpen,
    candidateDetailsOpen,
    connectDialogOpen,
    cancelConnectionDialogOpen,
    declineApplicationDialogOpen,
    cancelDeclinationDialogOpen,
    successConnectionDialogOpen,
    acceptDialogOpen,
    contextProps,
    selectedMarker,
    markers,
    candidates,
    loading,
    dialogLoading,
    category,
    selectedCandidate,
    connectedCandidate,
    jobDialogOpen,
    paginationOptions: {
      page,
      pageSize: LIST_STEP,
      pageCount,
      rowCount: count,
      setPage,
    },
    goToMatched,
    handleDuplicateJob,
    handleEditJob,
    handleJobDialogClose,
    openConnectDialog,
    openCancelConnectionDialog,
    openDeclineConnectionDialog,
    openAcceptDialog,
    openCancelDeclinationDialog,
    setJobPreviewOpen,
    closeJob,
    sort,
    handleFilterChange,
    setMapMode,
    getJobPosition,
    goToCandidate,
    goBack,
    setConnectDialogOpen,
    connectCandidate,
    setCancelConnectionDialogOpen,
    cancelConnection,
    setAcceptDialogOpen,
    acceptApplication,
    declineApplication,
    setDeclineApplicationDialogOpen,
    setCancelDeclinationDialogOpen,
    cancelDeclination,
    setSuccessConnectionDialogOpen,
    setConnectedCandidate,
  };
};
