import React, { useCallback, useContext, useEffect, useState } from 'react';

import { Box, useTheme } from '@material-ui/core';
import isEqual from 'lodash/isEqual';
import { GridColDef, GridSortModel } from '@mui/x-data-grid';

import { FilterSection, PayNotificationStatuses, PayrollPayload, Payrun } from '@vyce/core/src/types';
import {
  downloadPayrunBACSRequest,
  downloadPayrunsBACSRequest,
  getPayrunsRequest,
} from '@vyce/core/src/api/pay';
import { currencyFormatter, isNil } from '@vyce/core/src/utils';
import { formatTableDate } from '@vyce/core/src/utils/dates';
import { formatSortModel } from '@vyce/core/src/utils/sorting';
import { GRID_PAGE_SIZE, TABLE_OFFSET_DELAY } from '@vyce/core/src/constants';
import { AppDataGridWithSavedPage, FilterSystem, IsVerified, AppLink } from '@vyce/core/src/components';
import { DownloadBACS, StatusComponents } from '@vyce/core/src/views/payroll/components';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';
import { useDebounceValue } from '@vyce/core/src/hooks';

import { useTypedSelector } from '../../hooks';

export const Payruns: React.FC = () => {
  const theme = useTheme();
  const [payruns, setPayruns] = useState<Payrun[]>([]);
  const [offset, setOffset] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [filters, setFilters] = useState<any>();
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'payment_date', sort: 'desc' }]);
  const [loading, setLoading] = useState<boolean>(false);
  const { handleServerError } = useContext(NotificationContext);
  const { selectedCompany, selectedCompanyAppData } = useTypedSelector(state => state.helper);
  const [selectionModel, setSelectionModel] = useState<string[]>([]);
  const selectedCompanyId = selectedCompany?.uuid || '';

  const dOffset = useDebounceValue(offset, TABLE_OFFSET_DELAY);

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Pay Schedule',
      flex: 10,
      editable: false,
      renderCell: params => (
        <Box display="flex" width="100%">
          <AppLink
            cy-test-id={`payrun-${params.row?.name}`}
            to={`payruns/${params.row?.name}_${params.row.pay_run_id}_${selectedCompany?.uuid}`}>
            {params.row?.name}
          </AppLink>
        </Box>
      ),
      minWidth: 150,
    },
    {
      field: 'week',
      headerName: 'Week',
      flex: 10,
      editable: false,
      minWidth: 150,
    },
    {
      field: 'payment_date',
      valueGetter: params => formatTableDate(params.row?.payment_date),
      headerName: 'Pay Date',
      flex: 10,
      editable: false,
      minWidth: 150,
    },
    {
      field: 'workers',
      headerName: 'Workers',
      flex: 10,
      editable: false,
      minWidth: 150,
    },
    {
      field: 'total_gross',
      headerName: 'Gross',
      flex: 10,
      valueFormatter: params => currencyFormatter.format(params.row.total_gross),
      editable: false,
      minWidth: 150,
    },
    {
      field: 'total_net',
      headerName: 'Net',
      valueFormatter: params => currencyFormatter.format(params.row.total_net),
      flex: 10,
      editable: false,
      minWidth: 150,
    },
    {
      field: 'pay_status',
      headerName: 'Status',
      flex: 10,
      renderCell: params => (
        <StatusComponents status={params.row.pay_status} isGreen={params.row.pay_status === 'paid'} />
      ),
      editable: false,
      minWidth: 150,
    },
    {
      field: 'sms_status',
      headerName: 'SMS Sent',
      flex: 10,
      editable: false,
      renderCell: ({ row }) => (
        <IsVerified
          verified={row.sms_status !== PayNotificationStatuses.NOT_SENT}
          checkIconColor={
            row.sms_status === PayNotificationStatuses.ALL_SENT
              ? theme.palette.success.main
              : theme.palette.warning.main
          }
        />
      ),
      minWidth: 150,
    },
    {
      field: 'email_status',
      headerName: 'Email Sent',
      flex: 10,
      editable: false,
      renderCell: ({ row }) => (
        <IsVerified
          verified={row.email_status !== PayNotificationStatuses.NOT_SENT}
          checkIconColor={
            row.email_status === PayNotificationStatuses.ALL_SENT
              ? theme.palette.success.main
              : theme.palette.warning.main
          }
        />
      ),
      minWidth: 150,
    },
  ];

  const filtersSections: FilterSection[] = [
    {
      title: 'Pay Schedule',
      expanded: true,
      filters: [
        {
          type: 'select',
          multiple: false,
          label: 'Pay Schedule',
          field: 'payScheduleName',
          values: selectedCompanyAppData?.paySchedules?.map(item => item.name),
          defaultValue: '',
        },
        {
          type: 'select',
          multiple: false,
          label: 'Status',
          field: 'pay_run_status',
          values: ['', 'draft', 'submitted'],
          defaultValue: '',
        },
        {
          type: 'select',
          multiple: false,
          label: 'Pay Status',
          field: 'pay_status',
          values: ['', 'paid', 'unpaid', 'partly-paid'],
          defaultValue: '',
        },
      ],
    },
  ];

  const getPayruns = useCallback(async () => {
    if (!selectedCompanyId || isNil(dOffset)) {
      return;
    }
    const data: PayrollPayload = {
      limit: GRID_PAGE_SIZE,
      offset: dOffset as number,
      order_by: formatSortModel<Payrun>(sortModel),
      pay_run_status: filters?.pay_run_status || undefined,
      pay_status: filters?.pay_status || undefined,
      pay_schedule_name: filters?.payScheduleName || null,
    };
    try {
      setLoading(true);
      const res = await getPayrunsRequest(selectedCompanyId, data);
      setTotal(res.data.count);
      setPayruns(res.data.items);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setPayruns([]);
      handleServerError(e);
    }
  }, [dOffset, filters, sortModel, selectedCompanyId]);

  const handleFilterChange = (newFilters: any) => {
    setFilters(newFilters);
  };

  const handleSortModelChange = (newModel: GridSortModel) => {
    if (isEqual(newModel, sortModel)) {
      return;
    }
    setSortModel(newModel);
  };

  const handlePageChange = (newPage: number) => {
    setOffset(newPage * GRID_PAGE_SIZE);
  };

  useEffect(() => {
    getPayruns();
  }, [getPayruns]);

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
        <Box></Box>

        <Box display="flex" justifyContent="space-between">
          {selectionModel?.length && selectedCompanyId ? (
            <Box marginRight={2}>
              <DownloadBACS
                downloadPayrunBACSRequest={downloadPayrunBACSRequest}
                downloadPayrunsBACSRequest={downloadPayrunsBACSRequest}
                mode="multiple"
                payrunIds={selectionModel}
                companyId={selectedCompanyId}
              />
            </Box>
          ) : (
            <></>
          )}
          <FilterSystem filtersSections={filtersSections} onFiltersChange={handleFilterChange} />
        </Box>
      </Box>

      <AppDataGridWithSavedPage
        noPaper
        rows={payruns}
        getRowId={row => row.pay_run_id}
        loading={loading}
        columns={columns}
        rowCount={total}
        height="calc(100vh - 240px)"
        paginationMode="server"
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        checkboxSelection
        onSelectionModelChange={newSelectionModel => setSelectionModel(newSelectionModel as string[])}
        onPageChange={handlePageChange}
        pageSize={GRID_PAGE_SIZE}
        rowsPerPageOptions={[GRID_PAGE_SIZE]}
        disableSelectionOnClick
        unit="pay schedules"
        withProfileButton
        setOffset={setOffset}
      />
    </>
  );
};
