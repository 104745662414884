import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { OutlinedInputProps } from '@material-ui/core/OutlinedInput';
import { getBackground } from '../../theme/styles';

export const appTextFieldRootStyle = (theme: Theme) => {
  const background = getBackground(theme.palette.type === 'dark');
  return {
    border: `1px solid ${background.backgroundInput}`,
    overflow: 'hidden',
    maxHeight: '56px',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: background.backgroundInput,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:hover, &$focused': {
      backgroundColor: theme.palette.background.paper,
      borderColor: theme.palette.primary.main,
    },
  };
};

export const appInputStyles = {
  padding: '27px 24px 10px',
};

export const useAppTextFieldStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: appTextFieldRootStyle(theme),
    input: appInputStyles,
    focused: {},
  })
);

export const AppTextField = React.forwardRef(({ label, required, ...restProps }: TextFieldProps, ref) => {
  const classes = useAppTextFieldStyles();
  const enrichLabel = required ? `${label}*` : label;

  return (
    <TextField
      innerRef={ref}
      variant="filled"
      label={enrichLabel}
      {...restProps}
      InputProps={
        {
          classes,
          disableUnderline: true,
          ...restProps.InputProps,
        } as Partial<OutlinedInputProps>
      }
    />
  );
});
