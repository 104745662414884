import React from 'react';

import { Box, Typography, Grid, Button } from '@material-ui/core';
import clsx from 'clsx';

import { DetailItem } from '@vyce/core/src/types';
import { ReactComponent as VerifiedLabel } from '@vyce/core/src/assets/svg/verified-label.svg';
import { getFormattedDate } from '@vyce/core/src/utils';

import useStyles from '../../styles';
import { Statuses } from '../../types';
import { BoxWrapper } from './BoxWrapper';

interface RightToWorkProps {
  rtw: DetailItem & { expiration_date?: string | null; value: keyof typeof Statuses };
  id: DetailItem;
  share_code: DetailItem;
  showViewDocumentButton: boolean;
  onOpenIdInfoDialog: () => void;
}

const expStatuses = ['Expiring', 'Expired'];

const RtwDataRowItem = ({
  title,
  value,
  verified,
  expiration_date,
}: DetailItem & { expiration_date?: string | null; value: keyof typeof Statuses }) => {
  const classes = useStyles();

  if (!value) return null;
  return (
    <Box className={classes.recordWrapper} marginBottom={2}>
      <Grid item xs={12} sm={6} lg={5}>
        <Box display="flex" alignItems="center" height="100%">
          <Typography className={classes.bold}>{title}:</Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} lg={7}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" flexDirection="column">
            <Typography
              color={value === 'indefinite' || value === 'limited' ? 'textSecondary' : 'error'}
              className={clsx(classes.bigTextWrap, { [classes.warningText]: value === 'limited' })}>
              {Statuses[value]}
            </Typography>
            {expiration_date && (
              <Typography color="textSecondary" className={classes.bigTextWrap}>
                Exp. {getFormattedDate(expiration_date)}
              </Typography>
            )}
          </Box>

          {verified && <VerifiedLabel />}
        </Box>
      </Grid>
    </Box>
  );
};

const DataRowItem = ({
  title,
  value,
  verified,
  expiration_date,
}: DetailItem & { expiration_date?: string }) => {
  const classes = useStyles();

  if (!value) return null;
  return (
    <Box className={classes.recordWrapper} marginBottom={2}>
      <Grid item xs={12} sm={6} lg={5}>
        <Box display="flex" alignItems="center" height="100%">
          <Typography className={classes.bold}>{title}:</Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} lg={7}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" flexDirection="column">
            <Typography color={verified ? 'textSecondary' : 'error'} className={classes.bigTextWrap}>
              {value === 'Vyce verified' && verified ? 'Verified' : value}
            </Typography>
            {expiration_date && (
              <Typography color="textSecondary" className={classes.bigTextWrap}>
                {expStatuses.includes(value) ? '' : 'Exp.'} {getFormattedDate(expiration_date)}
              </Typography>
            )}
          </Box>

          {verified && <VerifiedLabel />}
        </Box>
      </Grid>
    </Box>
  );
};

export const RightToWork = ({
  rtw,
  id,
  share_code,
  showViewDocumentButton,
  onOpenIdInfoDialog,
}: RightToWorkProps) => {
  const classes = useStyles();

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Typography color="textSecondary" variant="h6">
        ID & Right to Work
      </Typography>
      <BoxWrapper>
        <Box flex="1 0 auto" width="100%">
          <RtwDataRowItem {...rtw} />
          <DataRowItem {...share_code} />
          <DataRowItem {...id} />
        </Box>

        {showViewDocumentButton && (
          <Box width="100%">
            <Button
              color="primary"
              variant="outlined"
              size="small"
              className={classes.outlinedIDButton}
              onClick={onOpenIdInfoDialog}>
              View Documents
            </Button>
          </Box>
        )}
      </BoxWrapper>
    </Box>
  );
};
