import { ChangeEvent, useCallback, useContext, useEffect, useState } from 'react';

import { GridSortModel } from '@mui/x-data-grid';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';

import { InvitedUser } from '@vyce/core/src/types';
import { GRID_PAGE_SIZE } from '@vyce/core/src/constants';
import { formatSortModel } from '@vyce/core/src/utils/sorting';

import { InviteProps } from '../types';
import { NotificationContext } from '../../../contexts/notificationContext';

export const useInvitesData = ({
  selectedCompanyId,
  isLegend = false,
  handleInviteButtonClick,
  deleteInviteRequest,
  getInvitesRequest,
  resendInviteRequest,
}: InviteProps) => {
  const { handleServerError, showNotification } = useContext(NotificationContext);
  const [invitedUsers, setInvitedUsers] = useState<InvitedUser[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'created_at', sort: 'desc' }]);
  const [offset, setOffset] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [substring, setSubstring] = useState<string>('');

  const resendInvite = async (inviteId: string, companyId: string) => {
    if (!companyId) {
      return;
    }
    try {
      await resendInviteRequest(companyId, inviteId);
      await getInvites();
      showNotification({ message: 'The Invitation has been resent', options: { variant: 'success' } });
    } catch (e) {
      handleServerError(e);
    }
  };

  const deleteInvite = async (inviteId: string, companyId: string) => {
    if (!companyId) {
      return;
    }
    try {
      const res = await deleteInviteRequest(companyId, inviteId);
      await getInvites();
      showNotification({ message: res.data.message, options: { variant: 'success' } });
    } catch (e) {
      handleServerError(e);
    }
  };

  const handleSetSubstring = debounce((e: ChangeEvent<HTMLInputElement>) => {
    const substr = e?.target?.value;
    setSubstring(substr);
  }, 500);

  const getInvites = useCallback(async () => {
    if (!isLegend && !selectedCompanyId) {
      return;
    }

    setLoading(true);
    try {
      const defaultOptions = {
        limit: GRID_PAGE_SIZE,
        offset,
        substring,
        order_by: formatSortModel<InvitedUser>(sortModel),
      };
      const options = isLegend ? defaultOptions : { ...defaultOptions, companyId: selectedCompanyId };
      const res = await getInvitesRequest(options);
      setInvitedUsers(res.data.items);
      setTotal(res.data.count);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      handleServerError(e);
    }
  }, [selectedCompanyId, substring, offset, sortModel]);

  const handleSortModelChange = (newModel: GridSortModel) => {
    if (isEqual(newModel, sortModel)) {
      return;
    }
    setSortModel(newModel);
  };

  const handlePageChange = (newPage: number) => {
    const newOffset = newPage * GRID_PAGE_SIZE;
    setOffset(newOffset);
  };

  useEffect(() => {
    getInvites();
  }, [getInvites]);

  return {
    loading,
    invitedUsers,
    sortModel,
    total,
    handleInviteButtonClick,
    handleSortModelChange,
    handlePageChange,
    resendInvite,
    deleteInvite,
    handleSetSubstring,
  };
};
