import React, { useMemo } from 'react';

import {
  Box,
  DialogContentText,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  useTheme,
} from '@material-ui/core';
import dayjs from 'dayjs';
import { BiIdCard } from 'react-icons/bi';
import clsx from 'clsx';

import { ImageUploader } from '@vyce/core/src/components';
import { getUKFormattedDate } from '@vyce/core/src/utils/dates';

import useStyles from '../styles';
import { QualificationType, CertificateOptions, TestOption, CourseOption } from '../types';
import { dateFields, previewControlSet } from '../config';
import { Image } from '../../../types';

const commonSize = 120;

const certificateOptions: CertificateOptions[] = [
  { fieldName: 'title', title: 'Qualification Name' },
  { fieldName: 'issuer', title: 'Issuing Body' },
  { fieldName: 'verified', title: 'Status' },
  { fieldName: 'expiry_date', title: 'Expiry' },
];

const testOptions: TestOption[] = [
  { fieldName: 'title', title: 'Qualification Name' },
  { fieldName: 'issuer', title: 'Issuing Body' },
  { fieldName: 'verified', title: 'Status' },
];

const courseOptions: CourseOption[] = [
  { fieldName: 'title', title: 'Qualification Name' },
  { fieldName: 'issuer', title: 'Issuing Body' },
  { fieldName: 'verified', title: 'Status' },
  { fieldName: 'expiry_date', title: 'Expiry' },
];

const CommonQualificationContentItem = ({
  fieldName,
  title,
  value,
}: {
  fieldName: string;
  title: string;
  value: string;
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isVerifiedField = fieldName === 'verified';
  const conditionValue = useMemo(() => {
    if (fieldName === 'verified') {
      return value ? 'Vyce verified' : 'Unverified';
    }
    return value;
  }, [fieldName, value]);

  return (
    <Box display="flex" flexDirection="column">
      <Typography
        className={classes.smallText}
        style={{ color: theme.palette.type === 'dark' ? '#A0A3BD' : '#6E7191' }}>
        {title}
      </Typography>
      <Typography
        className={clsx(classes.smallText, {
          [classes.verified]: isVerifiedField && !!value,
          [classes.unverified]: isVerifiedField && !value,
        })}>
        {dateFields.includes(fieldName) ? getUKFormattedDate(value) : conditionValue}
      </Typography>
      {fieldName === 'expiry_date' && !value && (
        <Typography className={classes.smallText}>No Expiry</Typography>
      )}
    </Box>
  );
};

export const CommonQualificationContent = ({ qualification }: { qualification: QualificationType }) => {
  const previewDocument: Image | null = useMemo(() => {
    if (qualification.type === 'certificate' && qualification?.document?.url) {
      return { url: qualification?.document?.url, name: qualification?.document?.url } as Image;
    }
    return null;
  }, [qualification]);

  return (
    <DialogContentText>
      <Box display="flex" gridGap={16}>
        <Box flex="1 0 auto">
          {qualification && 'endorsements' in qualification && !!qualification?.endorsements?.length && (
            <>
              <Typography>Endorsements:</Typography>

              <List>
                {qualification.endorsements.map((item: any, index: number) => (
                  <ListItem key={index}>
                    <ListItemAvatar>
                      <BiIdCard size="32px" />
                    </ListItemAvatar>
                    <ListItemText
                      primary={item.title}
                      secondary={`Expiry Date: ${dayjs(item.expiry_date).format('DD MMMM YYYY')}`}
                    />
                  </ListItem>
                ))}
              </List>
            </>
          )}
          <Box display="flex" flexDirection="column" gridGap={16} marginTop={2}>
            {qualification.type === 'certificate' &&
              certificateOptions.map(({ fieldName, title }) => (
                <CommonQualificationContentItem
                  key={fieldName}
                  fieldName={fieldName}
                  title={title}
                  value={(qualification[fieldName] as string) ?? ''}
                />
              ))}
            {qualification.type === 'test' &&
              testOptions.map(({ fieldName, title }) => (
                <CommonQualificationContentItem
                  key={fieldName}
                  fieldName={fieldName}
                  title={title}
                  value={(qualification[fieldName] as string) ?? ''}
                />
              ))}

            {qualification.type === 'course' &&
              courseOptions.map(({ fieldName, title }) => (
                <CommonQualificationContentItem
                  key={fieldName}
                  fieldName={fieldName}
                  title={title}
                  value={(qualification[fieldName] as string) ?? ''}
                />
              ))}
          </Box>
        </Box>
        {qualification.type === 'certificate' && qualification?.document?.url && (
          <ImageUploader
            image={previewDocument}
            extraText="new files"
            extraSmallSize={true}
            isDocument={true}
            type="both"
            outsideControl
            customControlSet={previewControlSet}
            width={commonSize}
            height={commonSize}
          />
        )}
      </Box>
    </DialogContentText>
  );
};
