import React, { useContext, useState } from 'react';

import { Box, Button, Paper, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { AxiosResponse } from 'axios';

import { useBooleanState } from '@vyce/core/src/hooks';
import { RTWProps, RTWVerification } from '@vyce/core/src/types';
import { AppA, DocumentsDialog } from '@vyce/core/src/components';

import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';

import useStyles from '../styles';
import { UploadRtwPdfDialog } from './UploadRtwPdfDialog';
import { ShareCodeDialog } from './ShareCodeDialog';
import { StatusComponent } from './StatusComponent';
import { RtwManualVerificationDialog } from './RtwManualVerificaionDialog';

interface Props {
  shareCode?: string;
  dateOfBirth?: string;
  rtw?: RTWProps;
  rtwVerification?: RTWVerification;
  userId?: string;
  isLegend?: boolean;
  setRtw: Function;
  triggerCheckerRequest: Function;
  getUser: () => Promise<void>;
  updateRTWRequest?: (userId: string, rtwVerification: Partial<RTWVerification>) => Promise<AxiosResponse>;
}

export const RtwVerificationCard: React.FC<Props> = ({
  shareCode,
  dateOfBirth,
  rtw,
  userId,
  rtwVerification,
  isLegend,
  setRtw,
  triggerCheckerRequest,
  updateRTWRequest,
  getUser,
}) => {
  const classes = useStyles();
  const { handleServerError } = useContext(NotificationContext);
  const [isUploadDialogOpen, openUploadDialog, closeUploadDialog] = useBooleanState(false);
  const [isManualDialogOpen, openManualDialog, closeManualDialog] = useBooleanState(false);
  const [isShareCodeDialogOpen, openShareCodeDialog, closeShareCodeDialog] = useBooleanState(false);
  const [isInfoDialogOpen, openInfoDialog, closeInfoDialog] = useBooleanState(false);

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const updateRtw = async (data: Partial<RTWVerification>) => {
    if (!updateRTWRequest || !userId) {
      return;
    }

    try {
      setButtonLoading(true);
      await updateRTWRequest(userId, data);
      getUser();
      setButtonLoading(false);
      closeManualDialog();
    } catch (e) {
      setButtonLoading(false);
      handleServerError(e);
    }
  };

  const handleInfoDialogOpen = () => {
    if (rtw?.uuid) {
      openInfoDialog();
    }
  };

  const handleVerifyButtonClick = (e: any) => {
    e.stopPropagation();
    openShareCodeDialog();
  };

  const handleVerifyManuallyButtonClick = (e: any) => {
    e.stopPropagation();
    openManualDialog();
  };

  return (
    <>
      <Paper
        onClick={handleInfoDialogOpen}
        variant="outlined"
        className={clsx(classes.paper, { [classes.clickable]: rtw?.uuid })}>
        <Box width="100%" display="flex" alignItems="center" justifyContent="space-between">
          <Typography className={classes.bold} color="primary" variant="h6">
            Right to Work
          </Typography>
          {rtwVerification && (
            <StatusComponent
              verified={rtwVerification?.verified}
              expiration_date={rtwVerification?.expiration_date}
            />
          )}
        </Box>

        <Box width="100%" minHeight={80} marginTop={1} marginBottom={2} display="flex" alignItems="center">
          {!rtwVerification?.verified && (
            <Box display="flex" alignItems="center" justifyContent="center">
              <Typography variant="subtitle2">
                Please add an up to date Share Code to verify your right to work. Don’t have a Share Code?
                Get one <AppA href="https://www.gov.uk/prove-right-to-work" content="here" />.
              </Typography>
            </Box>
          )}

          {rtwVerification?.verified && (
            <Box>
              <Typography variant="subtitle2">{rtw?.full_name}</Typography>

              <Box display="flex" marginTop={1}>
                <Typography variant="subtitle2" color="textSecondary" style={{ marginRight: '4px' }}>
                  Status:
                </Typography>
                <Typography variant="subtitle2">{rtwVerification?.status}</Typography>
              </Box>

              <Box display="flex" marginTop={1}>
                <Typography variant="subtitle2" color="textSecondary" style={{ marginRight: '4px' }}>
                  Document Expiring:
                </Typography>
                <Typography variant="subtitle2">{rtwVerification?.expiration_date || 'N/A'}</Typography>
              </Box>
            </Box>
          )}
        </Box>

        <Box display="flex" gridGap={16} width="100%">
          <Button
            variant="contained"
            disabled={rtwVerification?.status === 'indefinite'}
            color={rtw && !rtw?.expired ? 'secondary' : 'primary'}
            onClick={handleVerifyButtonClick}
            fullWidth>
            {rtw && !rtw?.expired ? 'Update' : 'Verify'}
          </Button>

          {isLegend && (
            <>
              <Button
                variant="contained"
                onClick={handleVerifyManuallyButtonClick}
                fullWidth
                color="primary">
                {rtw && !rtw?.expired ? 'Update Manually' : 'Verify Manually'}
              </Button>

              <Button
                disabled={rtwVerification?.status === 'indefinite'}
                variant="contained"
                onClick={openUploadDialog}
                fullWidth
                color="primary">
                Upload PDF
              </Button>
            </>
          )}
        </Box>
      </Paper>

      <DocumentsDialog
        open={isInfoDialogOpen}
        onClose={closeInfoDialog}
        rtw={rtw}
        rtwVerification={rtwVerification}
        mode="RTW"
      />

      <ShareCodeDialog
        userId={userId}
        shareCode={shareCode}
        dateOfBirth={dateOfBirth}
        open={isShareCodeDialogOpen}
        triggerCheckerRequest={triggerCheckerRequest}
        handleClose={closeShareCodeDialog}
      />

      <UploadRtwPdfDialog
        setRtw={setRtw}
        getUser={getUser}
        open={isUploadDialogOpen}
        handleClose={closeUploadDialog}
        userId={userId}
      />

      <RtwManualVerificationDialog
        loading={buttonLoading}
        updateRtw={updateRtw}
        rtwVerification={rtwVerification}
        open={isManualDialogOpen}
        handleClose={closeManualDialog}
      />
    </>
  );
};
