import { createContext, useContext, useEffect, useState } from 'react';

import noop from 'lodash/noop';

import { AI_TOOL_ACTIVATED } from '../constants';
import { AIChatMessage, AIChatRole, User } from '../../../types';
import { useBooleanState } from '../../../hooks';
import { askAIRequest, speechToTextRequest } from '../../../api/ai';
import { saveToLS } from '../../../utils/local-storage';
import { getPersonalizedMessage } from '../utils';
import { NotificationContext } from '../../../contexts/notificationContext';

interface Data {
  messages: AIChatMessage[];
  loading: boolean;
  isAIActivated: boolean;
  speechToText: (audioFile: string) => void;
  activateAIChat: () => void;
  setIsAIActivated: (status: boolean) => void;
  addUserMessage: (message: string) => void;
  ask: (messages: AIChatMessage[]) => void;
}

const initialData: Data = {
  messages: [],
  loading: false,
  isAIActivated: false,
  speechToText: noop,
  activateAIChat: noop,
  addUserMessage: noop,
  setIsAIActivated: noop,
  ask: noop,
};

export const AIChatContext = createContext(initialData);

interface Props {
  children: any;
  user?: User;
  model?: string;
}

function AIChatContextProvider({ children, user, model }: Props) {
  const { handleServerError } = useContext(NotificationContext);
  const [messages, setMessages] = useState<AIChatMessage[]>([]);
  const [loading, setLoadingTrue, setLoadingFalse] = useBooleanState(false);
  const [isAIActivated, setIsAIActivated] = useState<boolean>(false);
  const [personalMessage, setPersonalMessage] = useState<string>('');

  const ask = async (messages: AIChatMessage[]) => {
    try {
      setLoadingTrue();
      const res = await askAIRequest([{ role: 'assistant', content: personalMessage }, ...messages], model);
      const systemMessage = res.data.body?.message;
      if (systemMessage) {
        setMessages(value => [...value, systemMessage]);
      }
      setLoadingFalse();
    } catch (e) {
      setLoadingFalse();
      handleServerError(e);
    }
  };

  const addUserMessage = async (message: string) => {
    setMessages(value => [...value, { role: 'user' as AIChatRole, content: message }]);
  };

  const speechToText = async (audioFile: string) => {
    try {
      setLoadingTrue();
      const res = await speechToTextRequest(audioFile);
      const message = res.data.body;
      if (message) {
        addUserMessage(message);
      }
    } catch (e) {
      setLoadingFalse();
      handleServerError(e);
    }
  };

  const activateAIChat = () => {
    setIsAIActivated(true);
    saveToLS(AI_TOOL_ACTIVATED, 'true');
  };

  useEffect(() => {
    const lastMessage = messages[messages.length - 1];
    if (lastMessage && lastMessage.role === 'user') {
      ask(messages);
    }
  }, [messages]);

  useEffect(() => {
    if (user) {
      const message = getPersonalizedMessage(user);
      setPersonalMessage(message);
    }
  }, [user]);

  const value = {
    loading,
    messages,
    speechToText,
    isAIActivated,
    activateAIChat,
    addUserMessage,
    setIsAIActivated,
    ask,
  };

  return <AIChatContext.Provider value={value}>{children}</AIChatContext.Provider>;
}

export default AIChatContextProvider;
