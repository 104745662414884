import { ChangeEvent, useCallback, useContext, useEffect, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import {
  Box,
  FilledInput,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  Typography,
} from '@material-ui/core';

import { ControlledSelect } from '@vyce/core/src/components/controlled-inputs/ControlledSelect';
import { AppCheckbox, useAppTextFieldStyles } from '@vyce/core/src/components/inputs';
import { SALARY_TYPES } from '@vyce/core/src/utils/job';
import { NEGOTIABLE } from '@vyce/core/src/modules/hiringModule/config';

import { useStyles } from '../../styles';
import { HiringContext } from '../../../../modules/hiringModule/context/hiringContext';

interface BudgetProps {
  salaryType: any;
  isPayOvertime: boolean;
  setIsPayOvertime: (arg0: boolean) => void;
}

const defaultRules = { required: 'Value is required' };

const getSalaryToRules = (salaryFrom?: number) => ({
  required: 'Value is required',
  validate: (value?: number) => {
    if (value === undefined || salaryFrom === undefined) {
      return true;
    }
    return Number(value) >= Number(salaryFrom) || `Can’t be lower than ${salaryFrom} £`;
  },
});

export const Budget = ({ salaryType, isPayOvertime, setIsPayOvertime }: BudgetProps) => {
  const classes = useStyles();
  const inputClasses = useAppTextFieldStyles();
  const { isEditMode } = useContext(HiringContext);
  const {
    control,
    getValues,
    trigger,
    watch,
    formState: { errors, dirtyFields },
  } = useFormContext();
  watch('salary_from', '');
  watch('overtime_from', '');

  const salaryFrom = getValues('salary_from');
  const overtimeFrom = getValues('overtime_from');

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setIsPayOvertime(e.target.checked);
    },
    [setIsPayOvertime]
  );

  const salaryToRules = useMemo(() => {
    return getSalaryToRules(salaryFrom);
  }, [salaryFrom]);

  const overtimeToRules = useMemo(() => {
    return getSalaryToRules(overtimeFrom);
  }, [overtimeFrom]);

  useEffect(() => {
    trigger('salary_to');
  }, [salaryFrom]);

  useEffect(() => {
    trigger('overtime_to');
  }, [overtimeFrom]);

  return (
    <Box>
      <Typography variant="subtitle1" color="primary">
        Your Budget
      </Typography>

      <ControlledSelect
        name="salary_type"
        label="Type"
        disabled={isEditMode}
        margin="normal"
        items={SALARY_TYPES}
      />

      <Grid container spacing={1}>
        {salaryType?.toLowerCase() !== NEGOTIABLE && (
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              rules={defaultRules}
              name="salary_from"
              render={({ field }) => (
                <FormControl fullWidth variant="filled" margin="normal">
                  <InputLabel variant="filled" error={!!errors.salary_from?.message} htmlFor="salary-from">
                    From (£)
                  </InputLabel>
                  <FilledInput
                    {...field}
                    disabled={isEditMode}
                    disableUnderline
                    classes={inputClasses}
                    error={!!errors.salary_from?.message}
                    type="number"
                    id="salary-from"
                  />
                  <FormHelperText error id="budget-helper-text">
                    {(errors.salary_from?.message as string) || ''}
                  </FormHelperText>
                </FormControl>
              )}
            />
          </Grid>
        )}

        {salaryType?.toLowerCase() !== NEGOTIABLE && (
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              rules={salaryToRules}
              name="salary_to"
              render={({ field }) => (
                <FormControl fullWidth variant="filled" margin="normal">
                  <InputLabel
                    variant="filled"
                    error={!!errors.salary_to?.message && dirtyFields.salary_to}
                    htmlFor="salary_to">
                    To (£)
                  </InputLabel>
                  <FilledInput
                    {...field}
                    disableUnderline
                    disabled={isEditMode}
                    classes={inputClasses}
                    error={!!errors.salary_to?.message && dirtyFields.salary_to}
                    type="number"
                    id="salary_to"
                  />
                  <FormHelperText error id="budget-helper-text">
                    {(dirtyFields.salary_to && errors.salary_to?.message) || ''}
                  </FormHelperText>
                </FormControl>
              )}
            />
          </Grid>
        )}

        <FormControlLabel
          className={classes.overPaymentCheckbox}
          control={
            <AppCheckbox
              onChange={handleChange}
              disabled={isEditMode}
              color="primary"
              checked={isPayOvertime}
            />
          }
          classes={{ label: classes.checkboxLabel }}
          label="Paying overtime hours?"
        />

        {isPayOvertime && (
          <>
            <ControlledSelect
              name="overtime_type"
              label="Type"
              disabled={isEditMode}
              margin="normal"
              items={SALARY_TYPES}
            />

            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                rules={defaultRules}
                name="overtime_from"
                render={({ field }) => (
                  <FormControl fullWidth variant="filled" margin="normal">
                    <InputLabel
                      variant="filled"
                      error={!!errors.overtime_from?.message}
                      htmlFor="overtime_from">
                      From (£)
                    </InputLabel>
                    <FilledInput
                      {...field}
                      disabled={isEditMode}
                      disableUnderline
                      classes={inputClasses}
                      error={!!errors.overtime_from?.message}
                      type="number"
                      id="overtime_from"
                    />
                    <FormHelperText error id="budget-helper-text">
                      {(errors.overtime_from?.message as string) || ''}
                    </FormHelperText>
                  </FormControl>
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                rules={overtimeToRules}
                name="overtime_to"
                render={({ field }) => (
                  <FormControl fullWidth variant="filled" margin="normal">
                    <InputLabel
                      variant="filled"
                      error={!!errors.overtime_to?.message && dirtyFields.overtime_to}
                      htmlFor="salary-to">
                      To (£)
                    </InputLabel>
                    <FilledInput
                      {...field}
                      disableUnderline
                      disabled={isEditMode}
                      classes={inputClasses}
                      error={!!errors.overtime_to?.message && dirtyFields.overtime_to}
                      type="number"
                      id="overtime_to"
                    />
                    <FormHelperText error id="budget-helper-text">
                      {(dirtyFields.overtime_to && errors.overtime_to?.message) || ''}
                    </FormHelperText>
                  </FormControl>
                )}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};
