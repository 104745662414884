import React, { useContext } from 'react';

import { Box, Button, Typography } from '@material-ui/core';
import { AiOutlinePlus } from 'react-icons/ai';
import clsx from 'clsx';

import { CustomizeDashboard, FilterSystem } from '@vyce/core/src/components';
import { WidgetItem } from '@vyce/core/src/types';
import { AppDashboard } from '@vyce/core/src/components/Dashboard/AppDashboard';
import { TimeDashboardModuleContext } from '@vyce/core/src/contexts';
import { useHorizontalScrollStyles } from '@vyce/core/src/styles';
import { LiveTimeLocationMap } from '@vyce/core/src/views/time/components';
import { ClockInWidget } from '@vyce/core/src/views/widgets/ClockInWidget';
import { WorkersOvertimeWidget } from '@vyce/core/src/views/widgets/WorkersOvertimeWidget';
import { ClockOutWidget } from '@vyce/core/src/views/widgets/ClockOutWidget';
import { TopPerformingWidget } from '@vyce/core/src/views/widgets/TopPerformingWidget';
import { OnSiteTradesWidget } from '@vyce/core/src/views/widgets/OnSiteTradesWidget';

import { useTimeDashboardData } from './hooks';

export const TimeDashboardModule = () => {
  const horizontalScrollClasses = useHorizontalScrollStyles();

  const { widgetComponent, onOpenUniversalInviteForm, inviteButtonComponent } =
    useContext(TimeDashboardModuleContext);

  const {
    isMobile,
    permissions,
    selectedCompanyId,
    filterSections,
    locations,
    timeDashboard,
    name,
    handleFilterChange,
    onWidgetStatusChange,
    onLayoutChange,
  } = useTimeDashboardData();

  const getWidget = (widget: WidgetItem) => {
    switch (widget.system_name) {
      case 'team_on_site':
        return <LiveTimeLocationMap selectedCompanyId={selectedCompanyId} />;
      case 'clocked_in_now':
        return (
          <ClockInWidget
            locations={locations}
            selectedCompanyId={selectedCompanyId}
            userPermissions={permissions}
          />
        );

      case 'clocked_out_today':
        return (
          <ClockOutWidget
            locations={locations}
            selectedCompanyId={selectedCompanyId}
            userPermissions={permissions}
          />
        );
      case 'top_performing_team_members_last_week':
        return <TopPerformingWidget selectedCompanyId={selectedCompanyId} userPermissions={permissions} />;

      case 'workers_worked_time_overview':
        return <WorkersOvertimeWidget selectedCompanyId={selectedCompanyId} />;
      case 'on_site_trades':
        return <OnSiteTradesWidget selectedCompanyId={selectedCompanyId} />;
      default:
        return React.createElement(widgetComponent, { widget, redirectUrl: '/time/timelogs' });
    }
  };

  return (
    <>
      <Box
        className={clsx(
          horizontalScrollClasses.blockWrapper,
          horizontalScrollClasses.blockWithHideScroll,
          horizontalScrollClasses.navBarBlock
        )}>
        {!isMobile && (
          <Box display="flex">
            <Typography
              variant="subtitle1"
              style={{ marginRight: 4, fontWeight: 600, whiteSpace: 'nowrap' }}
              color="secondary">
              Hey {name},
            </Typography>
            <Typography variant="subtitle1" style={{ whiteSpace: 'nowrap' }} color="textSecondary">
              here’s your Time dashboard...
            </Typography>
          </Box>
        )}
        <Box display="flex" gridGap={16}>
          {inviteButtonComponent ? (
            React.createElement(inviteButtonComponent)
          ) : (
            <Button
              onClick={onOpenUniversalInviteForm}
              size="small"
              data-tour="invite"
              startIcon={<AiOutlinePlus />}
              variant="contained"
              color="primary">
              Invite New Members
            </Button>
          )}

          <FilterSystem filtersSections={filterSections} onFiltersChange={handleFilterChange} />

          <CustomizeDashboard
            items={timeDashboard?.widgets || []}
            onWidgetStatusChange={onWidgetStatusChange}
          />
        </Box>
      </Box>

      <Box style={{ marginRight: '-24px', marginLeft: '-24px' }}>
        <AppDashboard
          layouts={timeDashboard?.layouts}
          onLayoutChange={onLayoutChange}
          widgets={timeDashboard?.widgets || []}
          getWidget={getWidget}
          onWidgetStatusChange={onWidgetStatusChange}
        />
      </Box>
    </>
  );
};
