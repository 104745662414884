import { useCallback, useContext, useEffect, useState } from 'react';

import isEqual from 'lodash/isEqual';
import { GridSortModel } from '@mui/x-data-grid';

import { Subscription, SubscriptionInvoice } from '@vyce/core/src/types';
import { getInvoicesRequest } from '@vyce/core/src/api/billing';
import { GRID_PAGE_SIZE } from '@vyce/core/src/constants';
import { useBooleanState } from '@vyce/core/src/hooks';
import { formatSortModel } from '@vyce/core/src/utils/sorting';

import { NotificationContext } from '../../../contexts/notificationContext';

interface Props {
  token: string;
  subscription?: Subscription;
  companyId?: string;
}

export const usePaymentsData = ({ token, subscription, companyId }: Props) => {
  const { handleServerError } = useContext(NotificationContext);
  const [invoices, setInvoices] = useState<SubscriptionInvoice[]>([]);
  const [loading, setLoadingTrue, setLoadingFalse] = useBooleanState();
  const [total, setTotal] = useState<number>(0);
  const [offset, setOffset] = useState<number>(0);
  const [status, setStatus] = useState<string>('');
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: 'next_payment_attempt', sort: 'desc' },
  ]);

  const getInvoices = useCallback(async () => {
    if (!companyId || !subscription?.active) {
      return;
    }
    try {
      setLoadingTrue();
      const { data } = await getInvoicesRequest({
        token,
        companyId,
        requestData: {
          offset,
          limit: GRID_PAGE_SIZE,
          order_by: formatSortModel<SubscriptionInvoice>(sortModel),
          status: status || undefined,
        },
      });
      setInvoices(data.items);
      setTotal(data.count);
      setLoadingFalse();
    } catch (e) {
      setLoadingFalse();
      handleServerError(e);
    }
  }, [companyId, offset, sortModel, status]);

  useEffect(() => {
    getInvoices();
  }, [companyId, offset, sortModel, status]);

  const handleSortModelChange = (newModel: GridSortModel) => {
    if (isEqual(newModel, sortModel)) {
      return;
    }
    setSortModel(newModel);
  };

  const handlePageChange = (newPage: number) => {
    const newOffset = newPage * GRID_PAGE_SIZE;
    setOffset(newOffset);
  };

  const handleViewInvoiceClick = (url: string) => {
    window.open(url, '_blank');
  };

  const handleFilterChange = (newFilters: any) => {
    setStatus(newFilters.status.toLowerCase());
  };

  return {
    invoices,
    loading,
    total,
    sortModel,
    handleSortModelChange,
    handlePageChange,
    handleViewInvoiceClick,
    handleFilterChange,
  };
};
