import React, { useState } from 'react';

import {
  Button,
  FilledInput,
  FormControl,
  InputAdornment,
  InputLabel,
  Typography,
  useTheme,
} from '@material-ui/core';
import { FiChevronRight } from 'react-icons/fi';
import { AxiosResponse } from 'axios';

import { useAppTextFieldStyles } from '../inputs';
import { User } from '../../types';
import { SetNewPasswordDialog } from './SetNewPasswordDialog';
import { ChangePasswordData } from '../../api/types';
import { VerifyYourIdentityDialog } from '../VerifyYourIdentityDialog';

export const UPPER_CASE_REGEX = /(?=.*?[A-Z])/;
export const LOWER_CASE_REGEX = /(?=.*?[a-z])/;
export const ONE_DIGIT_REGEX = /(?=.*?[0-9])/;
export const SPECIAL_CHARACTER_REGEX = /(?=.*?[#?!@$%^"()_+\\'<,>./={[}]|~`&*-])/;

interface Props {
  email: string;
  token: string;
  user?: User;
  isLegend?: boolean;
  changePasswordRequest: (
    token: string,
    data: ChangePasswordData,
    userId?: string
  ) => Promise<AxiosResponse>;
}

export const ChangePasswordField: React.FC<Props> = ({
  email,
  token,
  changePasswordRequest,
  user,
  isLegend,
}) => {
  const classes = useAppTextFieldStyles();
  const theme = useTheme();
  const [openCurrentPasswordDialog, setOpenCurrentPasswordDialog] = useState<boolean>(false);
  const [openNewPasswordDialog, setOpenNewPasswordDialog] = useState<boolean>(false);

  const handleChangePasswordClick = () => {
    // change user's password by legend without current password confirmation
    if (isLegend) {
      return setOpenNewPasswordDialog(true);
    }
    // change password by user with current password confirmation
    setOpenCurrentPasswordDialog(true);
  };

  return (
    <>
      <FormControl fullWidth variant="filled">
        <InputLabel htmlFor="password">Password</InputLabel>
        <FilledInput
          classes={classes}
          disableUnderline
          id="password"
          value="00000000"
          type="password"
          endAdornment={
            <InputAdornment position="end">
              <Button
                onClick={handleChangePasswordClick}
                endIcon={<FiChevronRight color={theme.palette.text.secondary} />}>
                <Typography variant="subtitle1" color="textSecondary">
                  Change Password
                </Typography>
              </Button>
            </InputAdornment>
          }
        />
      </FormControl>

      <VerifyYourIdentityDialog
        email={email}
        message="Enter your current password."
        open={openCurrentPasswordDialog}
        handleClose={() => setOpenCurrentPasswordDialog(false)}
        handleNext={() => setOpenNewPasswordDialog(true)}
      />

      <SetNewPasswordDialog
        token={token}
        user={user}
        isLegend={isLegend}
        changePasswordRequest={changePasswordRequest}
        setOpen={setOpenNewPasswordDialog}
        open={openNewPasswordDialog}
      />
    </>
  );
};
