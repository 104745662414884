import React, { useEffect, useState, useContext, useMemo, useCallback } from 'react';

import clsx from 'clsx';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import { useHistory } from 'react-router-dom';
import { GridColDef, GridSortModel } from '@mui/x-data-grid';
import { Box, MenuItem, MenuList } from '@material-ui/core';

import { formatTableDate } from '@vyce/core/src/utils/dates';
import { ListEmployeeRequestData, RemoveEmployeeRequest } from '@vyce/core/src/api/types';
import {
  Employee,
  FilterSection,
  Nationality,
  PayrollUser,
  PaySchedule,
  Tag,
  TagGroups,
} from '@vyce/core/src/types';
import { useHorizontalScrollStyles } from '@vyce/core/src/styles';
import { DeviceContext } from '@vyce/core/src/contexts';
import {
  BUSINESS_TYPES,
  DOCUMENT_STATUSES_ARR,
  GRID_PAGE_SIZE,
  RTW_VALUES,
  SUBCONTRACTOR_TYPES,
  TABLE_OFFSET_DELAY,
  TAXATION_STATUSES,
} from '@vyce/core/src/constants';
import {
  AppA,
  AppDataGridWithSavedPage,
  ConfirmDialog,
  FilterSystem,
  GridActions,
  GridCellWithAvatar,
  IDStatus,
  RTWStatus,
} from '@vyce/core/src/components';
import { getAvatar } from '@vyce/core/src/utils/getAvatar';
import { formatSortModel } from '@vyce/core/src/utils/sorting';
import { fetchNationalitiesRequest, fetchTagsRequest } from '@vyce/core/src/api/handbook';
import { getBooleanFilterValue, isNil } from '@vyce/core/src/utils';
import { AppSearchInput } from '@vyce/core/src/components/inputs';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';
import { useDebounceValue } from '@vyce/core/src/hooks';

interface Props {
  removeEmployeeFromPayScheduleRequest: (data: RemoveEmployeeRequest) => void;
  getEmployeeListRequest: Function;
  paySchedules?: PaySchedule[];
  companyId?: string;
  token: string;
  children: React.ReactElement;
  withProfileButton?: boolean;
}

export const EmployeeList: React.FC<Props> = ({
  removeEmployeeFromPayScheduleRequest,
  getEmployeeListRequest,
  companyId,
  paySchedules = [],
  token,
  children,
  withProfileButton,
}) => {
  const { handleServerError } = useContext(NotificationContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [substring, setSubstring] = useState<string>();
  const [filters, setFilters] = useState<Partial<ListEmployeeRequestData>>();
  const [offset, setOffset] = useState<number>(0);
  const [selectedEmployee, setSelectedEmployee] = useState<Employee>();
  const [selectedPayScheduleId, setSelectedPayScheduleId] = useState<string>();
  const [total, setTotal] = useState<number>(0);
  const [employees, setEmployees] = useState<PayrollUser[]>([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [roles, setRoles] = useState<Tag[]>([]);
  const [nationalities, setNationalities] = useState<Nationality[]>([]);
  const history = useHistory();
  const [closeGridAction, setCloseGridAction] = useState<boolean>(false);
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'created_at', sort: 'desc' }]);
  const horizontalScrollClasses = useHorizontalScrollStyles();
  const { isMobile } = useContext(DeviceContext);

  const dOffset = useDebounceValue(offset, TABLE_OFFSET_DELAY);

  const enrichedEmployees = useMemo(
    () => employees.map((item, i) => ({ ...item, id: i + offset })),
    [employees]
  );

  const filtersSections: FilterSection[] = [
    {
      title: 'Checks',
      expanded: true,
      filters: [
        {
          type: 'select',
          multiple: false,
          label: 'ID Verified',
          field: 'id_verified',
          values: ['', 'Verified', 'Not Verified'],
          defaultValue: '',
        },
        {
          type: 'select',
          multiple: false,
          label: 'ID Status',
          field: 'id_status',
          values: ['', ...DOCUMENT_STATUSES_ARR],
          defaultValue: '',
        },
        {
          type: 'select',
          multiple: false,
          label: 'RTW Status',
          field: 'rtw_status',
          values: [
            '',
            { name: 'Indefinite', value: RTW_VALUES.INDEFINITE },
            { name: 'Limited', value: RTW_VALUES.LIMITED },
            { name: 'No RTW', value: RTW_VALUES.NO_RTW },
          ],
          defaultValue: '',
        },
        {
          type: 'select',
          multiple: false,
          label: 'VAT Registered',
          field: 'vat_registered',
          values: ['', 'VAT Registered', 'Not VAT Registered'],
          defaultValue: '',
        },
      ],
    },
    {
      title: 'Payroll Types',
      expanded: false,
      filters: [
        {
          type: 'select',
          multiple: false,
          label: 'Pay Schedule',
          field: 'payScheduleName',
          values: ['', ...paySchedules.map(item => item.name)],
          defaultValue: '',
        },
        {
          type: 'select',
          multiple: true,
          label: 'Pay Scheme',
          field: 'pay_schemes',
          values: [
            SUBCONTRACTOR_TYPES.CIS_SELF_EMPLOYED.toUpperCase(),
            SUBCONTRACTOR_TYPES.SELF_EMPLOYED.toUpperCase(),
            SUBCONTRACTOR_TYPES.PAYE.toUpperCase(),
          ],
          defaultValue: [],
        },
        {
          type: 'select',
          multiple: false,
          label: 'Taxation Statuses',
          field: 'taxation_statuses',
          values: [
            '',
            { name: 'CIS 20%', value: TAXATION_STATUSES.CIS20 },
            { name: 'CIS 30%', value: TAXATION_STATUSES.CIS30 },
            { name: 'CIS Gross', value: TAXATION_STATUSES.CIS_GROSS },
            { name: 'Self-employed Gross', value: TAXATION_STATUSES.SELF_EMPLOYED_GROSS },
            { name: 'Gross', value: TAXATION_STATUSES.GROSS },
          ],
          defaultValue: '',
        },
        {
          type: 'autocomplete',
          multiple: true,
          label: 'Business Types',
          field: 'business_types',
          values: [BUSINESS_TYPES.COMPANY, BUSINESS_TYPES.SOLE_TRADER],
          defaultValue: [],
        },
      ],
    },
    {
      title: 'Worker Profiles',
      expanded: false,
      filters: [
        {
          type: 'autocomplete',
          multiple: true,
          label: 'Job Role',
          field: 'job_roles',
          values: roles,
          defaultValue: [],
        },
        {
          type: 'autocomplete',
          multiple: true,
          label: 'Nationality',
          field: 'nationalities',
          values: nationalities,
          defaultValue: [],
        },
      ],
    },
  ];

  const columns: GridColDef[] = [
    {
      field: 'first_name',
      headerName: 'Name',
      flex: 0.2,
      minWidth: 140,
      disableColumnMenu: true,
      renderCell: params => (
        <GridCellWithAvatar
          avatarUrl={params.row.photo}
          avatarPlaceholder={getAvatar(params.row.gender)}
          name={`${params.row.first_name} ${params.row.last_name}`}
          link={`${history.location.pathname}/${params.row?.first_name}_${params.row?.user_id}`}
        />
      ),
    },
    {
      field: 'ni_number',
      headerName: 'Ref #',
      flex: 0.1,
      width: 95,
      disableColumnMenu: true,
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 0.15,
      minWidth: 240,
      disableColumnMenu: true,
      renderCell: params => <AppA content={params.row.email} href={`mailto: ${params.row.email}`} />,
    },
    {
      field: 'phone',
      headerName: 'Phone Number',
      flex: 0.1,
      disableColumnMenu: true,
      minWidth: 150,
      renderCell: params => <AppA content={params.row.phone} href={`tel: ${params.row.phone}`} />,
    },
    {
      field: 'created_at',
      headerName: 'Registration Date',
      flex: 0.1,
      disableColumnMenu: true,
      valueGetter: params => formatTableDate(params.row?.created_at),
      minWidth: 140,
    },
    {
      field: 'pay_schedule',
      headerName: 'Pay Schedule',
      valueGetter: params => params.row.pay_schedule?.name,
      flex: 0.1,
      hideSortIcons: true,
      sortable: false,
      disableColumnMenu: true,
      minWidth: 100,
    },
    {
      field: 'taxation_status',
      headerName: 'Tax Status',
      valueGetter: params => params.row?.taxation_status?.toUpperCase(),
      flex: 0.1,
      disableColumnMenu: true,
      minWidth: 120,
    },
    {
      field: 'pay_scheme',
      headerName: 'Type',
      valueGetter: params => params.row?.pay_scheme?.toUpperCase(),
      flex: 0.05,
      disableColumnMenu: true,
      minWidth: 80,
    },
    {
      field: 'id_verified',
      headerName: 'ID verified',
      flex: 0.1,
      renderCell: params => (
        <IDStatus
          idExpiring={params.row.id_expiring}
          verified={params.row.id_verified}
          status={params.row.id_status}
        />
      ),
      disableColumnMenu: true,
      width: 105,
    },
    {
      field: 'rtw_verified',
      headerName: 'RTW',
      flex: 0.1,
      renderCell: params => (
        <RTWStatus verified={params.row.rtw_verified} idExpiring={params.row.rtw_expiring} />
      ),
      disableColumnMenu: true,
      width: 120,
    },
    {
      field: '',
      headerName: 'Actions',
      width: 80,
      hideSortIcons: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: params => (
        <Box display="flex" width="100%">
          <GridActions close={closeGridAction}>
            <MenuList>
              <MenuItem
                onClick={() => {
                  setSelectedEmployee(params.row as Employee);
                  setSelectedPayScheduleId(params.row.pay_schedule?.pay_schedule_id);
                  setOpenConfirmDialog(true);
                  resetCloseStatus();
                }}>
                Remove from '{params.row.pay_schedule.name}' pay schedule
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setSelectedEmployee(params.row as Employee);
                  setOpenConfirmDialog(true);
                  setSelectedPayScheduleId(undefined);
                  resetCloseStatus();
                }}>
                Remove from Payroll Module
              </MenuItem>
            </MenuList>
          </GridActions>
        </Box>
      ),
    },
  ];

  const fetchJobsAndNationalities = async () => {
    try {
      const resRoles = await fetchTagsRequest({ group: TagGroups.ROLE, short: false });
      const resNationalities = await fetchNationalitiesRequest();
      setRoles(resRoles.data.items);
      setNationalities(resNationalities.data.items);
    } catch (e) {
      console.error(e);
    }
  };

  const resetCloseStatus = () => {
    setCloseGridAction(true);
    setTimeout(() => setCloseGridAction(false), 100);
  };

  const getEmployees = useCallback(async () => {
    if (!companyId || isNil(dOffset)) {
      return;
    }

    try {
      setLoading(true);
      const res = await getEmployeeListRequest({
        companyId,
        data: {
          substring,
          offset: dOffset,
          limit: GRID_PAGE_SIZE,
          company_id: companyId,
          order_by: formatSortModel<PayrollUser>(sortModel),
          ...filters,
        },
      });
      setTotal(res.data.count);
      setEmployees(res.data.items);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      handleServerError(e);
    }
  }, [companyId, substring, dOffset, sortModel, filters]);

  const deleteEmployee = async () => {
    if (!selectedEmployee) {
      return;
    }
    try {
      await removeEmployeeFromPayScheduleRequest({
        token,
        companyId: selectedEmployee.company?.company_id || companyId,
        employeeId: selectedEmployee.uuid,
        payScheduleId: selectedPayScheduleId,
      });
      getEmployees();
      setSelectedEmployee(undefined);
      setSelectedPayScheduleId(undefined);
      setOpenConfirmDialog(false);
    } catch (e) {
      handleServerError(e);
    }
  };

  useEffect(() => {
    fetchJobsAndNationalities();
  }, []);

  const handleSearchChange = useCallback((e: any) => {
    setSubstring(e.target.value);
  }, []);

  const debouncedSearchChange = useMemo(() => debounce(handleSearchChange, 500), [handleSearchChange]);

  const handlePageChange = (newPage: number) => {
    setOffset(newPage * GRID_PAGE_SIZE);
  };

  const handleFilterChange = (newFilters: any) => {
    const formattedFilters = prepareFilters(newFilters);
    setFilters(formattedFilters);
  };

  const handleSortModelChange = (newModel: GridSortModel) => {
    if (isEqual(newModel, sortModel)) {
      return;
    }
    setSortModel(newModel);
  };

  const prepareFilters = (filters: any): ListEmployeeRequestData => {
    const selectedPaySchedule = paySchedules.find(item => item.name === filters.payScheduleName);
    return {
      pay_schedule_id: selectedPaySchedule?.uuid,
      id_verified: getBooleanFilterValue(filters.id_verified),
      rtw_status: filters.rtw_status?.value,
      vat_registered: getBooleanFilterValue(filters.vat_registered),
      id_status: filters.id_status,
      pay_schemes: filters.pay_schemes?.map((item: string) => item.toLowerCase()),
      taxation_statuses: filters.taxation_statuses?.value ? [filters.taxation_statuses.value] : [],
      business_types: filters.business_types?.map((item: string) => item.toLowerCase()),
      job_roles: filters.job_roles?.map((item: Tag) => item.name),
      nationalities: filters.nationalities?.map((item: Nationality) => item.name),
      company_id: companyId,
    };
  };

  useEffect(() => {
    getEmployees();
  }, [getEmployees]);

  return (
    <>
      <Box
        marginBottom={2}
        gridGap={16}
        className={clsx(horizontalScrollClasses.blockWrapper, horizontalScrollClasses.blockWithHideScroll)}>
        <Box>
          <AppSearchInput
            cypressId="search-payroll-users"
            onChange={debouncedSearchChange}
            isSmall
            expanded={!isMobile}
          />
        </Box>

        <Box display="flex" gridGap={16}>
          {children}
          <FilterSystem filtersSections={filtersSections} onFiltersChange={handleFilterChange} />
        </Box>
      </Box>

      <AppDataGridWithSavedPage
        noPaper
        rows={enrichedEmployees}
        columns={columns}
        height="calc(100vh - 240px)"
        rowCount={total}
        loading={loading}
        pageSize={GRID_PAGE_SIZE}
        paginationMode="server"
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        onPageChange={handlePageChange}
        rowsPerPageOptions={[GRID_PAGE_SIZE]}
        disableSelectionOnClick
        unit="users"
        withProfileButton={withProfileButton}
        setOffset={setOffset}
      />

      <ConfirmDialog
        handleClose={() => {
          setOpenConfirmDialog(false);
          setSelectedEmployee(undefined);
          setSelectedPayScheduleId(undefined);
        }}
        open={openConfirmDialog}
        confirmText="Yes, remove them"
        cancelText="No, go back"
        title="Before we remove this profile..."
        subtitle="If you remove this profile you will not be able to process payments for this person going forward. Still want to remove them?"
        handleConfirm={deleteEmployee}
      />
    </>
  );
};
