import React from 'react';

import clsx from 'clsx';
import { Box, makeStyles, Theme, createStyles } from '@material-ui/core';

import { AIChatMessage } from '@vyce/core/src/types';
import { CopyToClipboard } from '../../../components/CopyToClipboard';

interface Props {
  message: AIChatMessage;
}

const boldRegex = /\*\*(.*?)\*\*/g;

export const Message: React.FC<Props> = ({ message }) => {
  const classes = useStyles();
  const isUserMessage = message.role === 'user';
  const content = message.content.replace(/\n/g, '<br/>').replace(boldRegex, '<strong>$1</strong>');
  return (
    <Box
      display="flex"
      alignItems="flex-end"
      gridGap={4}
      style={{ alignSelf: isUserMessage ? 'end' : undefined }}>
      <Box
        className={clsx(
          classes.container,
          { [classes.userMessage]: isUserMessage },
          { [classes.systemMessage]: !isUserMessage }
        )}>
        <p
          dangerouslySetInnerHTML={{
            __html: content,
          }}
          style={{ margin: 0 }}></p>
      </Box>
      {!isUserMessage && (
        <CopyToClipboard
          text={`${content}
<br/>
<br/>
<i style="color:blue;text-align:center;"">Powered by <a href="https://www.vyce.io/">Vyce.io</a> AI Assistant</i>`}
        />
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: 16,
      borderRadius: 16,
      [theme.breakpoints.down('xs')]: {
        padding: 12,
        borderRadius: 12,
      },
    },
    userMessage: {
      backgroundColor: theme.palette.primary.main,
      color: '#FFFFFF',
      borderBottomRightRadius: 0,
      width: 'fit-content',
    },
    systemMessage: {
      backgroundColor: theme.palette.background.paper,
      borderBottomLeftRadius: 0,
    },
  })
);
