import React from 'react';
import { createStyles, LinearProgress, makeStyles, Theme } from '@material-ui/core';
import { LinearProgressProps } from '@material-ui/core/LinearProgress';
import { backgrounds } from '../theme/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    progress: {
      width: '100%',
      borderRadius: '6px',
      height: '8px',
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
      backgroundColor: theme.palette.type === 'dark' ? backgrounds.darkInput : backgrounds.input,
    },
  })
);

export const AppLinearProgress = React.forwardRef((props: LinearProgressProps, ref) => {
  const classes = useStyles();
  return <LinearProgress classes={{ root: classes.progress }} innerRef={ref} {...props} />;
});
