import { makeStyles, Theme } from '@material-ui/core';
import { monochrome } from '@vyce/core/src/theme/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  barText: {
    fontSize: 13,
    fontWeight: 500,
    lineHeight: '22px',
    fill: theme.palette.type === 'dark' ? monochrome.lightest : monochrome.darker,
  },
  chartWrapper: {
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    position: 'relative',
  },

  pageIconButton: {
    border: `1px solid ${theme.palette.type === 'dark' ? monochrome.mediumdark : monochrome.mediumlight}`,
    borderRadius: 6,
    padding: '6px',
    color: theme.palette.type === 'dark' ? monochrome.lighter : monochrome.dark,
  },
  pageIconButtonDisabled: {
    opacity: 0.4,
  },
  wrapper: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  tooltipWrapper: {
    borderRadius: '6px',
    width: '300px',
    padding: '16px 20px',
    backgroundColor: theme.palette.type === 'dark' ? 'rgba(78, 75, 102, 0.4)' : 'rgba(252, 252, 252, 0.8)',
    border: `1px solid ${theme.palette.divider}`,
    backdropFilter: 'blur(5px)',
  },
  legendColorExample: {
    width: 12,
    height: 12,
    borderRadius: '50%',
  },
  smallText: {
    fontSize: '13px',
    lineHeight: '22px',
  },
  barContainer: {
    '& > div > div': {
      position: 'absolute !important',
    },
  },
}));
