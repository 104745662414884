import React from 'react';
import { FieldProps } from "../../types";
import { SEARCH_STATUSES } from "../../constants";
import { ControlledSelect } from "./ControlledSelect";

export const SearchStatusField: React.FC<FieldProps> = ({ margin, label }) => {
  return (
    <ControlledSelect
      margin={margin}
      label={label}
      name="search_status"
      items={SEARCH_STATUSES}
      multiple={false}/>
  )
}
