import React, { useContext, useMemo, useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from '@material-ui/core';

import { TimeLogByShiftDateItemLog } from '../../../../types';
import { formatDate, numbersToTime, timeToSeconds } from '../../../../utils/dates';
import { AMENDMENT_INPUT_LENGTH, AmendmentInput } from './AmendmentInput';
import { editWorkerHoursRequest } from '../../../../api/time';
import { ButtonTitleWithLoading } from '../../../../components';
import { DeviceContext } from '../../../../contexts';
import { MAIN_CONTAINER_ID } from '../../../../constants';

interface Props {
  handleClose: Function;
  open: boolean;
  userId: string;
  log: TimeLogByShiftDateItemLog;
  onSuccess: Function;
  date: string;
}

export const EditWorkerHoursDialog: React.FC<Props> = ({
  open,
  handleClose,
  log,
  userId,
  date,
  onSuccess,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [isNegativeTotal, setIsNegativeTotal] = useState<boolean>(false);
  const [basicTotal, setBasicTotal] = useState<number>(0);
  const [overtimeTotal, setOvertimeTotal] = useState<number>(0);
  const { isMobile } = useContext(DeviceContext);
  const [isBasicMinus, setIsBasicMinus] = useState<boolean>(
    log.basic_amendment_hours < 0 || log.basic_amendment_minutes < 0
  );
  const [isOvertimeMinus, setIsOvertimeMinus] = useState<boolean>(
    log.overtime_amendment_hours < 0 || log.overtime_amendment_minutes < 0
  );
  const [basicValue, setBasicValue] = useState<string>(
    numbersToTime(log.basic_amendment_hours, log.basic_amendment_minutes)
  );
  const [overtimeValue, setOvertimeValue] = useState<string>(
    numbersToTime(log.overtime_amendment_hours, log.overtime_amendment_minutes)
  );

  const isExceeded = useMemo(() => {
    return overtimeTotal + basicTotal > 24 * 60 * 60;
  }, [overtimeTotal, basicTotal]);

  const save = async () => {
    try {
      setLoading(true);
      await editWorkerHoursRequest({
        companyId: log.company_id,
        siteId: log.site_id,
        shiftId: log.shift_id,
        day: date,
        userId,
        amendments: {
          basic_amendment: timeToSeconds(basicValue, isBasicMinus),
          overtime_amendment: timeToSeconds(overtimeValue, isOvertimeMinus),
        },
      });
      setLoading(false);
      onSuccess();
      handleClose();
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  return (
    <Dialog
      container={document.getElementById(MAIN_CONTAINER_ID)}
      fullScreen={isMobile}
      maxWidth="md"
      onClose={() => handleClose()}
      open={open}>
      <DialogTitle>
        Edit Hours for {formatDate(log?.checked_in, 'dddd DD MMMM')}, {log?.shift_name} shift
      </DialogTitle>

      <DialogContent>
        <Box
          display="flex"
          flexDirection={isMobile ? 'column' : 'row'}
          gridGap={32}
          paddingTop={2}
          marginBottom={1}>
          <AmendmentInput
            setIsNegativeTotal={setIsNegativeTotal}
            trackedHours={log.basic_hours}
            trackedMinutes={log.basic_minutes}
            title="Regular Hours"
            value={basicValue}
            handleIconClick={() => setIsBasicMinus(value => !value)}
            isMinus={isBasicMinus}
            setValue={setBasicValue}
            setTotal={setBasicTotal}
          />

          {!isMobile && <Divider orientation="vertical" flexItem />}

          <AmendmentInput
            setIsNegativeTotal={setIsNegativeTotal}
            trackedHours={log.overtime_hours}
            trackedMinutes={log.overtime_minutes}
            title="Overtime Hours"
            value={overtimeValue}
            handleIconClick={() => setIsOvertimeMinus(value => !value)}
            isMinus={isOvertimeMinus}
            setValue={setOvertimeValue}
            setTotal={setOvertimeTotal}
          />
        </Box>
        {isExceeded && (
          <Typography variant="caption" color="error">
            Worked hours can’t exceed 24 hours
          </Typography>
        )}
      </DialogContent>

      <DialogActions>
        <Button size="large" onClick={() => handleClose()} variant="outlined">
          Cancel
        </Button>

        <Button
          style={{ width: 152 }}
          size="large"
          disabled={
            basicValue.length < AMENDMENT_INPUT_LENGTH ||
            overtimeValue.length < AMENDMENT_INPUT_LENGTH ||
            isNegativeTotal ||
            isExceeded
          }
          onClick={() => save()}
          color="primary"
          variant="contained">
          <ButtonTitleWithLoading title="Edit Hours" loaderVariant="primary" loading={loading} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
