import { forwardRef, useContext } from 'react';

import { createStyles, InputProps, makeStyles, styled, useTheme } from '@material-ui/core';
import { RiSearchLine } from 'react-icons/ri';
import InputBase from '@material-ui/core/InputBase';
import clsx from 'clsx';

import { ColorOption } from '../../types';
import { getColor, monochrome } from '../../theme/styles';
import { ColorThemeContext, DeviceContext } from '../../contexts';

interface AppSearchInputProps extends InputProps {
  variant?: ColorOption;
  isLarge?: boolean;
  isSmall?: boolean;
  isBorder?: boolean;
  expanded?: boolean;
  iconColor?: string;
  cypressId?: string;
}

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: '8px',
  backgroundColor: theme.palette.background.default,
  '&:hover': {
    backgroundColor: theme.palette.background.default,
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: '0 20px',
  top: 0,
  left: 0,
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  height: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: '48px',
    height: '100%',
    transition: theme.transitions.create('width'),
    width: 0,
    [theme.breakpoints.up('sm')]: {
      '&:focus': {
        width: '20ch',
      },
    },
    [theme.breakpoints.only('xs')]: {
      '&:focus': {
        width: '14ch',
      },
    },
  },
}));

const useStyles = makeStyles(theme =>
  createStyles({
    expanded: {
      width: '20ch',
      '& .MuiInputBase-input': {
        width: '100%',
      },
    },
    fullWidth: {
      width: '100% !important',
    },
  })
);

export const AppSearchInput = forwardRef((props: AppSearchInputProps, ref) => {
  const { colorTheme } = useContext(ColorThemeContext);
  const { isMobile } = useContext(DeviceContext);
  const theme = useTheme();
  const defaultIconColor = theme.palette.type === 'dark' ? monochrome.mediumlight : monochrome.dark;
  const {
    variant = 'paper',
    isLarge,
    isSmall,
    isBorder,
    expanded,
    fullWidth,
    placeholder = 'Search...',
    iconColor = defaultIconColor,
    ...restProps
  } = props;
  const classes = useStyles();
  const colors: any = getColor(colorTheme === 'dark');
  return (
    <Search
      style={{
        backgroundColor: colors[`${variant}BG`],
        border: isBorder ? `1px solid ${theme.palette.divider}` : 'none',
        height: isLarge ? '55px' : isSmall ? '40px' : '45px',
        minWidth: isLarge ? '55px' : isSmall ? '40px' : '45px',
      }}>
      <StyledInputBase
        inputProps={{ 'aria-label': 'search' }}
        innerRef={ref}
        placeholder={isMobile ? '' : placeholder}
        className={clsx({ [classes.expanded]: expanded, [classes.fullWidth]: fullWidth })}
        {...restProps}
        cy-test-id={restProps.cypressId || 'app-search'}
      />
      <SearchIconWrapper>
        <RiSearchLine size="18px" color={iconColor} />
      </SearchIconWrapper>
    </Search>
  );
});
