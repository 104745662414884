import React, { useContext, useEffect, useState } from 'react';

import { Avatar, Box, Typography, useTheme } from '@material-ui/core';
import dayjs from 'dayjs';
import { useHistory, useParams } from 'react-router-dom';

import { TimePeriod, TimeWorker } from '@vyce/core/src/types';
import { getAvatar } from '@vyce/core/src/utils/getAvatar';
import { BackTo, EmptyList, PageLoading } from '@vyce/core/src/components';
import { getUrlItems } from '@vyce/core/src/utils/url';
import { GetTimePeriodsRequestPayload } from '@vyce/core/src/api/types';
import { getTimePeriodsRequest, getTimeWorkerRequest } from '@vyce/core/src/api/time';
import config from '@vyce/core/src/assets/config';
import { useQuery } from '@vyce/core/src/hooks';
import { formatDate } from '@vyce/core/src/utils/dates';

import { ClockInOutPeriod } from './components';
import { NotificationContext } from '../../../contexts/notificationContext';

interface Props {
  companyId?: string;
  canViewLocation?: boolean;
}

export const DailyActivity: React.FC<Props> = ({ companyId, canViewLocation }) => {
  const theme = useTheme();
  const { handleServerError } = useContext(NotificationContext);
  const [worker, setWorker] = useState<TimeWorker>();
  const [loading, setLoading] = useState<boolean>(false);
  const [periods, setPeriods] = useState<TimePeriod[]>([]);
  const { userId, date } = useParams<{ userId: string; date: string }>();
  const history = useHistory();
  const query = useQuery();
  const shiftId = query.get('shiftId') ?? '';

  const getData = async () => {
    if (!companyId) {
      return;
    }
    try {
      setLoading(true);
      const { id: workerId } = getUrlItems(userId);
      const endDate = formatDate(dayjs(date).add(1, 'day').format());

      const payload: GetTimePeriodsRequestPayload = {
        user_id: workerId,
        shift_id: shiftId,
        start_date: date,
        end_date: endDate,
      };
      const workerRes = await getTimeWorkerRequest(workerId, companyId);
      const periodsRes = await getTimePeriodsRequest({ companyId, payload });
      const items: TimePeriod[] = periodsRes.data.items || [];
      setPeriods(items.filter(item => !item.unpaid_break));
      setWorker(workerRes.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      handleServerError(e);
    }
  };

  const back = () => {
    history.goBack();
  };

  useEffect(() => {
    if (shiftId) {
      getData();
    }
  }, [userId, companyId, shiftId, date]);

  if (loading) {
    return (
      <Box height="calc(100vh - 200px)">
        <PageLoading />
      </Box>
    );
  }

  if (!worker || !periods?.length) {
    return (
      <Box height="calc(100vh - 200px)" display="flex" alignItems="center" justifyContent="center">
        <EmptyList title="No data found..." image={config.icons3D.lightning} />
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column" gridGap={16}>
      <Box margin="-22px -40px 0 -40px">
        <BackTo backTo={{ text: 'Logs', back }} />
        <Box
          display="flex"
          alignItems="center"
          paddingRight={5}
          paddingLeft={5}
          paddingBottom={3}
          bgcolor={theme.palette.background.paper}>
          <Avatar src={worker?.avatar || getAvatar(worker?.gender ?? null)} />
          <Box marginLeft={2}>
            <Typography variant="h6">
              {worker?.first_name} {worker?.last_name}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box display="flex" flexDirection="column" gridGap={24}>
        {periods.map((period, index) => (
          <ClockInOutPeriod
            canViewLocation={canViewLocation}
            companyId={companyId}
            key={'period' + index}
            period={period}
            worker={worker}
          />
        ))}
      </Box>
    </Box>
  );
};
