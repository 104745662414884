import React from 'react';

import { Typography, Box, useTheme } from '@material-ui/core';

import { getExpiringDays, formatDate } from '@vyce/core/src/utils';
import { statuses } from '../config';

type Props = {
  status: keyof typeof statuses;
  expiration_date?: string | null;
};

export const StatusComponent = ({ status, expiration_date }: Props) => {
  const theme = useTheme();
  if (status === 'Expiring') {
    const days = getExpiringDays(formatDate(expiration_date ?? null, undefined, 'DD/MM/YYYY'));
    return (
      <Box color={theme.palette.warning.main} key="status_component">
        <Typography variant="subtitle2">Expiring {days ? `(${days} days)` : ''}</Typography>
      </Box>
    );
  }
  if (status === 'Expired') {
    return (
      <Box color={theme.palette.error.main} key="status_component">
        <Typography variant="subtitle2">Expired</Typography>
      </Box>
    );
  }
  if (status === 'Valid') {
    return (
      <Box color={theme.palette.primary.main} key="status_component">
        <Typography variant="subtitle2">Valid</Typography>
      </Box>
    );
  }

  return (
    <Box color={theme.palette.error.main} key="status_component">
      <Typography variant="subtitle2">Unverified</Typography>
    </Box>
  );
};
