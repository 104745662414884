import uuid from 'uuid';

import { TimeLogByShiftDateItem } from '@vyce/core/src/types';

export const transformData = (dateItems: TimeLogByShiftDateItem[] = []) => {
  return dateItems
    ?.filter(dateItem => dateItem.time_logs.length)
    .map(dateItem =>
      dateItem.time_logs.map(timeLog => ({
        ...timeLog,
        work_day: dateItem.date,
        weekDay: dateItem.weekday,
        id: uuid.v4(),
      }))
    )
    .reverse()
    .flat();
};
