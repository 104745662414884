import React, { useEffect, useState, useContext, useMemo, useCallback } from 'react';

import { GridColDef, GridSortModel } from '@mui/x-data-grid';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';

import { Box, Button, MenuItem, MenuList } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { AiOutlinePlus } from 'react-icons/ai';
import clsx from 'clsx';

import {
  AppDataGridWithSavedPage,
  AppA,
  GridCellWithAvatar,
  ActivateSubscriptionDialog,
} from '@vyce/core/src/components';
import { UserManagementItem } from '@vyce/core/src/types';
import { GRID_PAGE_SIZE, TABLE_OFFSET_DELAY } from '@vyce/core/src/constants';
import { formatSortModel } from '@vyce/core/src/utils/sorting';
import { AppSearchInput } from '@vyce/core/src/components/inputs';
import { GetUserManagementItemsPayload } from '@vyce/core/src/api/types';
import { useHorizontalScrollStyles } from '@vyce/core/src/styles';
import { GridActions } from '@vyce/core/src/components';
import { DeviceContext } from '@vyce/core/src/contexts';
import { getAvatar } from '@vyce/core/src/utils/getAvatar';
import { getUKFormattedDate } from '@vyce/core/src/utils/dates';
import { isNil } from '@vyce/core/src/utils';
import { useDebounceValue } from '@vyce/core/src/hooks';

import { useTypedSelector, useInviteButton } from 'src/hooks';
import { UniversalInviteModule } from 'src/modules';

interface Props {
  users: UserManagementItem[];
  loading: boolean;
  getUsers: (params: GetUserManagementItemsPayload) => void;
  handleRemoveUserClick: (user: UserManagementItem) => void;
  total: number;
  moduleName: string;
}

//TODO check, maybe it's a dublicate with thesquare-web/packages/global-app/src/views/user-management/components/UserList.tsx

export const UserList: React.FC<Props> = ({
  users,
  loading,
  getUsers,
  total,
  handleRemoveUserClick,
  moduleName,
}) => {
  const horizontalScrollClasses = useHorizontalScrollStyles();

  const history = useHistory();
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'first_name', sort: 'asc' }]);
  const [offset, setOffset] = useState<number>(0);
  const [substring, setSubstring] = useState<string>('');
  const { selectedCompany } = useTypedSelector(state => state.helper);
  const [closeGridAction, setCloseGridAction] = useState<boolean>(false);
  const { isMobile } = useContext(DeviceContext);

  const dOffset = useDebounceValue(offset, TABLE_OFFSET_DELAY);

  const {
    isInviteFormOpen,
    subscribeLoading,
    isActivateSubscriptionDialogOpen,
    simpleSubscribe,
    setCloseInviteForm,
    handleInviteButtonClick,
    closeActivateSubscriptionDialog,
  } = useInviteButton();

  const columns: GridColDef[] = [
    {
      field: 'first_name',
      headerName: 'Name',
      flex: 0.1,
      minWidth: 200,
      disableColumnMenu: true,
      renderCell: params => (
        <GridCellWithAvatar
          avatarUrl={params.row.avatar}
          avatarPlaceholder={getAvatar(params.row.gender)}
          name={`${params.row.first_name} ${params.row.last_name}`}
          link={`${history.location.pathname}/${params.row?.first_name}_${params.row?.user_id}`}
        />
      ),
    },
    {
      field: 'ni_number',
      headerName: 'Ref #',
      width: 110,
      flex: 0.1,
      disableColumnMenu: true,
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 0.1,
      minWidth: 270,
      disableColumnMenu: true,
      renderCell: params => <AppA content={params.row.email} href={`mailto: ${params.row.email}`} />,
    },
    {
      field: 'phone',
      headerName: 'Phone Number',
      disableColumnMenu: true,
      minWidth: 180,
      flex: 0.1,
      renderCell: params => <AppA content={params.row.phone} href={`tel: ${params.row.phone}`} />,
    },
    {
      field: 'created_at',
      headerName: 'Reg Date',
      disableColumnMenu: true,
      minWidth: 180,
      flex: 0.1,
      valueGetter: params => getUKFormattedDate(params.row?.created_at),
    },

    {
      field: '',
      headerName: 'Actions',
      width: 80,
      hideSortIcons: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: params => (
        <Box display="flex" width="100%">
          <GridActions close={closeGridAction}>
            <MenuList>
              <MenuItem
                cy-test-id="remove-from-module"
                onClick={() => handleRemoveClick(params.row as UserManagementItem)}>
                Remove from {moduleName} module
              </MenuItem>
            </MenuList>
          </GridActions>
        </Box>
      ),
    },
  ];

  const handleRemoveClick = (user: UserManagementItem) => {
    resetCloseStatus();
    handleRemoveUserClick(user);
  };

  const resetCloseStatus = () => {
    setCloseGridAction(true);
    setTimeout(() => setCloseGridAction(false), 100);
  };

  const handleSortModelChange = (newModel: GridSortModel) => {
    if (isEqual(newModel, sortModel)) {
      return;
    }
    setSortModel(newModel);
  };

  const handlePageChange = (newPage: number) => {
    const newOffset = newPage * GRID_PAGE_SIZE;
    setOffset(newOffset);
  };

  const handleSearchChange = debounce((e: any) => {
    const substr = e.target.value;
    setSubstring(substr);
  }, 500);

  useEffect(() => {
    if (isNil(dOffset)) return;

    getUsers({
      substring,
      offset: dOffset as number,
      limit: GRID_PAGE_SIZE,
      order_by: formatSortModel<UserManagementItem>(sortModel),
    });
  }, [selectedCompany, substring, dOffset, sortModel]);

  return (
    <>
      <Box
        marginBottom={2}
        gridGap={16}
        className={clsx(horizontalScrollClasses.blockWrapper, horizontalScrollClasses.blockWithHideScroll)}>
        <Box>
          <AppSearchInput onChange={handleSearchChange} isSmall expanded={!isMobile} />
        </Box>
        <Box>
          <Button
            onClick={handleInviteButtonClick}
            size="small"
            data-tour="invite"
            startIcon={<AiOutlinePlus />}
            variant="contained"
            color="primary">
            Invite New Members
          </Button>
        </Box>
      </Box>

      <AppDataGridWithSavedPage
        noPaper
        rows={users}
        getRowId={row => row.user_id}
        height="calc(100vh - 240px)"
        columns={columns}
        rowCount={total}
        loading={loading}
        pageSize={GRID_PAGE_SIZE}
        paginationMode="server"
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        onPageChange={handlePageChange}
        rowsPerPageOptions={[GRID_PAGE_SIZE]}
        disableSelectionOnClick
        unit="users"
        withProfileButton
        setOffset={setOffset}
      />
      <UniversalInviteModule isOpen={isInviteFormOpen} onClose={setCloseInviteForm} />
      <ActivateSubscriptionDialog
        open={isActivateSubscriptionDialogOpen}
        onClose={closeActivateSubscriptionDialog}
        callback={simpleSubscribe}
        loading={subscribeLoading}
        wasSubscribed={selectedCompany?.subscribed_company}
      />
    </>
  );
};
