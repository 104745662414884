import axios from 'axios';
import { AIChatMessage } from '../../types';

export const askAIRequest = (messages: AIChatMessage[], model?: string) => {
  return axios.post('https://w6pzfq5p9j.execute-api.eu-west-1.amazonaws.com/prod', {
    messages,
    model,
  });
};

export const speechToTextRequest = (audioFile: string) => {
  return axios.post('https://m2z4glo26g.execute-api.eu-west-1.amazonaws.com/prod', {
    file: audioFile,
  });
};
