import React, { useContext, useEffect, useMemo, useState } from 'react';

import { Box, MenuItem, MenuList, Typography } from '@material-ui/core';
import { GridColDef, GridSortModel } from '@mui/x-data-grid';
import isEqual from 'lodash/isEqual';
import { AxiosResponse } from 'axios';

import { AppDataGrid } from '@vyce/core/src/components/AppDataGrid';
import { CISReport, ReportPayload } from '@vyce/core/src/types';
import { ConfirmDialog } from '@vyce/core/src/components/ConfirmDialog';
import { GRID_PAGE_SIZE } from '@vyce/core/src/constants';
import { formatSortModel } from '@vyce/core/src/utils/sorting';

import { StatusComponents } from './components';
import { GridActions } from '../../components';
import { generateFileLinkAndSave } from '../../utils';
import { DownloadCISReportRequest, RefreshCISReportRequest, SendCISReportRequest } from '../../api/types';
import { NotificationContext } from '../../contexts/notificationContext';

interface Props {
  sendCis300ToHMRCRequest: (params: SendCISReportRequest) => Promise<AxiosResponse>;
  getCisReportsRequest: (params: ReportPayload) => Promise<AxiosResponse>;
  downloadCISReportRequest: (params: DownloadCISReportRequest) => Promise<AxiosResponse>;
  refreshCISReportRequest?: (params: RefreshCISReportRequest) => Promise<AxiosResponse>;
  companyId: string;
  token: string;
}

export const Reports: React.FC<Props> = ({
  sendCis300ToHMRCRequest,
  getCisReportsRequest,
  downloadCISReportRequest,
  refreshCISReportRequest,
  companyId,
  token,
}) => {
  const { handleServerError, showNotification } = useContext(NotificationContext);
  const [reports, setReports] = useState<CISReport[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [offset, setOffset] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedReport, setSelectedReport] = useState<CISReport>();
  const [closeGridAction, setCloseGridAction] = useState<boolean>(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'tax_year', sort: 'desc' }]);
  const initialColumns: GridColDef[] = [
    {
      field: 'tax_year',
      headerName: 'Tax Year',
      flex: 9,
      disableColumnMenu: true,
      minWidth: 150,
    },
    {
      field: 'company',
      headerName: 'Company',
      valueGetter: params => params.row?.company?.name,
      hideSortIcons: true,
      sortable: false,
      disableColumnMenu: true,
      flex: 6,
      minWidth: 120,
    },
    {
      field: 'tax_month',
      headerName: 'Tax Month',
      flex: 9,
      disableColumnMenu: true,
      minWidth: 150,
    },
    {
      field: 'status',
      headerName: 'Status',
      renderCell: params => (
        <StatusComponents status={params.row.status} isGreen={params.row.status === 'accepted'} />
      ),
      flex: 9,
      disableColumnMenu: true,
      minWidth: 150,
    },
    {
      field: '',
      headerName: 'Actions',
      width: 80,
      hideSortIcons: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: params => (
        <Box display="flex" width="100%">
          <GridActions close={closeGridAction}>
            <MenuList>
              {params.row.status !== 'submitted' && (
                <MenuItem onClick={() => handleSubmit(params.row as CISReport)}>Submit</MenuItem>
              )}
              <MenuItem onClick={() => handleDownload(params.row as CISReport)}>Download</MenuItem>
              {refreshCISReportRequest && (
                <MenuItem onClick={() => handleRefresh(params.row as CISReport)}>Refresh</MenuItem>
              )}
            </MenuList>
          </GridActions>
        </Box>
      ),
    },
  ];

  const columns = useMemo(
    () => (companyId ? initialColumns.filter(item => item.field !== 'company') : initialColumns),
    [closeGridAction, companyId]
  );

  const resetCloseStatus = () => {
    setCloseGridAction(true);
    setTimeout(() => setCloseGridAction(false), 100);
  };

  const handleSubmit = (report: CISReport) => {
    setOpenConfirmDialog(true);
    resetCloseStatus();
    setSelectedReport(report);
  };

  const handleDownload = async (report: CISReport) => {
    try {
      const res = await downloadCISReportRequest({ companyId, reportId: report.uuid });
      generateFileLinkAndSave(res);
      resetCloseStatus();
    } catch (e) {
      handleServerError(e);
    }
  };

  const handleRefresh = async (report: CISReport) => {
    if (!refreshCISReportRequest) {
      return;
    }

    try {
      const res = await refreshCISReportRequest({
        companyId: report.company.company_id,
        taxYear: report.tax_year,
        taxMonth: report.tax_month,
      });
      showNotification({ message: res.data.message, options: { variant: 'success' } });
      resetCloseStatus();
    } catch (e) {
      handleServerError(e);
    }
  };

  const getReports = async (offset: number, sortModel: GridSortModel) => {
    try {
      const payload: ReportPayload = {
        limit: GRID_PAGE_SIZE,
        offset,
        order_by: formatSortModel<CISReport>(sortModel),
        company_id: companyId,
      };

      const res = await getCisReportsRequest(payload);
      setReports(res.data.items);
      setTotal(res.data.count);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setReports([]);
      handleServerError(e);
    }
  };

  const sendToHMRC = async () => {
    if (!selectedReport) {
      return;
    }
    const id = companyId || selectedReport?.company?.company_id;
    try {
      await sendCis300ToHMRCRequest({
        companyId: id,
        taxMonth: selectedReport.tax_month,
        taxYear: selectedReport.tax_year,
      });
      showNotification({ message: 'Report has been sent', options: { variant: 'success' } });
      setOpenConfirmDialog(false);
      getReports(offset, sortModel);
    } catch (e) {
      handleServerError(e);
    }
  };

  useEffect(() => {
    getReports(offset, sortModel);
  }, [companyId]);

  const handlePageChange = (newPage: number) => {
    const newOffset = newPage * GRID_PAGE_SIZE;
    setOffset(newOffset);
    getReports(newOffset, sortModel);
  };

  const handleSortModelChange = (newModel: GridSortModel) => {
    if (isEqual(newModel, sortModel)) {
      return;
    }
    setSortModel(newModel);
    getReports(offset, newModel);
  };

  return (
    <>
      <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
        <Typography style={{ fontWeight: 600 }} variant="h6">
          CIS 300
        </Typography>
      </Box>

      <AppDataGrid
        noPaper
        rows={reports}
        loading={loading}
        height="calc(100vh - 256px)"
        columns={columns}
        getRowId={row => row.uuid}
        pageSize={GRID_PAGE_SIZE}
        rowCount={total}
        paginationMode="server"
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        onPageChange={handlePageChange}
        rowsPerPageOptions={[GRID_PAGE_SIZE]}
        disableSelectionOnClick
      />

      <ConfirmDialog
        handleClose={() => setOpenConfirmDialog(false)}
        open={openConfirmDialog}
        confirmText="Submit"
        cancelText="Cancel"
        title="Submit CIS-300?"
        subtitle="Are you sure?"
        handleConfirm={() => sendToHMRC()}
      />
    </>
  );
};
