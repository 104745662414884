import React, { useMemo } from 'react';

import Lottie from 'react-lottie';
import { Box } from '@material-ui/core';

import { isDateExpired, isDateExpiring } from '@vyce/core/src/utils';
import { ReactComponent as VerifiedLabel } from '@vyce/core/src/assets/svg/verified-label.svg';
import { ReactComponent as VerifiedLabelExpiring } from '@vyce/core/src/assets/svg/verification-label-expiring.svg';

import { expiredOptions, unverifiedOptions } from '../config';

type Props = {
  verified?: boolean;
  expiration_date?: string | null;
};
const getStatusComponent = ({ verified, expiration_date }: Props) => {
  if (verified && isDateExpiring(expiration_date)) {
    return <VerifiedLabelExpiring />;
  }
  if (verified && isDateExpired(expiration_date)) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" height="100%">
        <Lottie options={expiredOptions} height={35} width={110} />
      </Box>
    );
  }
  if (verified) {
    return <VerifiedLabel />;
  }
  if (!verified) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" height="100%">
        <Lottie options={unverifiedOptions} height={35} width={110} />
      </Box>
    );
  }
};
export const StatusComponent = (data: Props) => {
  const statusComponent = useMemo(() => getStatusComponent(data), [data]);
  return <>{statusComponent}</>;
};
