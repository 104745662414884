import { useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import {
  Box,
  FilledInput,
  FormControl,
  FormHelperText,
  InputLabel,
  Typography,
  InputAdornment,
} from '@material-ui/core';

import { ControlledTagAutocomplete } from '@vyce/core/src/components/controlled-inputs/ControlledTagAutocomplete';
import { SpecificSkillsField } from '@vyce/core/src/components/controlled-inputs/SpecificSkillsField';
import { useAppTextFieldStyles } from '@vyce/core/src/components/inputs';

import { HiringContext } from '../../../../modules/hiringModule/context/hiringContext';

export const ProfessionalDetails = () => {
  const inputClasses = useAppTextFieldStyles();
  const { token } = useContext(HiringContext);
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Box>
      <Typography variant="subtitle1" color="primary">
        Professional Details
      </Typography>
      <Typography variant="caption" color="textSecondary">
        What specific things are you looking for?
      </Typography>

      <Controller
        control={control}
        rules={{ required: 'Value is required' }}
        name="years_of_experience"
        render={({ field }) => (
          <FormControl fullWidth variant="filled" margin="normal">
            <InputLabel
              variant="filled"
              error={!!errors.years_of_experience?.message}
              htmlFor="years_of_experience">
              Years Experience
            </InputLabel>
            <FilledInput
              {...field}
              disableUnderline
              classes={inputClasses}
              error={!!errors.years_of_experience?.message}
              type="number"
              id="years_of_experience"
              endAdornment={<InputAdornment position="end">Years</InputAdornment>}
            />
            <FormHelperText error id="budget-helper-text">
              {(errors.years_of_experience?.message as string) || ''}
            </FormHelperText>
          </FormControl>
        )}
      />

      <Typography variant="caption" color="textSecondary">
        Any industry qualifications or cards you need? We verify a number of industry cards and
        qualifications with the issuing bodies. Add any that you need.
      </Typography>
      <ControlledTagAutocomplete
        name="qualifications_required"
        label="Type"
        multiple
        margin="normal"
        items={['cscs', 'eusr']}
      />

      <Typography variant="caption" color="textSecondary">
        Any other skills, experience or specialisms?
      </Typography>
      <SpecificSkillsField margin="normal" name="skills" token={token} />
    </Box>
  );
};
