import { useContext, useEffect, useState, useCallback, useMemo } from 'react';

import { useForm } from 'react-hook-form';
import debounce from 'lodash/debounce';

import {
  Checker,
  Image,
  CheckerOptionField,
  CustomQualificationRequestData,
  CustomCertificate,
  Tag,
} from '@vyce/core/src/types';
import { useBooleanState } from '@vyce/core/src/hooks';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';

import { Props, FormProps, UnlistedProps, ListedProps } from '../types';
import {
  QualificationTypes,
  Modes,
  CUSTOM_SOURCE,
  CHECKER_NAME,
  listedDefaultValues,
  notListedValues,
  Types,
} from '../config';
import { getArrayOfNewDocs } from '../utils';

export const useModuleData = ({
  isAdmin,
  userId = '',
  isOpen,
  reRequestData,
  onClose,
  triggerCheckerRequest,
  getCheckersRequest,
  createCustomQualificationRequest,
  uploadCustomQualificationImageRequest,
  getTagListRequest,
  ...restProps
}: Props) => {
  const [isShowPhotosBlock, setShowPhotosBlockTrue, setShowPhotosBlockClose] = useBooleanState(false);
  const [isManualMode, setManualModeTrue, setManualModeFalse] = useBooleanState(false);
  const [selectedQualificationType, setSelectedQualificationType] = useState<
    keyof typeof QualificationTypes | null
  >(null);
  const [photos, setPhotos] = useState<Image[]>([]);
  const [editMode, setEditMode] = useState<keyof typeof Modes | null>(null);
  const [selectedQualificationSource, setSelectedQualificationSource] = useState<string | null>(null);
  const [fields, setFields] = useState<CheckerOptionField[]>([]);
  const [isQualificationLoading, setQualificationLoadingTrue, setQualificationLoadingFalse] =
    useBooleanState(false);
  const { showNotification } = useContext(NotificationContext);
  const [checkers, setCheckers] = useState<Checker[]>([]);
  const [selectedChecker, setSelectedChecker] = useState<Checker>();
  const [candidatesForRemoval, setCandidatesForRemoval] = useState<string[]>([]);
  const [substring, setSubstring] = useState('');
  const [tags, setTags] = useState<Tag[]>([]);

  const selectedCompanyId = (restProps.type === Types.outside && restProps.selectedCompanyId) || '';

  const selectedQualification = restProps.type === Types.inside ? restProps.selectedQualification : null;

  const selectedQualificationId = selectedQualification?.uuid ?? '';

  const methods = useForm<FormProps>({
    defaultValues: listedDefaultValues,
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const {
    reset,
    setValue,
    getValues,
    formState: { isDirty },
  } = methods;

  const customQualificationHandler = () => {
    const values = getValues() as UnlistedProps;
    const startDate =
      selectedQualificationType === QualificationTypes.industry && values.not_expired
        ? undefined
        : values.date;
    const requestData: CustomQualificationRequestData = {
      title: values.title,
      crt_type: selectedQualificationType ?? undefined,
      issuer: typeof values.extra_text === 'string' ? values.extra_text : values.extra_text?.name,
      start_date: selectedQualificationType === QualificationTypes.academic ? values.date : undefined,
      expiry_date: startDate ?? undefined,
      source: CUSTOM_SOURCE,
      reference_number: values.reference_number || undefined,
    };

    if (editMode === Modes.update) updateCustomQualification(requestData);
    if (editMode === Modes.create) createCustomQualification(requestData);
  };

  const updateCustomQualification = async (requestData: CustomQualificationRequestData) => {
    setQualificationLoadingTrue();
    try {
      if (isDirty && restProps.type === Types.inside) {
        await restProps.updateCustomQualificationRequest({
          data: requestData,
          userId,
          certificateId: selectedQualificationId ?? '',
        });
      }

      const arrayOfNewDocs = getArrayOfNewDocs(photos);

      if (arrayOfNewDocs.length) {
        for (let document of arrayOfNewDocs) {
          await uploadDocument({
            document,
            userId,
            certificateId: selectedQualificationId || '',
          });
        }
      }

      if (candidatesForRemoval.length && restProps.type === Types.inside) {
        for (let documentId of candidatesForRemoval) {
          await restProps.deleteCustomQualificationImageRequest({
            userId,
            certificateId: selectedQualificationId ?? '',
            documentId,
          });
        }
      }
      reRequestData();
    } catch (e) {
      console.error(e);
    } finally {
      onCloseDialog();
      setQualificationLoadingFalse();
    }
  };

  const createCustomQualification = async (requestData: CustomQualificationRequestData) => {
    let data: CustomCertificate | null = null;
    setQualificationLoadingTrue();
    try {
      const { data: certificateData } = await createCustomQualificationRequest({
        data: requestData,
        userId,
        companyId: selectedCompanyId,
      });
      data = certificateData;

      const arrayOfNewDocs = getArrayOfNewDocs(photos);

      if (arrayOfNewDocs.length) {
        for (let document of arrayOfNewDocs) {
          await uploadDocument({
            document,
            userId,
            certificateId: data?.uuid || '',
          });
        }
      }
      reRequestData();
    } catch (e) {
      console.error(e);
    } finally {
      onCloseDialog();
      setQualificationLoadingFalse();
    }
  };

  const createListedQualification = async () => {
    try {
      const requestData: any = {};
      const data = getValues() as ListedProps;
      fields.forEach(field => {
        requestData[field.name] = data[field.name as keyof ListedProps];
      });
      const taskName = selectedChecker?.name;
      if (taskName) {
        setQualificationLoadingTrue();
        await triggerCheckerRequest({
          task_name: taskName,
          parameters: requestData,
          userId,
          companyId: selectedCompanyId,
        });
        setCheckers(value => value.filter(i => i.name !== taskName));
        showNotification({ message: 'Verification is in progress', options: { variant: 'success' } });
      }
    } catch (e) {
      console.error(e);
    } finally {
      onCloseDialog();
      setQualificationLoadingFalse();
    }
  };

  const onSubmit = () => {
    if (
      editMode === Modes.update ||
      isManualMode ||
      selectedQualificationType === QualificationTypes.academic
    ) {
      customQualificationHandler();
    } else {
      createListedQualification();
    }
  };

  const setDataRegardingQualification = (qualification: CustomCertificate) => {
    if (!qualification || !isOpen) return;

    const preparedData = {
      title: qualification.title,
      extra_text: qualification.issuer,
      date:
        qualification.crt_type === QualificationTypes.industry
          ? qualification.expiry_date
          : qualification.start_date,
      not_expired: qualification.crt_type === QualificationTypes.industry && !qualification.expiry_date,
      reference_number: qualification?.additional_data?.reference_number ?? '',
    };

    if (qualification?.additional_data?.documents) {
      const preparedDocuments = qualification?.additional_data?.documents.map(
        item =>
          ({
            url: item.doc_url,
            name: item.doc_s3_key,
            id: item.uuid,
          } as Image & { id: string })
      );
      setPhotos(preparedDocuments);
      setShowPhotosBlockTrue();
    }
    setSelectedQualificationType(qualification.crt_type);
    setSelectedQualificationSource(qualification.source);
    setEditMode(Modes.update);
    reset(preparedData);
  };

  const onCloseDialog = () => {
    onClose();
    setSelectedQualificationType(null);
    setSelectedQualificationSource(null);
    setPhotos([]);
    setManualModeFalse();
    setShowPhotosBlockClose();
    setEditMode(null);
    setCandidatesForRemoval([]);
    setSubstring('');
  };

  const uploadDocument = ({
    document,
    certificateId,
    userId,
  }: {
    document: Image;
    certificateId: string;
    userId: string;
  }) => {
    if (document.file) {
      return uploadCustomQualificationImageRequest({
        file: document.file,
        userId,
        certificateId,
        companyId: selectedCompanyId,
      });
    }
    return null;
  };

  const handleAddPhoto = (photo: Image) => {
    setPhotos([...photos, photo]);
  };

  const handleRemovePhoto = async ({ image, index }: { image: Image & { id?: string }; index: number }) => {
    if (image?.id) {
      setCandidatesForRemoval([...candidatesForRemoval, image.id]);
    }
    const photosArr = [...photos];
    photosArr.splice(index, 1);
    setPhotos(photosArr);
  };

  const updateFields = (fields: CheckerOptionField[]) => {
    if (!fields.length) {
      return;
    }
    fields.forEach((field: any) => {
      if (field.value) {
        setValue(field.name, field.value);
      }
    });
    setFields(fields);
  };

  const resetListedCheckerValues = () => {
    if (selectedChecker && selectedChecker?.options?.length === 1) {
      updateFields(selectedChecker.options[0].fields);
    }
  };

  const getAvailableTasks = async () => {
    try {
      let { data } = await getCheckersRequest(userId);
      const qualificationCheckers: Checker[] = data?.items?.filter(
        (checker: Checker) => checker.group === 'Qualifications'
      );
      setCheckers(qualificationCheckers);
    } catch (e) {
      console.error(e);
    }
  };

  const fetchTags = useCallback(
    async (substring: string) => {
      try {
        const { data } = await getTagListRequest({
          group: selectedQualificationType === QualificationTypes.industry ? 'issuer' : 'qualification',
          substring,
        });
        setTags(data.items);
      } catch (e) {
        console.error(e);
      }
    },
    [selectedQualificationType]
  );

  const debouncedFetchTags = useMemo(() => debounce(fetchTags, 500), [fetchTags]);

  useEffect(() => {
    getAvailableTasks();
  }, []);

  useEffect(() => {
    if (isOpen) {
      setEditMode(Modes.create);
      setSelectedQualificationType(QualificationTypes.industry);
    } else {
      onCloseDialog();
    }
  }, [isOpen]);

  useEffect(() => {
    if (checkers.length) {
      const checker = checkers.find(task => task.name === CHECKER_NAME);
      if (checker) {
        setSelectedChecker(checker);
        if (checker?.options?.length === 1) {
          updateFields(checker.options[0].fields);
        }
      }
    }
  }, [checkers]);

  useEffect(() => {
    if (editMode === Modes.update) return;
    if (
      isManualMode &&
      (selectedQualificationType === QualificationTypes.academic ||
        selectedQualificationType === QualificationTypes.industry)
    ) {
      reset(notListedValues);
      return;
    }

    reset(listedDefaultValues);
    resetListedCheckerValues();
  }, [editMode, isManualMode, selectedQualificationType]);

  useEffect(() => {
    debouncedFetchTags(substring);
  }, [substring, debouncedFetchTags]);

  useEffect(() => {
    if (selectedQualification) {
      setDataRegardingQualification(selectedQualification);
    }
  }, [selectedQualification]);

  return {
    selectedQualificationType,
    selectedQualificationSource,
    checkers,
    methods,
    isOpen,
    isManualMode,
    photos,
    isShowPhotosBlock,
    editMode,
    listedPresetFields: fields,
    isQualificationLoading,
    tags,
    isAdmin,
    setManualMode: setManualModeTrue,
    onSubmit,
    setSelectedQualificationType,
    setDialogClose: onCloseDialog,
    handleAddPhoto,
    handleRemovePhoto,
    setShowPhotosBlockTrue,
    setSubstring,
  };
};
