import React from 'react';

import { SizeMe, SizeMeProps } from 'react-sizeme';
import { Layouts, Responsive as ResponsiveReactGridLayout } from 'react-grid-layout';
import { Box, useTheme } from '@material-ui/core';

import { WidgetWrapper } from './WidgetWrapper';
import { WidgetItem } from '../../types';

interface Props {
  widgets: WidgetItem[];
  getWidget: Function;
  onWidgetStatusChange: Function;
  onLayoutChange: any;
  layouts: any;
}

const margins: [number, number] = [24, 24];
const cols = { lg: 8, md: 4, sm: 2, xs: 1, xxs: 1 };

export const getInitialLayouts = (items: WidgetItem[]): any => ({
  lg: items.map(item => item.layout),
  md: items.map(item => item.layout),
  sm: items.map(item => item.layout),
  xxs: items.map(item => item.layout),
  xs: items.map(item => item.layout),
});

const formatLayouts = (layouts: any) => {
  if (!layouts) {
    return undefined;
  }

  let formattedLayout: Layouts = {};
  Object.keys(layouts)?.forEach(key => {
    formattedLayout[key] = layouts[key]
      ? layouts[key]?.map((item: any) => ({
          ...item,
          minH: item.minH || undefined,
          minW: item.minW || undefined,
          maxH: item.maxH || undefined,
          maxW: item.maxW || undefined,
          w: item.minW && item.minW > item.w ? item.minW : item.w,
        }))
      : [];
  });
  return formattedLayout;
};

export const AppDashboard: React.FC<Props> = ({
  widgets,
  layouts,
  getWidget,
  onWidgetStatusChange,
  onLayoutChange,
}) => {
  const theme = useTheme();
  const { lg, md, xs, sm } = theme.breakpoints.values;
  const formattedLayouts = React.useMemo(() => formatLayouts(layouts), [layouts]);
  const children = React.useMemo(() => {
    return widgets
      ?.filter(item => item.is_active)
      ?.map(item => (
        <Box key={item.system_name} className="widget" data-grid={item.layout}>
          <WidgetWrapper
            key={item.system_name}
            item={item}
            onRemoveItem={onWidgetStatusChange}
            getWidget={getWidget}
          />
        </Box>
      ));
  }, [widgets, getWidget]);

  return (
    <SizeMe>
      {(props: SizeMeProps) => (
        <ResponsiveReactGridLayout
          className="layout"
          layouts={formattedLayouts}
          isBounded={true}
          margin={margins}
          breakpoints={{ lg, md, sm, xs, xxs: xs }}
          cols={cols}
          rowHeight={40}
          useCSSTransforms={true}
          draggableHandle=".draggable-widget-header"
          onLayoutChange={onLayoutChange}
          width={props.size.width || 0}>
          {children}
        </ResponsiveReactGridLayout>
      )}
    </SizeMe>
  );
};
