import React from 'react';

import { Box, Typography, MenuItem } from '@material-ui/core';
import { GridColDef } from '@mui/x-data-grid';

import { AppSelect } from '@vyce/core/src/components/inputs';
import { AppDataGrid, AppLink } from '@vyce/core/src/components';

import { useWidgetData } from './hooks';
import { Props } from './types';
import { useStyles } from './styles';
import { GRID_PAGE_SIZE } from './config';
import { getTime } from '@vyce/core/src/utils/dates';

export const ClockOutWidget = (props: Props) => {
  const classes = useStyles();

  const {
    workers,
    locationOptions,
    selectedLocationId,
    total,
    sortModel,
    cnaViewWorker,
    handlePageChange,
    setSelectedLocationId,
    handleSortModelChange,
  } = useWidgetData(props);

  const columns: GridColDef[] = [
    {
      field: 'first_name',
      headerName: 'Name',
      flex: 1,
      disableColumnMenu: true,
      minWidth: 150,
      renderCell: params => (
        <Box display="flex" width="100%">
          {cnaViewWorker ? (
            <AppLink to={`/time/users/${params.row.first_name}_${params.row.user_id}/logs`}>
              {`${params.row?.first_name} ${params.row?.last_name}`}
            </AppLink>
          ) : (
            <Typography variant="subtitle2">{`${params.row?.first_name} ${params.row?.last_name}`}</Typography>
          )}
        </Box>
      ),
    },
    {
      field: 'site_name',
      headerName: 'Location',
      flex: 0.8,
      disableColumnMenu: true,
      minWidth: 100,
    },

    {
      field: 'checked_out',
      headerName: 'Clocked out',
      flex: 0.8,
      disableColumnMenu: true,
      minWidth: 100,
      valueGetter: params => getTime(params.row.checked_out),
    },
  ];

  const handleChange = (event: any) => {
    setSelectedLocationId(event.target.value);
  };

  return (
    <Box width="100%" height="100%" display="flex" flexDirection="column" gridGap={12}>
      <Box width="100%" display="flex" gridGap={12} alignItems="center">
        <Typography color="primary" className={classes.count}>
          {total} workers
        </Typography>
        <Typography>on</Typography>
        <AppSelect
          onChange={handleChange}
          value={selectedLocationId}
          labelId="select-location"
          fullWidth
          className={classes.selector}>
          {locationOptions.map(item => (
            <MenuItem key={item.value} value={item.value}>
              {item.name}
            </MenuItem>
          ))}
        </AppSelect>
      </Box>
      <AppDataGrid
        rows={workers}
        getRowId={row => row.user_id}
        columns={columns}
        noPaper
        rowHeight={80}
        height="calc(100% - 50px)"
        rowCount={total}
        sortModel={sortModel}
        pageSize={GRID_PAGE_SIZE}
        paginationMode="server"
        sortingMode="server"
        rowsPerPageOptions={[GRID_PAGE_SIZE]}
        onPageChange={handlePageChange}
        onSortModelChange={handleSortModelChange}
        disableSelectionOnClick
      />
    </Box>
  );
};
