import { Box, Typography, Paper } from '@material-ui/core';
import { GridColDef } from '@mui/x-data-grid';
import { RiEyeLine } from 'react-icons/ri';

import { AppDataGrid, FilterSystem, AppIconButton } from '@vyce/core/src/components';
import { AppRangeDatePicker } from '@vyce/core/src/components/inputs';
import { GRID_PAGE_SIZE } from '@vyce/core/src/constants';
import { formatDateToDayNumberMonth, getTime } from '@vyce/core/src/utils/dates';
import { twoDigits } from '@vyce/core/src/utils/numbers';

import { useModuleData } from './hooks';
import { ClockColHeader, ClockValue } from './components';
import { Props } from './types';
import { useStyles } from './styles';

export const EmployeeTimeLogs = (props: Props) => {
  const {
    timeLogsData,
    loading,
    total,
    sortModel,
    filters,
    filtersSections,
    dateFrom,
    dateTo,
    setDateFrom,
    setDateTo,
    handleFilterChange,
    goToDailyActivity,
    handlePageChange,
    handleSortModelChange,
  } = useModuleData(props);
  const classes = useStyles();

  const columns: GridColDef[] = [
    {
      field: 'work_day',
      headerName: 'Dates',
      disableColumnMenu: true,
      valueFormatter: params => formatDateToDayNumberMonth(params.row.work_day),
      flex: 0.2,
      minWidth: 120,
      sortable: false,
    },
    {
      field: 'site_name',
      headerName: 'Location',
      disableColumnMenu: true,
      flex: 0.2,
      minWidth: 150,
      sortable: false,
    },
    {
      field: 'clocked_in_hours',
      renderHeader: ClockColHeader,
      renderCell: params => (
        <ClockValue
          clocked_in_hours={getTime(params.row?.checked_in ?? 0)}
          clocked_out_hours={getTime(params.row?.checked_out ?? 0)}
        />
      ),
      flex: 0.2,
      sortable: false,
      disableColumnMenu: true,
      minWidth: 120,
    },
    {
      field: 'clocked_hours',
      headerName: 'Clocked Hours',
      valueFormatter: params =>
        `${twoDigits(params.row.clocked_in_hours)}:${twoDigits(params.row.clocked_in_minutes)}`,
      flex: 0.2,
      disableColumnMenu: true,
      sortable: false,
      minWidth: 140,
    },
    {
      field: 'basic_hours',
      headerName: 'Regular Paid Hours',
      valueFormatter: params =>
        `${twoDigits(params.row.basic_hours)}:${twoDigits(params.row.basic_minutes)}`,
      flex: 0.2,
      disableColumnMenu: true,
      sortable: false,
      minWidth: 140,
    },
    {
      field: 'overtime_hours',
      headerName: 'Overtime Paid Hours',
      valueFormatter: params =>
        `${twoDigits(params.row.overtime_hours)}:${twoDigits(params.row.overtime_minutes)}`,
      flex: 0.2,
      disableColumnMenu: true,
      sortable: false,
      minWidth: 100,
    },
    // TODO: uncomment when backend creates api request to get time periods by worker
    // {
    //   field: '',
    //   headerName: '',
    //   width: 90,
    //   hideSortIcons: true,
    //   sortable: false,
    //   disableColumnMenu: true,
    //   renderCell: ({ row }) => (
    //     <AppIconButton
    //       onClick={() =>
    //         goToDailyActivity({
    //           shiftId: row.shift_id,
    //           userId: row.user_id,
    //           date: row.work_day,
    //           companyId: row.company_id,
    //         })
    //       }>
    //       <RiEyeLine />
    //     </AppIconButton>
    //   ),
    // },
  ];

  return (
    <Box display="flex" flexDirection="column" gridGap={8}>
      <Box width="100%" display="flex" justifyContent="flex-end">
        <FilterSystem
          selectedValues={filters}
          filtersSections={filtersSections}
          onFiltersChange={handleFilterChange}
        />
      </Box>
      <Paper variant="outlined" className={classes.paper}>
        <Box display="flex" alignItems="center" justifyContent="flex-end" gridGap={8} mb={2}>
          <AppRangeDatePicker
            dateFrom={dateFrom}
            dateTo={dateTo}
            setDateFrom={setDateFrom}
            setDateTo={setDateTo}
          />
        </Box>

        <AppDataGrid
          noPaper
          rows={timeLogsData}
          height={'calc(100vh - 240px)'}
          getRowId={row => row.id}
          loading={loading}
          columns={columns}
          rowCount={total}
          paginationMode="server"
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          onPageChange={handlePageChange}
          pageSize={GRID_PAGE_SIZE}
          rowsPerPageOptions={[GRID_PAGE_SIZE]}
          disableSelectionOnClick
        />
      </Paper>
    </Box>
  );
};
