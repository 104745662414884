import React, { ReactElement, useMemo } from 'react';

import { Box, createStyles, makeStyles, Popover, Theme, Typography } from '@material-ui/core';

import { monochrome } from '../../../../theme/styles';
import { MAIN_CONTAINER_ID } from '../../../../constants';

interface Props {
  children: ReactElement;
  check_in_ok?: boolean;
  check_out_ok?: boolean;
  face_out_ok?: boolean;
  face_in_ok?: boolean;
  auto_clock_out?: boolean;
  manual_clock_out?: boolean;
}

export const TimeLogWarningCell: React.FC<Props> = ({
  children,
  face_in_ok,
  face_out_ok,
  check_out_ok,
  check_in_ok,
  auto_clock_out,
  manual_clock_out,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const classes = useStyles();

  const hasWarnings =
    check_in_ok === false ||
    check_out_ok === false ||
    face_out_ok === false ||
    face_in_ok === false ||
    auto_clock_out ||
    manual_clock_out;

  const getWarningTooltipText = useMemo((): string[] => {
    let warnings = [];
    if (face_in_ok === false) {
      warnings.push('Clock In FaceMatch not verified');
    }
    if (face_out_ok === false) {
      warnings.push('Clock Out FaceMatch not verified');
    }
    if (check_in_ok === false) {
      warnings.push('Clocked In outside of work location');
    }
    if (check_out_ok === false) {
      warnings.push('Clocked Out outside of work location');
    }
    if (manual_clock_out) {
      warnings.push('Manual Clock Out by a manager');
    }
    if (auto_clock_out) {
      warnings.push('Automated Clocked Out');
    }
    return warnings;
  }, [face_out_ok, check_out_ok, check_in_ok, face_in_ok, manual_clock_out, auto_clock_out]);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!hasWarnings) {
      return;
    }
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <Box
        paddingLeft={0.5}
        paddingRight={0.5}
        borderRadius={4}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        className={hasWarnings ? classes.warningContainer : undefined}>
        {children}
      </Box>

      <Popover
        id="mouse-over-popover"
        container={document.getElementById(MAIN_CONTAINER_ID)}
        className={classes.popover}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus>
        <Box className={classes.content}>
          {getWarningTooltipText.map(item => (
            <Typography key={item} className={classes.text} variant="caption">
              {item}
            </Typography>
          ))}
        </Box>
      </Popover>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    warningContainer: {
      lineHeight: '24px',
      background: theme.palette.warning.light,
      border: `1px solid ${theme.palette.warning.main}`,
    },
    popover: {
      pointerEvents: 'none',
    },
    content: {
      padding: '10px 16px',
      display: 'flex',
      flexDirection: 'column',
    },
    text: {
      color: theme.palette.type === 'dark' ? monochrome.mediumlight : monochrome.dark,
    },
  })
);
