import { getCheckInWorkersRequest } from '../../../../api/time';
import { useBooleanState } from '../../../../hooks';

interface Props {
  companyId?: string;
  successCallback: () => void;
}

export const useCheckClockedInWorkers = ({ companyId, successCallback }: Props) => {
  const [isForbidDIalogOpen, openForbidDialog, closeForbidDialog] = useBooleanState(false);

  const handleDeleteClick = async (siteId: string) => {
    if (!companyId) {
      return;
    }
    try {
      const res = await getCheckInWorkersRequest({
        companyId,
        payload: { site_id: siteId, limit: 1 },
      });
      if (res.data.count) {
        return openForbidDialog();
      }
      successCallback();
    } catch (e) {}
  };

  return { isForbidDIalogOpen, openForbidDialog, closeForbidDialog, handleDeleteClick };
};
