import React, { useCallback, useContext } from 'react';

import noop from 'lodash/noop';
import { Box, Button, Grid } from '@material-ui/core';
import { AiOutlinePlus } from 'react-icons/ai';
import clsx from 'clsx';

import { TileList } from '@vyce/core/src/views/hiring/components/TileList';
import { Sorting } from '@vyce/core/src/views/hiring/components/Sorting';
import { ConfirmDialog } from '@vyce/core/src/components';
import { AppSearchInput } from '@vyce/core/src/components/inputs';
import { DeviceContext, TeamListModuleContext, TeamsPageContext } from '@vyce/core/src/contexts';
import { useHorizontalScrollStyles } from '@vyce/core/src/styles';

import { GetStarted } from './GetStarted';
import { CreateTeamDialog, TeamCard } from './components';

export const Teams = () => {
  const horizontalScrollClasses = useHorizontalScrollStyles();
  const { isMobile } = useContext(DeviceContext);
  const { loading, teams = [] } = useContext(TeamsPageContext);
  const {
    paginationOptions,
    openTeamDialog,
    handleSetSubstring,
    isOpenNonSubscribedDialog = false,
    handleCloseNonSubscribedDialog = noop,
    sort,
  } = useContext(TeamListModuleContext);

  const handleCreateNewTeam = useCallback(() => {
    openTeamDialog();
  }, [openTeamDialog]);

  return (
    <>
      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent="space-between"
        marginBottom={2}
        alignItems="center">
        <Box
          className={clsx(horizontalScrollClasses.blockWrapper, horizontalScrollClasses.blockWithHideScroll)}
          gridGap={16}>
          <Box>
            <AppSearchInput onChange={handleSetSubstring} isSmall expanded={!isMobile} />
          </Box>

          <Box display="flex" gridGap={16}>
            <Box>
              <Sorting sort={sort} sortOptions={['By name', 'Created at', 'Updated at']} />
            </Box>

            <Box display="flex">
              <Button
                fullWidth={isMobile}
                size="small"
                startIcon={<AiOutlinePlus />}
                color="primary"
                onClick={handleCreateNewTeam}
                variant="contained">
                Create New Team
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>

      <TileList loading={loading} paginationOptions={paginationOptions}>
        <>
          {teams?.map((team, index) => (
            <Grid key={index} item xs={12} md={6} lg={4}>
              <TeamCard {...team} />
            </Grid>
          ))}
        </>
      </TileList>

      <CreateTeamDialog />

      <ConfirmDialog
        handleClose={handleCloseNonSubscribedDialog}
        open={isOpenNonSubscribedDialog}
        confirmText="Ok"
        showCancelText={false}
        title="You can add team in subscribed company only"
        handleConfirm={handleCloseNonSubscribedDialog}
      />
    </>
  );
};

Teams.GetStarted = GetStarted;
