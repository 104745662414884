import React, { useContext, useEffect, useMemo, useState } from 'react';

import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import capitalize from 'lodash/capitalize';
import { Box, Button, MenuItem, MenuList, Typography } from '@material-ui/core';
import { GridSortModel } from '@mui/x-data-grid';

import { GRID_PAGE_SIZE } from '../../../constants';
import { AppSearchInput } from '../../../components/inputs';
import { AppDataGrid, GridActions, IsVerified } from '../../../components';
import {
  deletePayScheduleTagsRequest,
  getPayScheduleTagsRequest,
  patchPayScheduleTagsRequest,
} from '../../../api/pay-schedules';
import { PayTag, PayTagGroup } from '../../../types';
import { formatSortModel } from '../../../utils/sorting';
import { AddPayTagDialog } from './AddPayTagDialog';
import { NotificationContext } from '../../../contexts/notificationContext';

interface Props {
  group: PayTagGroup;
  payScheduleId: string;
  subtitle: string;
  companyId: string;
}

export const PayTagsGrid: React.FC<Props> = ({ group, payScheduleId, subtitle, companyId }) => {
  const { handleServerError } = useContext(NotificationContext);
  const [offset, setOffset] = useState<number>(0);
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'first_name', sort: 'desc' }]);
  const [tags, setTags] = useState<PayTag[]>([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [total, setTotal] = useState<number>(0);
  const [substring, setSubstring] = useState<string>('');
  const [closeGridAction, setCloseGridAction] = useState<boolean>(false);

  const columns = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        flex: 0.7,
        disableColumnMenu: true,
        minWidth: 150,
      },
      {
        field: 'verified',
        headerName: 'Verified',
        flex: 0.3,
        minWidth: 150,
        disableColumnMenu: true,
        renderCell: (params: any) => <IsVerified verified={params.row.verified} />,
      },
      {
        field: '',
        headerName: 'Actions',
        width: 80,
        hideSortIcons: true,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params: any) => (
          <Box display="flex" width="100%">
            <GridActions close={closeGridAction}>
              <MenuList>
                {!params.row?.verified && (
                  <MenuItem onClick={() => handleVerify(params.row as PayTag)}>Verify</MenuItem>
                )}
                <MenuItem onClick={() => handleDelete(params.row as PayTag)}>Delete</MenuItem>
              </MenuList>
            </GridActions>
          </Box>
        ),
      },
    ],
    [closeGridAction]
  );

  const resetCloseStatus = () => {
    setCloseGridAction(true);
    setTimeout(() => setCloseGridAction(false), 100);
  };

  const handleVerify = async (tag: PayTag) => {
    if (!tag?.uuid) {
      return;
    }
    try {
      await patchPayScheduleTagsRequest({
        payScheduleTagId: tag.uuid,
        payScheduleId,
        companyId,
        verified: true,
        group,
        name: tag.name,
      });
      resetCloseStatus();
      getTags({ offset, substring, sortModel });
    } catch (e) {
      handleServerError(e);
    }
  };

  const handleDelete = async (tag: PayTag) => {
    if (!tag?.uuid) {
      return;
    }
    try {
      await deletePayScheduleTagsRequest({
        payScheduleTagId: tag.uuid,
        payScheduleId,
        companyId,
      });
      resetCloseStatus();
      getTags({ offset, substring, sortModel });
    } catch (e) {
      handleServerError(e);
    }
  };

  const getTags = async ({
    offset,
    substring,
    sortModel,
  }: {
    offset: number;
    substring: string;
    sortModel: GridSortModel;
  }) => {
    try {
      setLoading(true);
      const res = await getPayScheduleTagsRequest({
        group,
        payScheduleIds: [payScheduleId],
        companyId,
        offset,
        substring,
        order_by: formatSortModel<PayTag>(sortModel),
      });
      setTags(res.data.items);
      setTotal(res.data.count);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  const handleSortModelChange = (newModel: GridSortModel) => {
    if (isEqual(newModel, sortModel)) {
      return;
    }
    setSortModel(newModel);
  };

  const handlePageChange = (newPage: number) => {
    const newOffset = newPage * GRID_PAGE_SIZE;
    setOffset(newOffset);
  };

  const handleSearchChange = debounce((event: any) => {
    const substring = event.target.value;
    setSubstring(substring);
  }, 500);

  useEffect(() => {
    getTags({ offset, substring, sortModel });
  }, [substring, offset, sortModel]);

  return (
    <Box mt={2}>
      <Typography variant="h6">Starter {capitalize(group)}</Typography>

      <Typography>{subtitle}</Typography>

      <Box mb={2} mt={2} display="flex" justifyContent="space-between" gridGap={16}>
        <AppSearchInput onChange={handleSearchChange} isSmall expanded={true} />

        <Button size="small" color="primary" onClick={() => setOpen(true)} type="submit" variant="contained">
          Add {capitalize(group)}
        </Button>
      </Box>

      <AppDataGrid
        noPaper
        rows={tags}
        getRowId={row => row.uuid}
        columns={columns}
        height="500px"
        rowCount={total}
        loading={loading}
        paginationMode="server"
        pageSize={GRID_PAGE_SIZE}
        onPageChange={handlePageChange}
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        disableSelectionOnClick
      />

      <AddPayTagDialog
        getTags={() => getTags({ offset, substring, sortModel })}
        companyId={companyId}
        payScheduleId={payScheduleId}
        group={group}
        open={open}
        handleClose={() => setOpen(false)}
      />
    </Box>
  );
};
