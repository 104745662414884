import React, { useContext, useMemo, useState } from 'react';

import { Box, Typography, useTheme } from '@material-ui/core';
import { BiIdCard } from 'react-icons/bi';
import clsx from 'clsx';

import { ReactComponent as VerifiedLabel } from '@vyce/core/src/assets/svg/verified-label.svg';
import { ReactComponent as VerifiedSmallLabel } from '@vyce/core/src/assets/svg/verified-small-label-icon.svg';
import { DeviceContext } from '@vyce/core/src/contexts';
import { Qualifications } from '@vyce/core/src/modules';
import { isDateExpiring, getUKFormattedDate } from '@vyce/core/src/utils/dates';

import { QualificationType } from '../../types';
import useStyles from '../../styles';

interface Props {
  isHiring?: boolean;
  qualification: QualificationType;
}

const StatusComponent: React.FC<{ verified: boolean; children: React.ReactNode }> = ({
  verified,
  children,
}) => {
  const classes = useStyles();
  return (
    <Typography
      variant="subtitle1"
      color={verified ? 'primary' : 'error'}
      className={clsx(classes.smallBold, classes.smallText, { [classes.bold]: !verified })}>
      {children}
    </Typography>
  );
};

export const QualificationItem: React.FC<Props> = ({ qualification, isHiring = false }) => {
  const { issuer, verified = false, source, title } = qualification;
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState<boolean>(false);
  const { isMobile } = useContext(DeviceContext);

  const getStatus = () => {
    if (source === 'custom') {
      if (qualification.type === 'certificate' && isDateExpiring(qualification.expiry_date)) {
        return (
          <Box display="flex" alignItems="center" gridGap={4}>
            <Typography
              style={{ color: '#EAAC30' }}
              className={clsx(classes.smallBold, classes.smallText, classes.bold)}>
              Expiring
            </Typography>
            <Typography className={classes.smallText}>
              {getUKFormattedDate(qualification.expiry_date)}
            </Typography>
          </Box>
        );
      }

      return <StatusComponent verified={verified}> {verified ? 'Valid' : 'Expired'}</StatusComponent>;
    }

    return (
      <StatusComponent verified={verified}> {verified ? 'Vyce verified' : 'Unverified'}</StatusComponent>
    );
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const label = useMemo(
    () => (isHiring || isMobile ? <VerifiedSmallLabel /> : <VerifiedLabel />),
    [isHiring, isMobile]
  );

  return (
    <>
      <Box
        onClick={handleOpen}
        alignItems="center"
        width="100%"
        display="flex"
        style={{ cursor: 'pointer' }}>
        <Box className={clsx(classes.iconContainer, { [classes.smallIconContainer]: isHiring })}>
          <BiIdCard size="22px" color={theme.palette.primary.main} />
        </Box>

        <Box marginLeft={2} display="flex" flexDirection="column" width="100%">
          <Typography
            variant="subtitle1"
            className={clsx(classes.bold, classes.ellipcedText, {
              [classes.smallBold]: isHiring,
              [classes.unverifiedText]: !(verified && source !== 'custom'),
            })}>
            {title}
          </Typography>

          <Typography
            variant="subtitle1"
            color="textSecondary"
            className={clsx(
              classes.ellipcedText,
              classes.smallBold,
              classes.smallText,
              classes.grayscaleBodyColor
            )}>
            {issuer}
          </Typography>

          <Box display="flex" alignItems="center" gridGap={8}>
            <Typography
              variant="subtitle1"
              color="textSecondary"
              className={clsx(classes.smallBold, classes.smallText)}>
              Status:
            </Typography>

            {getStatus()}
          </Box>
        </Box>

        <Box className={classes.verifiedLabelWrapper}>{verified && source !== 'custom' && label}</Box>
      </Box>

      <Qualifications.QualificationPreviewDialog
        handleClose={handleClose}
        open={open}
        qualification={qualification}
      />
    </>
  );
};
