import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import noop from 'lodash/noop';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import uniq from 'lodash/uniq';

import { Badge, Box, Button, Grid, Paper, Typography, useTheme } from '@material-ui/core';
import { AiOutlinePlus, AiOutlineRedo } from 'react-icons/ai';
import { BiExport, BiImport } from 'react-icons/bi';
import { BsCheck } from 'react-icons/bs';
import { GridColDef, GridSortModel } from '@mui/x-data-grid';
import { FiCopy, FiDownloadCloud } from 'react-icons/fi';
import { useHistory, useParams } from 'react-router-dom';
import clsx from 'clsx';
import { AxiosResponse } from 'axios';

import {
  Company,
  MappingItem,
  PaySchedule,
  PeriodData,
  TimeAndAttendanceLocation,
  Timesheet,
  TimesheetLine,
  TimesheetTotals,
} from '@vyce/core/src/types';
import { getUrlItems } from '@vyce/core/src/utils/url';
import {
  AddTimesheetLine,
  LastErrorsDialog,
  MappingDialog,
  TimesheetLineActions,
  TimesheetUploadDialog,
} from '@vyce/core/src/views/payroll/components';
import { DeviceContext } from '@vyce/core/src/contexts';
import { AppDataGrid, ConfirmDialog, RouterPrompt } from '@vyce/core/src/components';
import { AppMenu, AppSearchInput } from '@vyce/core/src/components/inputs';
import { GRID_PAGE_SIZE, MAPPING_PROPERTIES } from '@vyce/core/src/constants';
import {
  GenerateNextPayrun,
  GetTimesheetData,
  TimesheetByStaffDTO,
  TimesheetLineDTO,
} from '@vyce/core/src/api/types';
import { formatTableDate } from '@vyce/core/src/utils/dates';
import { formatSortModel } from '@vyce/core/src/utils/sorting';
import { currencyFormatter, generateExcelFileLink } from '@vyce/core/src/utils';
import { useHorizontalScrollStyles } from '@vyce/core/src/styles';

import { useStyles } from '../styles';
import { PayScheduleInfo } from './PayScheduleInfo';
import { PullTimelogsDialog, PullTimelogsForm } from './PullTimelogsDialog';
import { pullTimeLogsRequest } from '../../../api/pay';
import { NotificationContext } from '../../../contexts/notificationContext';

const TIMESHEET_TEMPLATE_LINK =
  'https://sq2-dev-public.s3.eu-west-2.amazonaws.com/static/templates/Vyce+Timesheet+Template+MASTER.xlsx';

interface Props {
  getTimesheetRequest: (
    companyId: string,
    payrunId: string,
    payload: GetTimesheetData
  ) => Promise<AxiosResponse<TimesheetByStaffDTO>>;
  submitTimesheetRequest: (
    companyId: string,
    payScheduleId: string,
    payrunId: string,
    payload: GetTimesheetData
  ) => Promise<AxiosResponse<TimesheetByStaffDTO>>;
  reopenTimesheetRequest: Function;
  deleteTimesheetLineRequest: Function;
  importTimesheetRequest: Function;
  editTimesheetLineRequest: Function;
  createTimesheetLineRequest: Function;
  lookupEmployeesRequest: Function;
  getTimesheetAsFileRequest: Function;
  getTimesheetMappingRequest: Function;
  duplicateTimesheetRequest: (params: GenerateNextPayrun) => Promise<any>;
  token: string;
  locations: TimeAndAttendanceLocation[];
}

export const TimesheetPage: React.FC<Props> = ({
  getTimesheetRequest,
  lookupEmployeesRequest,
  submitTimesheetRequest,
  reopenTimesheetRequest,
  deleteTimesheetLineRequest,
  importTimesheetRequest,
  editTimesheetLineRequest,
  createTimesheetLineRequest,
  getTimesheetAsFileRequest,
  duplicateTimesheetRequest,
  getTimesheetMappingRequest,
  token,
  locations,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { handleServerError, showNotification } = useContext(NotificationContext);
  const horizontalScrollStyles = useHorizontalScrollStyles();
  const [totals, setTotals] = useState<TimesheetTotals>();
  const [periodData, setPeriodData] = useState<PeriodData>();
  const [detectedColumns, setDetectedColumns] = useState<string[]>([]);
  const [rows, setRows] = useState<TimesheetLineDTO[]>([]);
  const [linesWithErrors, setLinesWithErrors] = useState<TimesheetLine[]>([]);
  const [validLines, setValidLines] = useState<TimesheetLine[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [file, setFile] = useState<File>();
  const [selectedTimesheetLine, setSelectedTimesheetLine] = useState<TimesheetLine | undefined>();
  const [openMappingDialog, setOpenMappingDialog] = useState<boolean>(false);
  const [openSubmitDialog, setOpenSubmitDialog] = useState<boolean>(false);
  const [openReopenDialog, setOpenReopenDialog] = useState<boolean>(false);
  const [openTimesheetUploadDialog, setOpenTimesheetUploadDialog] = useState<boolean>(false);
  const [openLasErrorsDialog, setOpenLastErrorsDialog] = useState<boolean>(false);
  const [openAddNewLineDialog, setOpenAddNewLineDialog] = useState<boolean>(false);
  const [openSuccessDuplicationDialog, setOpenSuccessDuplicationDialog] = useState<boolean>(false);
  const [openPullDialog, setOpenPullDialog] = useState<boolean>(false);
  const [mappingItems, setMappingItems] = useState<MappingItem[]>([]);
  const [paySchedule, setPaySchedule] = useState<PaySchedule>();
  const [companyId, setCompanyId] = useState<string>();
  const [company, setCompany] = useState<Company>();
  const [status, setStatus] = useState<string>();
  const [payrunId, setPayrunId] = useState<string>();
  const [duplicatedTimesheet, setDuplicatedTimesheet] = useState<Timesheet>();
  const inputRef = useRef<any>();
  const { id } = useParams<{ id: string }>();
  const { isMobile, isLargeDesktop } = useContext(DeviceContext);
  const history = useHistory();
  const [offset, setOffset] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [initialTotal, setInitialTotal] = useState<number>(0);
  const [substring, setSubstring] = useState<string>();
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'gross', sort: 'desc' }]);

  const columns: GridColDef[] = [
    {
      field: 'employee',
      headerName: 'Name',
      flex: 6,
      disableColumnMenu: true,
      sortable: false,
      renderCell: params => (
        <Typography variant="subtitle1">
          {params.row.employee?.first_name} {params.row.employee?.last_name}
        </Typography>
      ),
      minWidth: 140,
    },
    {
      field: 'uuid',
      headerName: 'Ref #',
      flex: 3,
      valueFormatter: params => params.row.employee?.ni_number,
      disableColumnMenu: true,
      minWidth: 100,
    },
    {
      field: 'basic_units',
      headerName: 'Hours / Days',
      flex: 4,
      disableColumnMenu: true,
      minWidth: 140,
    },
    {
      field: 'basic_rate',
      headerName: 'Rate',
      flex: 3,
      valueFormatter: params => currencyFormatter.format(params.row.basic_rate),
      disableColumnMenu: true,
      minWidth: 85,
    },
    {
      field: 'overtime_units',
      headerName: 'Overtime Hours / Days',
      flex: 5,
      disableColumnMenu: true,
      minWidth: 190,
    },
    {
      field: 'overtime_rate',
      headerName: 'Overtime Rate',
      valueFormatter: params => currencyFormatter.format(params.row.overtime_rate),
      flex: 4,
      disableColumnMenu: true,
      minWidth: 150,
    },
    {
      field: 'adjustments',
      headerName: 'Adjustments',
      valueFormatter: params => currencyFormatter.format(params.row.adjustments),
      flex: 4,
      disableColumnMenu: true,
      minWidth: 130,
    },
    {
      field: 'expenses',
      headerName: 'Expenses',
      valueFormatter: params => currencyFormatter.format(params.row.expenses),
      flex: 4,
      disableColumnMenu: true,
      minWidth: 100,
    },
    {
      field: 'miles',
      headerName: 'Mileage',
      flex: 3,
      disableColumnMenu: true,
      minWidth: 100,
    },
    {
      field: 'gross',
      headerName: 'Total Gross',
      flex: 4,
      disableColumnMenu: true,
      valueFormatter: params => currencyFormatter.format(params.row.gross),
      minWidth: 120,
    },
    {
      field: '',
      headerName: 'Actions',
      width: 80,
      hideSortIcons: true,
      sortable: true,
      disableColumnMenu: true,
      renderCell: params => (
        <Box display="flex" width="100%">
          <TimesheetLineActions
            disabled={loading || status === 'submitted'}
            onEditButtonClick={() => onEditButtonClick(params.row as TimesheetLine)}
            onDuplicateButtonClick={() => onDuplicateButtonClick(params.row as TimesheetLine)}
            onDeleteButtonClick={() => deleteTimesheetLine(params.row.uuid)}
          />
        </Box>
      ),
    },
  ];

  useEffect(() => {
    const { payrunId, companyId } = getIds(id);
    setCompanyId(companyId);
    setPayrunId(payrunId);
    getTimesheet({
      offset,
      order_by: formatSortModel<TimesheetLineDTO>(sortModel),
      substring,
    });
  }, [id]);

  const importTimeLogs = async ({ ids, strategy }: PullTimelogsForm) => {
    if (!payrunId || !companyId) {
      return;
    }
    try {
      const res = await pullTimeLogsRequest({ payrunId, companyId, strategy, approved: true });
      const items = res.data;
      const errorItems = items?.filter((item: TimesheetLine) => !!item.error?.message);

      if (errorItems?.length) {
        setLinesWithErrors(errorItems);
        return setOpenLastErrorsDialog(true);
      }

      showNotification({ message: 'Timesheet has been updated', options: { variant: 'success' } });
      getTimesheet(
        {
          offset,
          order_by: formatSortModel<TimesheetLineDTO>(sortModel),
          substring,
        },
        true
      );
      handlePullDialogClose();
    } catch (e) {
      handleServerError(e);
    }
  };

  const getIds = (urlParam: string) => {
    const timesheetUrlItems = getUrlItems(urlParam);
    const payrunId = timesheetUrlItems?.id;
    const companyId = timesheetUrlItems?.additionalId;
    return { payrunId, companyId };
  };

  const duplicateTimesheet = async () => {
    if (!paySchedule?.uuid) {
      return;
    }
    try {
      const res = await duplicateTimesheetRequest({
        companyId,
        payScheduleId: paySchedule?.uuid,
        copyPrevious: true,
      });
      setDuplicatedTimesheet(res.data.timesheet);
      setOpenSuccessDuplicationDialog(true);
    } catch (e) {
      handleServerError(e);
    }
  };

  const getTimesheet = async (payload: GetTimesheetData, skipLoading?: boolean) => {
    const { payrunId, companyId } = getIds(id);
    try {
      if (!skipLoading) {
        setLoading(true);
      }
      const { data } = await getTimesheetRequest(companyId, payrunId, {
        ...payload,
        limit: GRID_PAGE_SIZE,
      });
      handleGetTimesheetResult(data, !skipLoading);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      handleServerError(e);
    }
  };

  const handleGetTimesheetResult = (resData: TimesheetByStaffDTO, initial?: boolean) => {
    setTotals(resData.totals);
    setStatus(resData.status);
    setPeriodData({
      end_date: resData.end_date,
      start_date: resData.start_date,
      tax_week_end: resData.tax_week_end,
      tax_year_end: resData.tax_year_end,
    });
    setRows(resData.timesheet_lines?.items);
    setTotal(resData.timesheet_lines?.count);
    if (initial) {
      setInitialTotal(resData.timesheet_lines?.count);
    }
    setCompany(resData.company);
    setPaySchedule(resData.pay_schedule);
  };

  const submitTimesheet = async () => {
    if (!companyId || !paySchedule?.uuid || !payrunId) {
      return;
    }
    try {
      const payload = {
        offset,
        order_by: formatSortModel<TimesheetLineDTO>(sortModel),
        substring,
      };
      const { data } = await submitTimesheetRequest(companyId, paySchedule.uuid, payrunId, payload);
      handleGetTimesheetResult(data);
      setOpenSubmitDialog(false);
    } catch (e) {
      handleServerError(e);
    }
  };

  const reopenTimesheet = async () => {
    if (!companyId || !paySchedule?.uuid || !payrunId) {
      return;
    }
    try {
      await reopenTimesheetRequest(token, companyId, paySchedule.uuid, payrunId);
      getTimesheet({
        offset,
        order_by: formatSortModel<TimesheetLineDTO>(sortModel),
        substring,
      });
      setOpenReopenDialog(false);
    } catch (e) {
      handleServerError(e);
    }
  };

  const deleteTimesheetLine = async (timesheetLineId: string) => {
    if (!companyId || !payrunId) {
      return;
    }
    setLoading(true);
    try {
      await deleteTimesheetLineRequest(token, companyId, timesheetLineId);
      setLoading(false);
      getTimesheet({
        offset,
        order_by: formatSortModel<TimesheetLineDTO>(sortModel),
        substring,
      });
    } catch (e) {
      setLoading(false);
      handleServerError(e);
    }
  };

  const exportTimesheet = async () => {
    if (!companyId || !payrunId) {
      return;
    }
    try {
      const res = await getTimesheetAsFileRequest(token, companyId, payrunId);
      generateExcelFileLink(res);
    } catch (e) {
      console.error(e);
      handleServerError(e);
    }
  };

  const parseTimesheet = async (file: File, mapping?: any) => {
    if (!file || !paySchedule?.uuid) {
      return;
    }
    setLoading(true);
    try {
      const res = await importTimesheetRequest({
        token,
        companyId,
        payrunId: payrunId as string,
        payScheduleId: paySchedule.uuid,
        file,
        mapping,
      });
      setLoading(false);
      const timesheetError = res.data?.errors?.timesheet?.message;
      if (timesheetError) {
        showNotification({ message: timesheetError, options: { variant: 'warning' } });
      }
      const validLines = res.data.timesheet_lines || [];
      const linesWithErrors = res.data?.errors?.timesheet_lines || [];
      setLinesWithErrors(linesWithErrors);
      setValidLines(validLines);
      setOpenMappingDialog(false);
      setOpenTimesheetUploadDialog(true);
    } catch (e) {
      setLoading(false);
      handleServerError(e);
    }
  };

  const onEditButtonClick = (line: TimesheetLine) => {
    setSelectedTimesheetLine(line);
    setOpenAddNewLineDialog(true);
  };

  const onDuplicateButtonClick = (line: TimesheetLine) => {
    setSelectedTimesheetLine({ ...line, uuid: '' });
    setOpenAddNewLineDialog(true);
  };

  const getPredictedValue = (label: string, detectedColumns: string[]): string => {
    let prediction = '';
    detectedColumns.forEach(col => {
      if (col.toLowerCase().trim() === label.toLowerCase().trim()) {
        prediction = col;
      }
    });
    return prediction;
  };

  const handleInputChange = async (event: React.FormEvent<HTMLInputElement>) => {
    event.preventDefault();
    const file = (event.target as HTMLInputElement)?.files![0];
    setFile(file);
    setLoading(true);
    try {
      const res = await getTimesheetMappingRequest(token, companyId, file);
      const mapping = res.data.mapping;
      const columns = res.data.columns;
      const newMapping: MappingItem[] = MAPPING_PROPERTIES.map(item => ({
        ...item,
        matchedValue:
          mapping && mapping[item.value] ? mapping[item.value] : getPredictedValue(item.label, columns),
      }));
      setMappingItems(newMapping);
      setDetectedColumns(uniq(columns));
      setOpenMappingDialog(true);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      handleServerError(e);
    }
  };

  const saveMapping = () => {
    const mapping: any = {};
    mappingItems.forEach(item => {
      if (item.matchedValue) {
        mapping[item.value] = item.matchedValue;
      }
    });
    if (file) {
      parseTimesheet(file, mapping);
    }
  };

  const closeMappingDialog = () => {
    setFile(undefined);
    inputRef.current.value = '';
    setOpenMappingDialog(false);
    setMappingItems([]);
  };

  const closeUploadDialog = () => {
    setFile(undefined);
    inputRef.current.value = '';
    setOpenTimesheetUploadDialog(false);
    getTimesheet({
      offset,
      order_by: formatSortModel<TimesheetLineDTO>(sortModel),
      substring,
    });
  };

  const closeAddNewLineDialog = () => {
    getTimesheet({
      offset,
      order_by: formatSortModel<TimesheetLineDTO>(sortModel),
      substring,
    });
    setOpenAddNewLineDialog(false);
    setSelectedTimesheetLine(undefined);
  };

  const clearErrors = () => {
    setOpenLastErrorsDialog(false);
    setOpenPullDialog(false);
    setLinesWithErrors([]);
  };

  const goToTimesheet = () => {
    history.push(
      `/payroll/timesheets/${duplicatedTimesheet?.pay_schedule?.name}_${duplicatedTimesheet?.pay_run_id}_${companyId}`
    );
    setDuplicatedTimesheet(undefined);
    setOpenSuccessDuplicationDialog(false);
  };

  const handlePageChange = (newPage: number) => {
    const newOffset = newPage * GRID_PAGE_SIZE;
    setOffset(newOffset);
    getTimesheet({
      offset: newOffset,
      order_by: formatSortModel<TimesheetLineDTO>(sortModel),
      substring,
    });
  };

  const handleSortModelChange = (newModel: GridSortModel) => {
    if (isEqual(newModel, sortModel)) {
      return;
    }
    setSortModel(newModel);
    getTimesheet({
      offset,
      order_by: formatSortModel<TimesheetLineDTO>(newModel),
      substring,
    });
  };

  const handleSearchChange = debounce((e: any) => {
    const substr = e.target.value;
    setSubstring(substr);
    getTimesheet(
      {
        offset,
        order_by: formatSortModel<TimesheetLineDTO>(sortModel),
        substring: substr,
      },
      true
    );
  }, 500);

  const downloadTemplate = () => {
    let a = document.createElement('a');
    a.href = TIMESHEET_TEMPLATE_LINK;
    a.click();
  };

  const handlePullDialogClose = () => {
    setOpenPullDialog(false);
  };

  const handlePullDialogOpen = () => {
    setOpenPullDialog(true);
  };

  const options = useMemo(
    () => [
      {
        title: 'Line',
        icon: <AiOutlinePlus size="15px" color={theme.palette.primary.main} />,
        cy: 'add-new-line',
        onClick: () => setOpenAddNewLineDialog(true),
        disabled: loading || status === 'submitted',
      },
      {
        title: 'Export',
        icon: <BiExport size="15px" color={theme.palette.primary.main} />,
        onClick: exportTimesheet,
        disabled: loading || !initialTotal,
      },
      {
        title: 'Upload',
        icon: <BiImport size="15px" color={theme.palette.primary.main} />,
        disabled: loading || status === 'submitted',
        htmlFor: 'contained-button-file',
        onClick: noop,
      },
      {
        title: 'Duplicate',
        icon: <FiCopy size="15px" color={theme.palette.primary.main} />,
        onClick: () => duplicateTimesheet(),
        disabled: loading || !initialTotal,
      },
      {
        title: 'Download Timesheet Template',
        icon: <FiDownloadCloud size="15px" color={theme.palette.primary.main} />,
        onClick: downloadTemplate,
      },
      {
        title: 'Import Time Logs',
        icon: <BiImport size="15px" color={theme.palette.primary.main} />,
        onClick: handlePullDialogOpen,
      },
    ],
    [duplicateTimesheet, exportTimesheet, initialTotal, loading, status]
  );

  const errorButton = useMemo(
    () => (
      <Box>
        <Badge overlap="rectangular" badgeContent={linesWithErrors?.length} color="error">
          <Button
            size="small"
            className={classes.errorButton}
            onClick={() => setOpenLastErrorsDialog(true)}
            variant="outlined">
            {isMobile ? 'Errors' : 'Last Errors'}
          </Button>
        </Badge>
      </Box>
    ),
    [isMobile, linesWithErrors?.length]
  );

  const submitButton = useMemo(
    () => (
      <Button
        variant="contained"
        size="small"
        cy-test-id="open-submit-timesheet-dialog"
        disabled={loading}
        onClick={() => {
          if (status === 'submitted') {
            setOpenReopenDialog(true);
          } else {
            setOpenSubmitDialog(true);
          }
        }}
        startIcon={status === 'submitted' ? <AiOutlineRedo /> : <BsCheck />}
        color="primary">
        {status === 'submitted' ? 'Reopen' : 'Submit'}
      </Button>
    ),
    [loading, status]
  );

  const mobileControlView = useMemo(
    () => (
      <Box
        display="flex"
        alignItems="center"
        gridGap={16}
        margin={isMobile ? '40px 0 12px 0' : '24px 0'}
        justifyContent="space-between"
        className={clsx(horizontalScrollStyles.blockWithHideScroll, horizontalScrollStyles.blockWrapper)}>
        <Box display="flex" alignItems="center" flex={1}>
          <Box width="100%">
            <AppSearchInput onChange={handleSearchChange} isSmall expanded={true} fullWidth={true} />
          </Box>
        </Box>

        <Box display="flex" gridGap={8}>
          {!!linesWithErrors?.length && errorButton}
          {submitButton}
          <AppMenu options={options} cy="open-actions-dropdown-menu" />
        </Box>
      </Box>
    ),
    [errorButton, handleSearchChange, isMobile, linesWithErrors?.length, options, submitButton]
  );

  const desktopControlView = useMemo(
    () => (
      <Box
        display="flex"
        alignItems="center"
        flexWrap="wrap"
        gridGap={8}
        margin={'24px 0'}
        justifyContent="space-between">
        <Box
          gridGap={8}
          display="flex"
          alignItems="center"
          flexWrap="wrap"
          height="100%"
          marginBottom={isMobile ? 1 : 0}>
          <AppSearchInput onChange={handleSearchChange} isSmall expanded={true} />

          <Button
            onClick={() => setOpenAddNewLineDialog(true)}
            disabled={loading || status === 'submitted'}
            startIcon={<AiOutlinePlus />}
            variant="contained"
            cy-test-id="add-new-line"
            size="small"
            color="secondary">
            Line
          </Button>

          <Button
            onClick={exportTimesheet}
            disabled={loading || !initialTotal}
            startIcon={<BiExport size="15px" />}
            variant="contained"
            size="small"
            color="secondary">
            Export
          </Button>
          <label htmlFor="contained-button-file">
            <Button
              component="span"
              disabled={loading || status === 'submitted'}
              variant="contained"
              startIcon={<BiImport size="15px" />}
              size="small"
              color="secondary">
              Upload
            </Button>
          </label>

          <Button onClick={downloadTemplate} variant="outlined" size="small" color="secondary">
            Download Template
          </Button>
        </Box>

        <Box display="flex" gridGap={8}>
          {!!linesWithErrors?.length && errorButton}

          <Button
            startIcon={<BiImport size="15px" />}
            onClick={() => handlePullDialogOpen()}
            variant="contained"
            size="small"
            color="primary">
            Import Time Logs
          </Button>

          <Button
            onClick={() => duplicateTimesheet()}
            disabled={loading || !initialTotal}
            startIcon={<FiCopy size="18px" />}
            variant="contained"
            size="small"
            color="primary">
            Duplicate
          </Button>

          {submitButton}
        </Box>
      </Box>
    ),
    [
      duplicateTimesheet,
      errorButton,
      exportTimesheet,
      handleInputChange,
      handleSearchChange,
      initialTotal,
      isMobile,
      linesWithErrors?.length,
      loading,
      status,
      submitButton,
    ]
  );

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={9}>
          <PayScheduleInfo
            status={status}
            periodData={periodData}
            paySchedule={paySchedule}
            companyName={company?.name}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <Paper className={classes.wrapper} variant="outlined">
            {totals && (
              <Box alignItems={isMobile ? 'flex-start' : 'flex-end'} display="flex" flexDirection="column">
                <Box marginBottom={1}>
                  <Typography variant="subtitle2" className={clsx(classes.bold, classes.smallText)}>
                    Gross: {currencyFormatter.format(totals.gross)}
                  </Typography>
                </Box>
                <Typography variant="subtitle2" className={classes.smallText}>
                  Workers: {totals.workers}
                </Typography>
              </Box>
            )}
          </Paper>
        </Grid>
      </Grid>

      {isLargeDesktop ? desktopControlView : mobileControlView}

      <Box marginTop={2}>
        <AppDataGrid
          rows={rows}
          getRowId={row => row.uuid}
          height="calc(100vh - 370px)"
          columns={columns}
          loading={loading}
          rowCount={total}
          pageSize={GRID_PAGE_SIZE}
          rowsPerPageOptions={[GRID_PAGE_SIZE]}
          onSortModelChange={handleSortModelChange}
          onPageChange={handlePageChange}
          paginationMode="server"
          sortingMode="server"
          sortModel={sortModel}
          disableSelectionOnClick
          unit="timesheets"
        />
      </Box>

      <input
        ref={inputRef}
        disabled={loading || status === 'submitted'}
        onChange={handleInputChange}
        className={classes.input}
        id="contained-button-file"
        type="file"
      />

      {openMappingDialog && (
        <MappingDialog
          mappingItems={mappingItems}
          setMappingItems={setMappingItems}
          open={openMappingDialog}
          closeDialog={closeMappingDialog}
          saveMapping={saveMapping}
          detectedColumns={detectedColumns}
        />
      )}

      {openTimesheetUploadDialog && companyId && (
        <TimesheetUploadDialog
          open={openTimesheetUploadDialog}
          validLines={validLines}
          linesWithErrors={linesWithErrors}
          closeDialog={closeUploadDialog}
        />
      )}

      {openLasErrorsDialog && (
        <LastErrorsDialog
          clearErrors={clearErrors}
          linesWithErrors={linesWithErrors}
          open={openLasErrorsDialog}
          closeDialog={() => setOpenLastErrorsDialog(false)}
        />
      )}

      <RouterPrompt
        when={!!linesWithErrors?.length}
        title="You have some errors from last upload. Are you sure you want to leave this page?"
        onOK={() => true}
        onCancel={() => false}
      />

      {openAddNewLineDialog && companyId && payrunId && paySchedule?.uuid && (
        <AddTimesheetLine
          payrunId={payrunId}
          token={token}
          createTimesheetLineRequest={createTimesheetLineRequest}
          editTimesheetLineRequest={editTimesheetLineRequest}
          lookupEmployeesRequest={lookupEmployeesRequest}
          companyId={companyId}
          payScheduleId={paySchedule.uuid}
          selectedTimesheetLine={selectedTimesheetLine}
          open={openAddNewLineDialog}
          handleClose={closeAddNewLineDialog}
        />
      )}

      <ConfirmDialog
        handleClose={() => setOpenSubmitDialog(false)}
        open={openSubmitDialog}
        confirmText="Submit"
        subtitle="Are you sure? Once submitted, this will be added to the Payrun for this period. You’ll be able to make changes to this but only until the Payrun has been processed."
        title="Submit timesheet?"
        handleConfirm={submitTimesheet}
      />

      <ConfirmDialog
        handleClose={() => setOpenReopenDialog(false)}
        open={openReopenDialog}
        confirmText="Reopen"
        title="Reopen timesheet?"
        handleConfirm={reopenTimesheet}
      />

      <ConfirmDialog
        handleClose={() => {
          setOpenSuccessDuplicationDialog(false);
          setDuplicatedTimesheet(undefined);
        }}
        open={openSuccessDuplicationDialog}
        subtitle={`Week: ${duplicatedTimesheet?.tax_week_end} - ${formatTableDate(
          duplicatedTimesheet?.start_date
        )} - ${formatTableDate(duplicatedTimesheet?.end_date)}`}
        confirmText="Go To Timesheet"
        cancelText="Ok, Close"
        title="Timesheet has been created."
        handleConfirm={goToTimesheet}
      />

      <PullTimelogsDialog
        importTimeLogs={importTimeLogs}
        locations={locations}
        open={openPullDialog}
        handleClose={handlePullDialogClose}
      />
    </Box>
  );
};
