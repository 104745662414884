/* 
    input: 0
    output: 00

    input: 01
    output: 01

*/
export const twoDigits = (num: string | number) => {
  return ('0' + num).slice(-2);
};
